import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const ControllerButton = ({translated, translatedForm, controllerInfo}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [disabledCheckViewLm, setDisabledCheckViewLm] = useState(true)
    const [disabledTypeFilling, setDisabledTypFilling] = useState(true)
    const [selectedDurationButton, setSelectedDurationButton] = useState({
        label:'',
        value:''
    })
    const [selectedButtonInput, setSelectedButtonInput] = useState({
        label:'',
        value:''
    })
    const [selectedTypeButton, setSelectedTypeButton] = useState({
        label:'',
        value:''
    })

    const listActiveStartPanelController = [
        {label:translated.No, value:false},
        {label:translated.Yes, value:true},
    ]

    const listTypeFilling = [
        {label:translated.WithDose, value:true},
        {label:translated.NotDose, value:false},
    ]

    const listTypeButton = [
        {label:translated.NotConnected, value:0},
        {label:translated.ButtonOnDisconnect, value:1},
        {label:translated.ButtonOnConnection, value:2},
        {label:translated.GunOnDisconnect, value:3},
        {label:translated.GunOnConnect, value:4},
    ]

    const listButtonInput = [
        {label:translated.Input1, value:0},
        {label:translated.Input2, value:1},
        {label:translated.Input3, value:2},
    ]
    
    const minDurationSignal = [
        {label:"0", value:0},
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
        {label:"5", value:5},
        {label:"6", value:6},
        {label:"7", value:7},
        {label:"8", value:8},
        {label:"9", value:9},
        {label:"10", value:10}
    ]

  useEffect(() => {

    api.get(`api/controllers/details/button`, {
        headers:{
            "content-type" : "application/json",
            "controllerId":params.id
        },
        params:{
        controllerId: params.id,
        },
        withCredentials:true
      })
      .then(function (response) { 

        console.log("button start stop")
        console.log(response)

        let startButton = listActiveStartPanelController.filter((item) => item.value == response.data.dataResult.startButton)
        setValue("StartButton", startButton[0])

        if(response.data.dataResult.startButton) {

            setDisabledTypFilling(false)
            let type = listTypeFilling.filter((item) => item.value == response.data.dataResult.typeFilling)
            setValue("TypeFilling", type[0])

            if(response.data.dataResult.typeFilling) {
                
                setDisabledCheckViewLm(false)
                let lm = listActiveStartPanelController.filter((item) => item.value == response.data.dataResult.checkViewLevelMeter)
                setValue("CheckViewLevelMeter", lm[0])

            } else {

                setDisabledCheckViewLm(true)
                let lm = listActiveStartPanelController.filter((item) => item.value == false)
                setValue("CheckViewLevelMeter", lm[0])
            
            }

        } else {
            setDisabledTypFilling(true)
            let type = listTypeFilling.filter((item) => item.value == false)
            setValue("TypeFilling", type[0])
            setValue("CheckViewLevelMeter", listActiveStartPanelController[1])
        }

        //let findButtonDuration = minDurationSignal.filter((item) => item.value == response.data.dataResult.buttonDuration)
        //setSelectedDurationButton({label:findButtonDuration[0].label, value:findButtonDuration[0].value})
        setValue("ButtonDuration", response.data.dataResult.buttonDuration)

        let findTypeButton = listTypeButton.filter((item) => item.value == response.data.dataResult.buttonType)
        setSelectedTypeButton({label:findTypeButton[0].label, value:findTypeButton[0].value})
        //setValue("ButtonType", selectedTypeButton)

        let findButtonInput = listButtonInput.filter((item) => item.value == response.data.dataResult.buttonInput)
        setSelectedButtonInput({label:findButtonInput[0].label, value:findButtonInput[0].value})

      })
      .catch(function (error) {
        console.log(error);
    });

  }, [])

  const onSubmit = data => {

    setLoadingBtn(true);
    api.post("/api/controllers/details/button/update", {
        ButtonType: selectedTypeButton.value,
        ButtonInput: selectedButtonInput.value,
        ButtonDuration: data.ButtonDuration,
        StartButton: data.StartButton.value,
        CheckViewLevelMeter: data.CheckViewLevelMeter.value,
        TypeFilling: data.TypeFilling.value,
        ControllerId: params.id
    })
    .then(function (response) {
      setLoadingBtn(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      setLoadingBtn(false)
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => 
  {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                
                {
                    controllerInfo.type != 3
                    ?
                    <div className="col-xs-12 col-lg-5 col-md-5">

                        <h4 className="text-black">{translated.ExternalButton}</h4>
                    
                        <Form.Group className="mb-3 mt-3">
                        <Form.Label className="text-black">{translated.ButtonExternalType}</Form.Label>
                            <Controller
                                name="ButtonType"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field:{value} }) => (
                                <Select
                                    onChange={(item) => {
                                        setSelectedTypeButton({label:item.label, value:item.value})
                                        setValue("ButtonType", selectedTypeButton)
                                    }}
                                    value={selectedTypeButton}
                                    options={listTypeButton}
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                />
                                )}
                            />
                            {errors.ButtonType && 
                                <span className="text-danger font-size-13">{errors.ButtonType.message}</span>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.ButtonInput}</Form.Label>
                            <input type="text" disabled={true} className="form-control" value={selectedButtonInput.label} />
                        </Form.Group>

                        <Form.Group className="mb-3 mt-3">
                        <Form.Label className="text-black">{translated.MinStartSignal}</Form.Label>
                        <Form.Control 
                        {...register("ButtonDuration", {
                            max : {
                                value: 30000,
                                message: translatedForm.MaxNumber(30000)
                            },
                            minLength : {
                                value: 0,
                                message: translatedForm.MinNumber(0)
                            },
                        })}
                        type="number" isInvalid={errors.ButtonDuration}  
                        placeholder={translatedForm.EnterValue} className="form-control"  />
                        {errors.ButtonDuration && 
                            <span className="text-danger font-size-13">{errors.ButtonDuration.message}</span>
                        }
                        </Form.Group>

                        <div className="form-group mt-3">
                            <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                                {loadingBtn && (
                                    <Spinner animation="border" variant="light" 
                                        as="span"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                                {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                            </button>
                        </div>
                    </div>
                    : null
                }

                <div className="col-xs-12 col-lg-6 col-md-6">
                    <h4 className="text-black">{translated.SettingsButton}</h4>

                    <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">
                    {translated.StartButtonPanel}
                    </Form.Label>
                        <Controller
                            name="StartButton"                                
                            control={control}
                            rules={{ required: translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Select
                                onChange={(item) => {
                                    setValue("StartButton", item)
                                    if(item.value) {
                                        setDisabledTypFilling(false)
                                    } else {
                                        setDisabledTypFilling(true)
                                    }
                                }}
                                value={value}
                                options={listActiveStartPanelController}
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                        {errors.StartButton && 
                            <span className="text-danger font-size-13">{errors.StartButton.message}</span>
                        }
                    </Form.Group>

                    {
                        controllerInfo.type == 2 || controllerInfo.type == 3
                        ?
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.RefuelingTypeButton}</Form.Label>
                                    <Controller
                                        name="TypeFilling"                                
                                        control={control}
                                        rules={{ required: translatedForm.Required }}
                                        render={({ field:{value} }) => (
                                        <Select
                                            onChange={(item) => {
                                                setValue("TypeFilling", item)
                                                if(item.value) {
                                                    setDisabledCheckViewLm(false)
                                                } else {
                                                    setDisabledCheckViewLm(true)
                                                }
                                            }}
                                            isDisabled={disabledTypeFilling}
                                            value={value}
                                            options={listTypeFilling}
                                            placeholder={translatedForm.SelectFromList}
                                            classNamePrefix="select"
                                        />
                                        )}
                                    />
                                    {errors.TypeFilling && 
                                        <span className="text-danger font-size-13">{errors.TypeFilling.message}</span>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3">
                                <Form.Label className="text-black">
                                    {translated.ActivationViewReadingButton}
                                </Form.Label>
                                    <Controller
                                        name="CheckViewLevelMeter"                                
                                        control={control}
                                        rules={{ required: !disabledCheckViewLm ? translatedForm.Required : false }} 
                                        render={({ field:{value} }) => (
                                        <Select
                                            onChange={(item) => {
                                                setValue("CheckViewLevelMeter", item)
                                            }}
                                            isDisabled={disabledCheckViewLm}
                                            value={value}
                                            options={listActiveStartPanelController}
                                            placeholder={translatedForm.SelectFromList}
                                            classNamePrefix="select"
                                        />
                                        )}
                                    />
                                    {errors.CheckViewLevelMeter && 
                                        <span className="text-danger font-size-13">{errors.CheckViewLevelMeter.message}</span>
                                    }
                                </Form.Group>

                            <div className="form-group mt-3">
                                <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                                    {loadingBtn && (
                                        <Spinner animation="border" variant="light" 
                                            as="span"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                                    {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                                </button>
                            </div>
                        </>
                        : null
                    }
                
                </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}
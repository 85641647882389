import React, { useState, useEffect, useMemo, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Nav, Modal, Tabs, Tab, Spinner, DropdownButton, Badge, Dropdown, Alert} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { ControllerGroups } from './groups';
import CurrencyInput from 'react-currency-input-field';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import './../../boxicons.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import StatusConnect01 from "../../img/image_connect/StatusConnect-01.png";
import StatusConnect02 from "../../img/image_connect/StatusConnect-02.png";
import StatusConnect03 from "../../img/image_connect/StatusConnect-03.png";
import StatusConnect04 from "../../img/image_connect/StatusConnect-04.png";
import StatusConnect05 from "../../img/image_connect/StatusConnect-05.png";
import StatusConnect06 from "../../img/image_connect/StatusConnect-06.png";
import StatusConnect07 from "../../img/image_connect/StatusConnect-07.png";
import StatusConnect08 from "../../img/image_connect/StatusConnect-08.png";
import StatusConnect09 from "../../img/image_connect/StatusConnectEth3.png";
import StatusConnect10 from "../../img/image_connect/StatusConnect-10.png";
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateController } from '../../lang/controllers/translateController';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const Controllers = () => {

  const router = useHistory();
  const { control, register, handleSubmit, setValue, reset, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [currentActiveTab, setActiveTab] = useState('')
  const [showFormGroupAdd, setShowFormGroupAdd] = useState(false)
  const [countController, setCountController] = useState(0)
  const [listController, setListController] = useState({})
  const [listCompany, setListCompany] = useState([])
  const [listStation, setListStation] = useState([])
  const [listSection, setListSection] = useState([])
  const [listGroup, setListGroup] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [controllerBtn, setControllerBtn] = useState(false)
  const [showDeleteController, setShowDeleteController] = useState(false)
  const [controllerIdForDelete, setControllerIdForDelete] = useState(0)
  const [role, setRole] = useState(0)
  const translated = useLittera(TranslateController);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [textButtonController, setTextButtonController] = useState(translated.ActionWithControllers)
  const [permission, setPermission] = useState({
    read: false,
    create: false,
    fullAccess: false
  })
  const [controllerData, setControllerData] = useState({
    connectId:''
  })
  const [selectedItem, setSelectedItem] = useState({
    companies:''
  })
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })
  const handleShow = () => {
    setShowFormAdd(true)
    getStations()
  };
  const handleShowGroup = () => {
    setShowFormGroupAdd(true)
    getUserCompanies()
  }
  const handleClose = () => setShowFormAdd(false);
  const {isAuth} = useContext(AuthContext);

  const formatChars = {
    '*': '[A-Fa-f0-9]'
  };

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getControllers()
    getGroups()
  }, [])
  
  const repeatGetConfig = data => {
    api.get('/api/controllers/repeat-get-config', {
      headers:{
        "content-type" : "application/json",
        "id":data
      },
      params:{
        id: data,
      },
      withCredentials:true
    })
    .then(function (response) {
        console.log(response)
        displayToast(response.data.message, response.data.isSuccess)
        setListController(response.data.listDatas)
        setCountController(response.data.listDatas.length)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = (data) => {
    console.log(data)
    setLoadingData({button:true})
    api.post("/api/controllers/create", {
        Name:data.Name,
        Login: data.Login,
        Password: data.Password,
        Address: data.Address,
        ConnectId: controllerData.connectId,
        StationId: data.Station.id,
        StationSections: data.Section,
        ControllerGroupId: data.Group == undefined ? 0 : data.Group.value
      })
      .then(function (response) {
        displayToast(response.data.message, response.data.isSuccess)
        setLoadingData({button:false})
        setListController(response.data.listDatas)
        setCountController(response.data.listDatas.length)
        reset({
            Name:'',
            ConnectId:''
        })
      })
      .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
        displayToast(error.response.data.message, error.response.data.isSuccess)
      });
  }

  const onSubmitGroup = (data) => {
    console.log(data)
    setLoadingData({button:true})
    api.post("/api/controllers/create-group", {
        Name:data.Name,
        CompanyId: data.Company.id
      })
      .then(function (response) {
        displayToast(response.data.message, response.data.isSuccess)
        setLoadingData({button:false})
        reset({
            Name:'',
        })
      })
      .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
      });
  }

  const getUserCompanies = () => {
    api.get('/api/users/details/companies', {
      withCredentials:true
    })
    .then(function (response) {
      console.log("user companies")
      console.log(response.data)
      const addStation = response.data.listDatas.map(item => ({
          ...item,
          value: item.id,
          label: item.name
      }))
      setListCompany(addStation)

    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getStationSections = data => {

    setValue("Station", data)

    api.get("/api/stations/details/sections", {
      headers:{
        "content-type" : "application/json",
        "StationId": data.id
      },
      params:{
        StationId: data.id
      }
    })
    .then(function (response) {
      console.log('sections')
      console.log(response);
      displayToast(response.data.message, response.data.isSuccess)

      const addSection = response.data.listDatas.map(item => ({
        ...item,
        value: item.id,
        label: item.name
      }))

      setListSection(addSection)
    })
    .catch(function (error) {
      console.log(error);
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const handleConnectId = (item) => {
    setControllerData({connectId: item.target.value})
    setValue("ConnectId", controllerData.connectId)
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

  const getControllers = () => {
    setIsLoading(true)
    api.get('/api/controllers/all', {
      withCredentials:true
    })
    .then(function (response) {
      console.log('controllers')
      console.log(response) 
      setPermission({
        read: response.data.read,
        create: response.data.create,
        fullAccess: response.data.fullAccess
      })
      setLoadingData({grid:false})
      setListController(response.data.listDatas)
      setCountController(response.data.listDatas.length)
      setRole(response.data.currentAuthUserRoleId)
      setIsLoading(false)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getStations = () => {
    api.get('/api/stations/all', {
      withCredentials:true
    })
    .then(function (response) {
      const addStation = response.data.listDatas.map(item => ({
          ...item,
          value: item.id,
          label: item.name
      }))
      setListStation(addStation)

    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const delete_controller = () => {
    api.post('/api/controllers/details/delete', {
      Id:controllerIdForDelete,
      withCredentials: true
    })
    .then(function (response) {
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingData({button:false})
      setListController(response.data.listDatas)
      setCountController(response.data.listDatas.length)
      setShowDeleteController(false)
    })
    .catch(function (error) {
      console.log(error);
      setLoadingData({button:false})
    });
  }

  const onDelete = (is_show, id) => {
    setShowDeleteController(is_show)
    setControllerIdForDelete(id)
  }

  const activeAutoRead = (text, active) => {
    console.log(active)
    setTextButtonController(text)
    setControllerBtn(true)
    api.post('/api/controllers/auto-read', {
      IsActive: active,
      withCredentials: true
    })
    .then(function (response) {
      console.log(response)
      displayToast(response.data.message, response.data.isSuccess)
      setControllerBtn(false)
      setListController(response.data.listDatas)
      setCountController(response.data.listDatas.length)
      setTextButtonController(translated.ActionWithControllers)
      setControllerBtn(false)
    })
    .catch(function (error) {
      console.log(error);
      setControllerBtn(false)
    });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: translated.Name,
      },
      {
        accessorFn: (row) => row.connectId,
        id: 'connectId',
        size:300,
        header: translated.ID,
      },
      {
        accessorFn: (row) => row.firstConfigRead != null 
          ? <Badge bg="success" className="font-size-13">{translated.Readed}</Badge> 
          : <Badge bg="danger" className="font-size-13">{translated.NotReaded}</Badge>,
        accessorKey: 'firstConfigRead', //normal accessorKey
        header: translated.Settings,
      },
      {
        accessorFn: (row) => row.doScanning == 1 
          ? <span className="badge bg-success font-size-12">{translated.Enable}</span> 
          : <span className="badge bg-danger font-size-12">{translated.Disable}</span>,
        accessorKey: 'doScanning',
        header: translated.AutoRead,
      },
      {
        accessorFn: (row) => row.versionSoftware != null && row.versionSoftware != "" ? row.versionSoftware : translated.Undefined,
        accessorKey: 'versionSoftware',
        header: translated.VersionSoftware,
      },
      {
        accessorFn: (row) => row.stationName,
        accessorKey: 'stationName',
        header: translated.Station,
      },
      {
        accessorFn: (row) => {

          if(row.statusConnect == -1 || row.statusConnect == -2 || row.statusConnect == -99 || row.statusConnect == -97 
          || row.statusConnect == -96 || row.statusConnect == -95 || row.statusConnect == -93)
          {
            return <img className="d-flex justify-content-center" src={StatusConnect06} />
          }

          if(row.statusConnect == -98) {
            return <img src={StatusConnect07} />
          }

          if(row.statusConnect == -94) {
            return <img src={StatusConnect10} />
          }

          if(row.statusConnect >= 0 && row.statusConnect <= 4) {
            return <img src={StatusConnect05} />
          }

          if(row.statusConnect > 4 && row.statusConnect <= 9) {
            return <img src={StatusConnect04} />
          }

          if(row.statusConnect > 9 && row.statusConnect <= 16) {
            return <img src={StatusConnect03} />
          }

          if(row.statusConnect > 16 && row.statusConnect <= 21) {
            return <img src={StatusConnect02} />
          }

          if(row.statusConnect > 21 && row.statusConnect <= 31) {
            return <img className="d-flex justify-content-center" src={StatusConnect01} />
          }

          if(row.statusConnect == 99) {
            return <img src={StatusConnect06} />
          }

          if(row.statusConnect == 128) {
            return <img src={StatusConnect09} />
          }
        },
        accessorKey: 'statusConnect',
        header: "GSM",
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => router.push(`/controller/${row.id}`)} className="btn btn-light btn-sm">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </Button>

          <Button onClick={() => onDelete(true, row.id)} className="btn btn-danger btn-sm mx-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
          </Button> 
        </>,
        accessorKey: 'button',
        header: translatedForm.Actions,
      },
    ],
    [],
  );

  const getGroups = () => {
    setIsLoading(true)
    api.get('/api/controllers/groups', {
      withCredentials:true
    })
    .then(function (response) { 
      const group = response.data.listDatas.map(item => ({
        ...item,
        value: item.id,
        label: item.name
      }))
      setListGroup(group)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  return (
    isAuth ?
      <>
      {
        isLoading
        ? <Spinner animation="border" variant="info" />
        :
        <>
          <div className="d-sm-flex align-items-center justify-content-between">
                <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                    {translated.ListController}
                    <span className="text-secondary benzamat-font-bold font-size-20"> {countController}</span>
                </h2>         
                {
                  permission.create || permission.fullAccess
                  ?
                  <div className="page-title-right">
                    <DropdownButton title={textButtonController} disabled={controllerBtn}>
                      <Dropdown.Item onClick={() => handleShow()}>{translated.AddController}</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleShowGroup()}>{translated.AddGroup}</Dropdown.Item>
                      <Dropdown.Item onClick={() => getControllers()}>{translated.UpdateList}</Dropdown.Item>
                      {
                        role == 1
                        ?
                        <>
                          <Dropdown.Item onClick={() => activeAutoRead(translated.EnableAutoRead, true)}>{translated.EnableAutoRead}</Dropdown.Item>
                          <Dropdown.Item onClick={() => activeAutoRead(translated.DisabledAutoRead, false)}>{translated.DisabledAutoRead}</Dropdown.Item>
                        </>
                        : null
                      }
                    </DropdownButton>
                  </div>
                  : ''
                }             
          </div>
          <div className="row">
          <div className="col-xs-12 col-lg-12 col-md-12 ">         
              <div className="mt-4">
                {
                  permission.read || permission.fullAccess
                  ?
                  <Tabs defaultActiveKey="controllers" onClick={(e) => setActiveTab(e.target.dataset.rrUiEventKey)}  id="uncontrolled-tab-example"  className="nav-tabs-custom">
                    <Tab eventKey="controllers" title={translated.ListController} tabClassName="font-size-15">   
                      <MaterialReactTable 
                        columns={columns} 
                        data={listController}
                        localization={MRT_Localization_RU}
                        initialState={{ showColumnFilters: true }}
                        enableRowVirtualization
                        enableTopToolbar={false}
                        muiTablePaperProps={{
                          elevation: 1,
                        }}
                        muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            fontWeight: 'bold',
                            color:"#000",
                            backgroundColor:'#F3F3F3',
                            fontFamily: 'BenzamatRegular',
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            color:"#000",
                            fontFamily: 'BenzamatRegular',
                          },
                      }}
                      />
                    </Tab>

                    <Tab eventKey="controller-groups" title={translated.ListGroup} tabClassName="font-size-15">
                      {
                        currentActiveTab == "controller-groups"
                        ? <ControllerGroups />
                        : ''
                      }
                    </Tab>

                  </Tabs>
                  :
                  <Alert variant='danger'>
                    {translated.PermissionDenied}
                  </Alert>
                }
              </div>
          </div>
        </div>

        <Modal backdrop="static" keyboard={false} show={showDeleteController} onHide={() => setShowDeleteController(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-black">{translated.DeleteController}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="text-black text-center col-xs-12 col-lg-12 col-md-12">
                <h4>{translated.TextDeleteController}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => delete_controller()} className="btn btn-success">{translated.Submit}</Button>
            <Button onClick={() => setShowDeleteController(false)} className="btn btn-danger">{translated.Cancel}</Button>
          </Modal.Footer>
        </Modal>
      
        <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
              {translated.AddControllerTitle}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>
          <Offcanvas.Body>  

          <Alert variant='info'>
            {translated.MessageAfterAdd}
          </Alert>

            <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Group className="mb-3">
              <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
              <Form.Control 
                  {...register("Name", {
                  maxLength : {
                      value: 100,
                      message: translatedForm.MaxLenghtString(100)
                  },
                  minLength : {
                      value: 5,
                      message: translatedForm.MinLengthString(5)
                  },
                    required : translatedForm.Required
                  })}
                  type="text" maxLength="100" isInvalid={errors.Name}
                  placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                  {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                  }
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-black">{translated.Address} <sup className="text-danger">*</sup></Form.Label>
              <Controller
                  name="Address"                                
                  control={control}
                  rules={{ required: translatedForm.Required }}
                  render={({ field: {value} }) => (
                  <CurrencyInput
                      placeholder={translated.Address}
                      defaultValue={0}
                      value={value}
                      className="form-control"
                      onValueChange={(value) => {
                        setValue("Address", value)
                      }}
                  />
                  )}
              />
              {errors.LimitMonth && 
                  <span className="text-danger font-size-13">{errors.LimitMonth.message}</span>
              }   
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">{translated.Login} <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Login", {
                maxLength : {
                    value: 500,
                    message: translatedForm.MaxLenghtString(500)
                },
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                required : translatedForm.Required
                })}
                type="text" maxLength="500" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(500)} className="form-control"  />
              {errors.Login && 
              <span className="text-danger font-size-13">{errors.Login.message}</span>
              }
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-black">{translated.Password} <sup className="text-danger">*</sup></Form.Label>
              <Form.Control 
                  {...register("Password", {
                  maxLength : {
                      value: 500,
                      message: translatedForm.MaxLenghtString(500)
                  },
                  minLength : {
                      value: 5,
                      message: translatedForm.MinLengthString(5)
                  },
                  required : translatedForm.Required
                  })}
                  type="text" maxLength="500" isInvalid={errors.Password}  
                  placeholder={translatedForm.EnterValue(500)} className="form-control"  />
                  {errors.Password && 
                  <span className="text-danger font-size-13">{errors.Password.message}</span>
                  }
            </Form.Group>

            <Form.Group className="mb-3 mt-3">
            <Form.Label className="text-black">{translated.ID} <sup className="text-danger">*</sup></Form.Label>

            <Controller
                name="ConnectId"                                
                control={control}
                rules={{ required: translatedForm.Required }}
                render={({ field }) => (
                    <InputMask 
                        mask="******** ******** ********"
                        className="form-control"
                        type="text"
                        formatChars={formatChars}
                        defaultValue={controllerData.connectId}
                        placeholder={translated.Write}
                        onChange={handleConnectId}
                    />
                )}
            />
            {errors.ConnectId && 
                <span className="text-danger font-size-13">{errors.ConnectId.message}</span>
            }   

            </Form.Group>

            <Form.Group className="mb-3 mt-3">
            <Form.Label className="text-black">{translated.Station} <sup className="text-danger">*</sup></Form.Label>
            
                <Controller
                    name="Station"                                
                    control={control}
                    rules={{ required: translatedForm.Required }}
                    render={({ field }) => (
                    <Select    
                    {...field}                        
                        isSearchable
                        options={listStation}                        
                        placeholder={translatedForm.SelectFromList}
                        classNamePrefix="select"
                        onChange={(item) => {
                          getStationSections(item)
                        }}
                    />
                    )}
                />
                {errors.Station && 
                    <span className="text-danger font-size-13">{errors.Station.message}</span>
                }   

            </Form.Group> 

            <Form.Group className="mb-3 mt-3">
              <Form.Label className="text-black">{translated.Sections} <sup className="text-danger">*</sup></Form.Label>
              
                <Controller
                    name="Section"                                
                    control={control}
                    rules={{ required: translatedForm.Required }}
                    render={({ field }) => (
                    <Select    
                    {...field}                        
                        isSearchable
                        isMulti
                        options={listSection}                        
                        placeholder={translatedForm.SelectFromList}
                        classNamePrefix="select"
                    />
                    )}
                />
                {errors.Section && 
                    <span className="text-danger font-size-13">{errors.Section.message}</span>
                } 
            </Form.Group>

            <Form.Group className="mb-3 mt-3">
              <Form.Label className="text-black">{translated.ListGroup}</Form.Label>
              
                <Controller
                    name="Group"                                
                    control={control}
                    render={({ field:{value} }) => (
                    <Select                        
                        isSearchable
                        value={value}
                        options={listGroup}                        
                        placeholder={translatedForm.SelectFromList}
                        classNamePrefix="select"
                        onChange={(item) => setValue("Group", item)}
                    />
                    )}
                />
            </Form.Group>

            <div className="form-group mt-3">
              <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                  {loadingData.button && (
                      <Spinner animation="border" variant="light" 
                          as="span"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: "5px" }}
                      />
                  )}
                  {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                  {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                </button>
            </div>
          
          </Form>

        </Offcanvas.Body>
        </Offcanvas> 

        <Offcanvas placement="end" show={showFormGroupAdd} onHide={() => setShowFormGroupAdd(false)}>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>Добавление группы контроллеров</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body>  

        <Form onSubmit={handleSubmit(onSubmitGroup)}>

        <Form.Group className="mb-3">
          <Form.Label className="text-black">Name <sup className="text-danger">*</sup></Form.Label>
          <Form.Control 
              {...register("Name", {
              maxLength : {
                  value: 500,
                  message: translatedForm.MaxLenghtString(500)
              },
              minLength : {
                  value: 5,
                  message: translatedForm.MinLengthString(5)
              },
              required : translatedForm.Required
              })}
              type="text" maxLength="500" isInvalid={errors.Name}  
              placeholder={translatedForm.EnterValue(500)} className="form-control"  />
              {errors.Name && 
              <span className="text-danger font-size-13">{errors.Name.message}</span>
              }
        </Form.Group>
        
        <Form.Group className="mb-3 mt-3">
          <Form.Label className="text-black">{translatedForm.Company} <sup className="text-danger">*</sup></Form.Label>
          
              <Controller
                  name="Company"                                
                  control={control}
                  rules={{ required: translatedForm.Required }}
                  render={({ field: {value} }) => (
                  <Select                   
                      isSearchable
                      options={listCompany}                        
                      placeholder={translatedForm.SelectFromList}
                      classNamePrefix="select"
                      value={value}
                      onChange={(item) => {
                        setValue("Company", item)
                      }}
                  />
                  )}
              />
              {errors.Company && 
                  <span className="text-danger font-size-13">{errors.Company.message}</span>
              }
          </Form.Group>

          <div className="form-group mt-3">
          <button type="submit" disabled={loadingData.button} className="btn btn-primary">
              {loadingData.button && (
                  <Spinner animation="border" variant="light" 
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: "5px" }}
                  />
              )}
              {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
              {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
              </button>
          </div>
      
        </Form>
        
        </Offcanvas.Body>
        </Offcanvas>
        </>
      }
  </>
  :
  <Redirect to='/login'/>
  );

}


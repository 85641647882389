import React, { useContext, useEffect, useState, useMemo, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Card, ProgressBar, Form, Offcanvas, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { FormAddSection } from './FormAddSection';
import { FormEditSection } from './FormEditSection';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Select from 'react-select';
import Chart from "react-apexcharts";
import axios from 'axios';
import './../../boxicons.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { TranslateSection } from '../../lang/stations/translateSection';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const StationSection = () => {

    const router = useHistory()
    const params = useParams();
    const [listSections, setListSections] = useState([])
    const [show, setShow] = useState(false)
    const [name, setName] = useState('')
    const [isUpdate, setIsUpdate] = useState(false)
    const [sectionId, setSectionId] = useState(0)
    const translated = useLittera(TranslateSection);
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();
    const [permission, setPermission] = useState({
        read: false,
        update:false,
        fullAccess: false
    })

    useEffect(() => {
      methods.setLocale(CurrentSelectLanguage);
        api.get("/api/stations/details/sections", {
            headers:{
              "content-type" : "application/json",
              "id":params.id
            },
            params:{
              StationId: params.id,
            },
        })
        .then(function (response) {
            console.log("sections")
            console.log(response)
            setListSections(response.data.listDatas)
            setPermission({
                read: response.data.read,
                fullAccess: response.data.fullAccess
            })
        })
        .catch(function (error) {
            console.log(error);
        });
    }, [])

    const handleShow = (data, header, id) => {
        setShow(true)
        setIsUpdate(data)
        setName(header)
        setSectionId(id)
    }
    
    const columns = useMemo(
        () => [
          {
            accessorKey: 'name', //access nested data with dot notation
            header: translated.Name,
          },
          {
            accessorFn: (row) => row.fuel.name,
            id: 'fuelName',
            header: translated.Fuel,
          },
          {
            accessorFn: (row) => row.volume != null && row.volume != '' ? row.volume : translated.Empty,
            accessorKey: 'volume', //normal accessorKey
            header: translated.AllVolume,
          },
          {
            accessorFn: (row) => row.fuelLitreBalance.toFixed(2),
            accessorKey: 'currentVolume', //normal accessorKey
            header: translated.CurrentVolume,
          },
          {
            accessorFn: (row) => {
                if(row.fuelBalance >= 50) {
                    return <ProgressBar style={{width:'100%'}} now={row.fuelBalance} variant="success" label={`${Math.ceil(row.fuelBalance)}%`} />
                } else if (row.fuelBalance >= 20 && row.fuelBalance <= 50) {
                    return <ProgressBar style={{width:'100%'}} now={row.fuelBalance} variant="warning" label={`${Math.ceil(row.fuelBalance)}%`} />
                } else {
                    return <ProgressBar style={{width:'100%'}} now={row.fuelBalance} variant="danger" label={`${Math.ceil(row.fuelBalance)}%`} />
                }
            },
            accessorKey: 'isParent',
            header: translated.Occupancy,
          },
          {
            accessorFn: (row) => 
            <>
              <Button onClick={() => handleShow(true, translated.Edit, row.id)} className="btn btn-light btn-sm">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.45938 16.8349L3.16406 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </Button> 
            </>,
            accessorKey: 'button',
            header: translated.Actions,
          },
        ],
        [],
    );

    return (
        <>
        {
            permission.create || permission.fullAccess
            ?
            <Button onClick={() => handleShow(false, translated.AddSection)} bg="primary" className="mt-3 mb-2">
              <i className="bx bx-plus font-size-16 align-middle me-2 "></i> 
              {translated.AddSection}
            </Button>
            : ''
        }

        {
            permission.read || permission.fullAccess
            ?
            <div className="mt-2">
                <MaterialReactTable 
                    columns={columns} 
                    data={listSections}
                    localization={MRT_Localization_EN}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                      elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                  />
            </div>
            : ''
        }

        <Offcanvas placement="end" show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                {name}
            </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
            <Offcanvas.Body>

            {
                isUpdate
                ? <FormEditSection translated={translated} translatedForm={translatedForm} id={sectionId} setListSections={setListSections} />
                : <FormAddSection translated={translated} translatedForm={translatedForm} setListSections={setListSections} />
            }

            </Offcanvas.Body>
        </Offcanvas>
        </>
    )
}
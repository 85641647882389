import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Spinner, Dropdown, Alert, ProgressBar, Modal} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller, useWatch} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_EN } from 'material-react-table/locales/ru';
import './../../boxicons.css';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import moment from 'moment/moment';
import { MenuItem } from '@mui/material';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslatePouring } from '../../lang/pourings/translatePouring';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const PouringControllers = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, getValues, watch, formState, formState: { errors }} = useForm();
  const [startLevel, setStartLevel] = useState(0)
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [countGsm, setCountGsm] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [listPourings, setListPourings] = useState([])
  const [listStation, setListStation] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [listSections, setListSections] = useState([])
  const [issetLevelMeter, setIssetLevelMeter] = useState(false)
  const [sectionIsSelected, setSectionIsSelected] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [startDateTime, setStartDateTime] = useState(new Date())
  const [sectionData, setSectionData] = useState({})
  const [endDateTime, setEndDateTime] = useState(new Date())
  const [invoiceDate, setInvoiceDate] = useState(new Date())
  const [deltaVolume, setDeltaVolume] = useState(0)
  const [deltaMass, setDeltaMass] = useState(0)
  const [deltaLevel, setDeltaLevel] = useState(0)
  const [deltaTemperatur, setDeltaTemperatur] = useState(0)
  const [deltaDensity, setDeltaDensity] = useState(0)
  const [startMass, setStartMass] = useState(0)
  const translated = useLittera(TranslatePouring);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [loadingLevelMeterBtn, setLoadingLevelMeterBtn] = useState({
    start:false,
    end:false
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:0
  })
  const [selectedStation, setSelectedStation] = useState({
    label:'',
    value:0
  })
  const [selectedSection, setSelectedSection] = useState({
    label:'',
    value:0
  })
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });

  const handleShow = () => {
    setModalShow(true)
    getCompanies()
    
    setValue("InvoiceDate", invoiceDate)
    setValue("InvoiceMass", 0)
    setValue("StartDateTime", startDateTime)
    setValue("EndDateTime", endDateTime)

    setValue("StartLevel", 0)
    setValue("EndLevel", 0)
    setValue("DeltaLevel", 0)

    setValue("StartVolume", 0)
    setValue("EndVolume", 0)
    setValue("DeltaVolume", 0)

    setValue("StartTemperature", 0)
    setValue("EndTemperature", 0)
    setValue("DeltaTemperature", 0)

    setValue("StartDensity", 0)
    setValue("EndDensity", 0)
    setValue("DeltaDensity", 0)

    setValue("StartMass", 0)
    setValue("EndMass", 0)
    setValue("DeltaMass", 0)

  };

  const handleClose = () => setShowFormAdd(false);

  const getSectionData = (id) => {
    api.get('/api/stations/details/sections/details', {
        headers:{
            "content-type" : "application/json",
            "Id":id
        },
        params:{
            Id: id,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("section data")
        console.log(response)
        setSectionData(response.data.dataResult)

        if(response.data.dataResult.levelMeter != null) {
            setIssetLevelMeter(true)
        }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")
        console.log(response);

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))

        setListCompanies(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getStations = companyId => {
    api.get('/api/company/details/stations', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("stations of company")
        console.log(response);

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListStation(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getSections = stationId => {
    console.log(stationId)
    api.get('/api/stations/details/sections', {
        headers:{
            "content-type" : "application/json",
            "StationId":stationId
        },
        params:{
            StationId: stationId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("sections of station")
        console.log(response);

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListSections(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const displayToast = (message, status) => {

      if(status){
        toast.success(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
    
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
      }
  }

  const handleUpdate = (id) => {
    api.get('/api/pourings/details', {
        headers:{
            "content-type" : "application/json",
            "Id":id
        },
        params:{
            Id: id,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("pouring details")
        console.log(response);
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = data => { 

    setLoadingData({button: true})
    api.post('/api/pourings/create', {
        Price: data.Price,
        StationSectionId: selectedSection.value,

        InvoiceDate: data.InvoiceDate,
        InvoiceNumber: data.InvoiceNumber,
        InvoiceVolume: data.InvoiceVolume,
        InvoiceDensity: data.InvoiceDensity,
        InvoiceTemperature: data.InvoiceTemperature,
        InvoiceMass: data.InvoiceMass,

        StartLevel: data.StartLevel,
        StartVolume: data.StartVolume,
        StartDensity: data.StartDensity,
        StartTemperature: data.StartTemperature,
        StartDateTime: data.StartDateTime,

        EndLevel: data.EndLevel,
        EndVolume: data.EndVolume,
        EndDensity: data.EndDensity,
        EndTemperature: data.EndTemperature,
        EndDateTime: data.EndDateTime,

        TankTruckVolume: data.TankTruckVolume,
        TankTruckDensity: data.TankTruckDensity
    })
    .then(function (response) {
        console.log("response create pourings")
        console.log(response);
        displayToast(response.data.message, response.data.isSuccess)
        setListPourings(response.data.listDatas)
        setCountGsm(response.data.listDatas.length)
        setLoadingData({button: false})
        setModalShow(false)
        reset({
            "Price":0,
            "InvoiceNumber": 0,
            "InvoiceVolume": 0,
            "InvoiceDensity": 0,
            "InvoiceTemperature": 0,
            "InvoiceMass": 0,

            "StartLevel": 0,
            "StartVolume": 0,
            "StartDensity": 0,
            "StartTemperature": 0,

            "EndLevel": 0,
            "EndVolume": 0,
            "EndDensity": 0,
            "EndTemperature": 0,

            "TankTruckVolume": 0,
            "TankTruckDensity": 0
        })
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
    });
  }

  const controllerPourings = () => {
    api.get('/api/pourings/controllers', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("pourings")
        console.log(response);
        setListPourings(response.data.listDatas)
        setCountGsm(response.data.listDatas.length)
        setPermission({
            read: response.data.read,
            create: response.data.create,
            fullAccess: response.data.fullAccess
        })
        setIsLoading(false)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const fix = id => {
    api.post('/api/pourings/controllers/fix', {
        Id: id,
        withCredentials:true
    })
    .then(function (response) {
        console.log("response create pourings")
        console.log(response);
        displayToast(response.data.message, response.data.isSuccess)
        controllerPourings()
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
    });
  }

  const deletePouring = id => {
    api.post('/api/pourings/details/delete', {
        Id: id,
        withCredentials:true
    })
    .then(function (response) {
        console.log("response create pourings")
        console.log(response);
        displayToast(response.data.message, response.data.isSuccess)
        controllerPourings()
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
    });
  }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    setIsLoading(true)
    controllerPourings()
  }, []);

  const columns = useMemo(
    () => [
        {
            accessorFn: (row) => row.sectionName,
            accessorKey: 'sectionName',
            header: 'Section',
        },
        {
            accessorFn: (row) => "Приход топлива",
            accessorKey: 'operation',
            header: 'Operation',
        },
        {
            accessorFn: (row) => row.price,
            accessorKey: 'price',
            header: 'Price per liter',
        },
        {
            accessorFn: (row) => row.factKg,
            accessorKey: 'factVolume',
            header: 'Actual arrival',
        },
        {
            accessorFn: (row) => row.invoiceVolume,
            accessorKey: 'factInvoice',
            header: 'Actual invoice',
        },
        {
            accessorFn: (row) => moment(row.dateTimeOperation).format("DD.MM.YYYY HH:mm"),
            accessorKey: 'dateOperation',
            header: 'Operation date',
        },
        {
            accessorFn: (row) => moment(row.dateTimeCorrection).format("DD.MM.YYYY HH:mm"),
            accessorKey: 'dateOperationUpdate',
            header: 'Change date',
        },
        {
            accessorFn: (row) => row.userName,
            accessorKey: 'userName',
            header: 'User',
        },
        {
            accessorFn: (row) => row.arrivalReady === 1 ? "Да" : "Нет" ,
            accessorKey: 'full',
            header: 'Date',
        },
    ],
    [],
  );

  return (
    isAuth ?
    <>
    {
        isLoading
        ? <Spinner animation="border" variant="info" />
        :
        <>

        <div className="d-sm-flex align-items-center justify-content-between">
            <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                {translated.FuelReceiptController}
                <span className="text-secondary benzamat-font-bold font-size-20"> {countGsm}</span>
            </h2>
        </div>

        <div className="row">

        <div className="col-xs-12 col-lg-12 col-md-12">         
            <div className="mt-4">
                {
                permission.read || permission.fullAccess
                ?
                <MaterialReactTable 
                    columns={columns} 
                    data={listPourings}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableRowVirtualization
                    enableTopToolbar={false}
                    enableRowActions
                    renderRowActionMenuItems={({ row  }) => [
                        
                        <MenuItem key="fix"
                        onClick={() => fix(row.original.id)}>
                            Зафиксировать
                        </MenuItem>,
                        
                        <MenuItem key="edit" onClick={() => console.info(row.id)}>
                            Изменить
                        </MenuItem>,

                        <MenuItem key="delete" onClick={() => deletePouring(row.original.id)}>
                            Удалить
                        </MenuItem>,
                    ]}
                    muiTablePaperProps={{
                    elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                    },
                    }}
                    muiTableBodyCellProps={{
                    sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                    },
                    }}
                />
                :
                <Alert variant="danger">{translated.AccessDenied}</Alert>
                }
            </div>
        </div>
        </div>
        
        <Modal
            size="lg"
            show={modalShow}
            onHide={() => setModalShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg" className="text-black benzamat-font-bold font-size-22">
                {translated.TitleFuelIntake}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-xs-12 col-lg-4 col-md-4">
                            <Form.Group className="mb-2">
                                <Form.Label className="text-black">Компаниия <sup className="text-danger">*</sup></Form.Label>
                                    <Controller
                                        name="Station"                                
                                        control={control}
                                        render={({ field }) => (
                                        <Select
                                            options={listCompanies}                   
                                            isSearchable                  
                                            placeholder={translatedForm.SelectFromList}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                getStations(item.id)
                                                setSelectedCompany({label:item.label, value:item.value})
                                            }}
                                        />
                                        )}
                                    />
                                {errors.Station && 
                                    <span className="text-danger font-size-13">{errors.Station.message}</span>
                                }
                            </Form.Group>
                        </div>

                        <div className="col-xs-12 col-lg-4 col-md-4">
                            <Form.Group className="mb-2">
                                <Form.Label className="text-black">{translated.Station} <sup className="text-danger">*</sup></Form.Label>
                                    <Controller
                                        name="Station"                                
                                        control={control}
                                        render={({ field }) => (
                                        <Select
                                            options={listStation}                   
                                            isSearchable                  
                                            placeholder={translatedForm.SelectFromList}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                getSections(item.id)
                                                setSelectedStation({label:item.label, value:item.value})
                                            }}
                                        />
                                        )}
                                    />
                                {errors.Station && 
                                    <span className="text-danger font-size-13">{errors.Station.message}</span>
                                }
                            </Form.Group>
                        </div>

                        <div className="col-xs-12 col-lg-4 col-md-4">
                        <Form.Group className="mb-2">
                            <Form.Label className="text-black">{translated.Section} <sup className="text-danger">*</sup></Form.Label>
                                <Controller
                                    name="Station"                                
                                    control={control}
                                    render={({ field }) => (
                                    <Select                     
                                        isSearchable
                                        options={listSections}                        
                                        placeholder={translatedForm.SelectFromList}
                                        classNamePrefix="select"
                                        onChange={(item) => {
                                            setSelectedSection({label:item.label, value:item.value})
                                            setSectionIsSelected(true)
                                            getSectionData(item.value)
                                        }}
                                    />
                                    )}
                                />
                            {errors.Station && 
                                <span className="text-danger font-size-13">{errors.Station.message}</span>
                            }
                        </Form.Group>
                        </div>

                        {
                            sectionIsSelected
                            ?
                            <>
                                <div className="col-xs-12 col-lg-3 col-md-3 mt-3">
                                    <span className="text-black benzamat-font-bold font-size-18">Накладная</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">Номер <sup className="text-danger">*</sup></Form.Label>
                                        <Form.Control 
                                            {...register("InvoiceNumber", {
                                            maxLength : {
                                                value: 100,
                                                message: 'Максимальная длина 100 символов'
                                            },
                                            required: "Обязательное поле",
                                            })}
                                            type="text" maxLength="100" isInvalid={errors.InvoiceNumber}  placeholder="Введите номер" className="form-control"  />
                                        {errors.InvoiceNumber && 
                                            <span className="text-danger font-size-13">{errors.InvoiceNumber.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Объем, л. <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="InvoiceVolume"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field }) => (
                                            <CurrencyInput
                                                placeholder="Введите литры"
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                suffix=" л."
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("InvoiceVolume", value)
                                                    let volume = getValues("InvoiceDensity")

                                                    if(volume === undefined) {
                                                        setValue("InvoiceDensity", 0)
                                                        setValue("InvoiceMass", parseFloat(value) * parseFloat(0) / 1000)
                                                    }

                                                    if(value === undefined) {
                                                        setValue("InvoiceVolume", 0)
                                                        setValue("InvoiceMass", parseFloat(0) * parseFloat(volume) / 1000)
                                                    }

                                                    if(volume != undefined && value != undefined) {
                                                        setValue("InvoiceMass", parseFloat(value) * parseFloat(volume) / 1000)
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.InvoiceVolume && 
                                            <span className="text-danger font-size-13">{errors.InvoiceVolume.message}</span>
                                        } 
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Плотность, кг/м3 <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="InvoiceDensity"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field }) => (
                                            <CurrencyInput
                                                placeholder="Введите литры"
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("InvoiceDensity", value)
                                                    let volume = getValues("InvoiceVolume")
                                                    
                                                    if(volume === undefined) {
                                                        setValue("InvoiceVolume", 0)
                                                        setValue("InvoiceMass", parseFloat(value) * parseFloat(0) / 1000)
                                                    }

                                                    if(value === undefined) {
                                                        setValue("InvoiceDensity", 0)
                                                        setValue("InvoiceMass", parseFloat(0) * parseFloat(volume) / 1000)
                                                    }

                                                    if(volume != undefined && value != undefined) {
                                                        setValue("InvoiceMass", parseFloat(value) * parseFloat(volume) / 1000)
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.InvoiceDensity && 
                                            <span className="text-danger font-size-13">{errors.InvoiceDensity.message}</span>
                                        } 
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Температура <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="InvoiceTemperature"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field }) => (
                                            <CurrencyInput
                                                placeholder="Введите литры"
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => setValue("InvoiceTemperature", value)}
                                            />
                                            )}
                                        />
                                        {errors.InvoiceTemperature && 
                                            <span className="text-danger font-size-13">{errors.InvoiceTemperature.message}</span>
                                        } 
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Масса, кг</Form.Label>
                                        <Form.Control 
                                            {...register("InvoiceMass", {
                                            maxLength : {
                                                value: 100,
                                                message: 'Максимальная длина 100 символов'
                                            },
                                            })}
                                            type="text" maxLength="100" isInvalid={errors.InvoiceMass} disabled className="form-control"  />
                                        {errors.InvoiceMass && 
                                        <span className="text-danger font-size-13">{errors.InvoiceMass.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Дата <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="InvoiceDate"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field }) => (
                                            <Flatpickr
                                                className='form-control'
                                                options={{ 
                                                    time_24hr: true,  
                                                    enableTime: true,
                                                    dateFormat: "d.m.Y H:i",
                                                    locale: Russian
                                                }}
                                                value={invoiceDate}
                                                onChange={([date]) => {
                                                    setInvoiceDate(date)
                                                    setValue("InvoiceDate", invoiceDate)
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.InvoiceDate && 
                                            <span className="text-danger font-size-13">{errors.InvoiceDate.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-3 col-md-3 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">Начало слива</span>
                                    
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">Уровень, см <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartLevel"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder="Введите уровень"
                                                value={value.toFixed(1)}
                                                decimalsLimit={2}
                                                suffix=" см."
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("StartLevel", value)
                                                    let end = getValues("EndLevel")

                                                    if(value === undefined) {
                                                        setValue("StartLevel", 0)
                                                        setValue("DeltaLevel", parseFloat(end) - parseFloat(0))
                                                    }

                                                    if(end === undefined) {
                                                        setValue("EndLevel", 0)
                                                        setValue("DeltaLevel", parseFloat(0) - parseFloat(value))
                                                    }

                                                    if(end != undefined && value != undefined) {
                                                        setValue("DeltaLevel", parseFloat(end) - parseFloat(value))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartLevel && 
                                            <span className="text-danger font-size-13">{errors.StartLevel.message}</span>
                                        } 
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Объем, л <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartVolume"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder="Введите объем"
                                                value={value.toFixed(1)}
                                                decimalsLimit={2}
                                                suffix=" л."
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("StartVolume", value)
                                                    let end = getValues("EndVolume")
                                                    
                                                    if(value === undefined) {
                                                        setValue("StartLevel", 0)
                                                        setValue("DeltaVolume", parseFloat(end) - parseFloat(0))
                                                    }

                                                    if(end === undefined) {
                                                        setValue("EndLevel", 0)
                                                        setValue("DeltaVolume", parseFloat(0) - parseFloat(value))
                                                    }

                                                    if(end != undefined && value != undefined) {
                                                        setValue("DeltaVolume", parseFloat(end) - parseFloat(value))
                                                    }

                                                    if(value != undefined) {
                                                        let density = getValues("StartDensity")
                                                        setValue("StartMass", (parseFloat(density) * parseFloat(value)) / 1000)
                                                        let endMass = getValues("EndMass")
                                                        let startMass = getValues("StartMass")
                                                        setValue("DeltaMass", parseFloat(endMass) - parseFloat(startMass))
                                                    }

                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartVolume && 
                                            <span className="text-danger font-size-13">{errors.StartVolume.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Плотность, кг/м3 <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartDensity"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder="Введите плотность"
                                                value={value.toFixed(1)}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("StartDensity", value)
                                                    let end = getValues("EndDensity")

                                                    if(value === undefined) {
                                                        setValue("StartDensity", 0)
                                                        setValue("DeltaDensity", parseFloat(end) - parseFloat(0))
                                                    }

                                                    if(end === undefined) {
                                                        setValue("EndDensity", 0)
                                                        setValue("DeltaDensity", parseFloat(0) - parseFloat(value))
                                                    }

                                                    if(end != undefined && value != undefined) {
                                                        setValue("DeltaDensity", parseFloat(end) - parseFloat(value))
                                                    }

                                                    if(value != undefined) {
                                                        let volume = getValues("StartVolume")
                                                        setValue("StartMass", (parseFloat(volume) * parseFloat(value)) / 1000)
                                                        let endMass = getValues("EndMass")
                                                        let startMass = getValues("StartMass")
                                                        setValue("DeltaMass", parseFloat(endMass) - parseFloat(startMass))
                                                    }
                                                    
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartDensity && 
                                            <span className="text-danger font-size-13">{errors.StartDensity.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Температура <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartTemperature"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder="Введите температуру"
                                                value={value.toFixed(1)}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("StartTemperature", value)
                                                    let end = getValues("EndTemperature")

                                                    if(value === undefined) {
                                                        setValue("StartTemperature", 0)
                                                        setValue("DeltaTemperature", parseFloat(end) - parseFloat(0))
                                                    }

                                                    if(end === undefined) {
                                                        setValue("EndTemperature", 0)
                                                        setValue("DeltaTemperature", parseFloat(0) - parseFloat(value))
                                                    }

                                                    if(end != undefined && value != undefined) {
                                                        setValue("DeltaTemperature", parseFloat(end) - parseFloat(value))
                                                    }

                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartTemperature && 
                                            <span className="text-danger font-size-13">{errors.StartTemperature.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Масса, кг</Form.Label>
                                        <Form.Control 
                                            {...register("StartMass", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" value={startMass.toFixed(1)} maxLength="800" isInvalid={errors.StartMass} disabled className="form-control"  />
                                        {errors.StartMass && 
                                        <span className="text-danger font-size-13">{errors.StartMass.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Дата начала слива <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartDateTime"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field:{value} }) => (
                                            <Flatpickr
                                                className='form-control'
                                                options={{ 
                                                    time_24hr: true,  
                                                    enableTime: true,
                                                    dateFormat: "d.m.Y H:i",
                                                    locale: Russian
                                                }}
                                                value={startDateTime}
                                                onChange={([date]) => {
                                                    setStartDateTime(date)
                                                    setValue("StartDateTime", startDateTime)
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartDateTime && 
                                            <span className="text-danger font-size-13">{errors.StartDateTime.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-3 col-md-3 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">Изменение</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">Уровень, см</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaLevel", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" value={deltaLevel} maxLength="800" disabled isInvalid={errors.DeltaLevel} className="form-control"  />
                                        {errors.DeltaLevel && 
                                        <span className="text-danger font-size-13">{errors.DeltaLevel.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Объем, л</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaVolume", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" value={deltaVolume} maxLength="800" disabled isInvalid={errors.Description} className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Плотность, кг/м3</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaDensity", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" value={deltaDensity} maxLength="800" disabled isInvalid={errors.DeltaDensity} className="form-control"  />
                                        {errors.DeltaDensity && 
                                        <span className="text-danger font-size-13">{errors.DeltaDensity.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Температура</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaTemperature", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" value={deltaTemperatur} maxLength="800" disabled  isInvalid={errors.DeltaTemperature} className="form-control"  />
                                        {errors.DeltaTemperature && 
                                        <span className="text-danger font-size-13">{errors.DeltaTemperature.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Масса, кг</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaMass", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" value={deltaMass} maxLength="800" isInvalid={errors.Description} disabled className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-3 col-md-3 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">Конец слива</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">Уровень, см <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndLevel"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field:{value} }) => (
                                            <CurrencyInput
                                                placeholder="Введите уровень"
                                                value={value.toFixed(1)}
                                                decimalsLimit={2}
                                                suffix=" см."
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("EndLevel", value)
                                                    let start = getValues("StartLevel")
                                                    
                                                    if(start === undefined) {
                                                        setValue("StartLevel", 0)
                                                        setValue("DeltaLevel", parseFloat(value) - parseFloat(0))
                                                    }

                                                    if(value === undefined) {
                                                        setValue("EndLevel", 0)
                                                        setValue("DeltaLevel", parseFloat(0) - parseFloat(start))
                                                    }

                                                    if(start != undefined && value != undefined) {
                                                        setValue("DeltaLevel", parseFloat(value) - parseFloat(start))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndLevel && 
                                            <span className="text-danger font-size-13">{errors.EndLevel.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Объем, л <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndVolume"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder="Введите объем"
                                                value={value.toFixed(1)}
                                                decimalsLimit={2}
                                                suffix=" л."
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("EndVolume", value)
                                                    let start = getValues("StartVolume")
                                                    
                                                    if(start === undefined) {
                                                        setValue("StartLevel", 0)
                                                        setValue("DeltaVolume", parseFloat(value) - parseFloat(0))
                                                    }

                                                    if(value === undefined) {
                                                        setValue("EndLevel", 0)
                                                        setValue("DeltaVolume", parseFloat(0) - parseFloat(start))
                                                    }

                                                    if(start != undefined && value != undefined) {
                                                        setValue("DeltaVolume", parseFloat(value) - parseFloat(start))
                                                    }

                                                    if(value != undefined) {
                                                        let volume = getValues("EndDensity")
                                                        setValue("EndMass", (parseFloat(volume) * parseFloat(value)) / 1000)
                                                        let endMass = getValues("EndMass")
                                                        let startMass = getValues("StartMass")
                                                        setValue("DeltaMass", parseFloat(endMass) - parseFloat(startMass))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndVolume && 
                                            <span className="text-danger font-size-13">{errors.EndVolume.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Плотность, кг/м3 <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndDensity"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder="Введите плотность"
                                                value={value.toFixed(1)}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("EndDensity", value)

                                                    let start = getValues("StartDensity")
                                                    
                                                    if(start === undefined) {
                                                        setValue("StartDensity", 0)
                                                        setValue("DeltaDensity", parseFloat(value) - parseFloat(0))
                                                    }

                                                    if(value === undefined) {
                                                        setValue("EndDensity", 0)
                                                        setValue("DeltaDensity", parseFloat(0) - parseFloat(start))
                                                    }

                                                    if(start != undefined && value != undefined) {
                                                        setValue("DeltaDensity", parseFloat(value) - parseFloat(start))
                                                    }

                                                    if(value != undefined) {
                                                        let volume = getValues("EndVolume")
                                                        setValue("EndMass", (parseFloat(volume) * parseFloat(value)) / 1000)
                                                        let endMass = getValues("EndMass")
                                                        let startMass = getValues("StartMass")
                                                        setValue("DeltaMass", parseFloat(endMass) - parseFloat(startMass))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndDensity && 
                                            <span className="text-danger font-size-13">{errors.EndDensity.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Температура <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndTemperature"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder="Введите объем"
                                                value={value.toFixed(1)}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("EndTemperature", value)
                                                    let start = getValues("StartTemperature")
                                                    
                                                    if(start === undefined) {
                                                        setValue("StartTemperature", 0)
                                                        setValue("DeltaTemperature", parseFloat(value) - parseFloat(0))
                                                    }

                                                    if(value === undefined) {
                                                        setValue("EndTemperature", 0)
                                                        setValue("DeltaTemperature", parseFloat(0) - parseFloat(start))
                                                    }

                                                    if(start != undefined && value != undefined) {
                                                        setValue("DeltaTemperature", parseFloat(value) - parseFloat(start))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndTemperature && 
                                            <span className="text-danger font-size-13">{errors.EndTemperature.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Масса, кг</Form.Label>
                                        <Form.Control 
                                            {...register("EndMass", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" maxLength="800" isInvalid={errors.EndMass} disabled className="form-control"  />
                                        {errors.EndMass && 
                                            <span className="text-danger font-size-13">{errors.EndMass.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Дата конца слива<sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndDateTime"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field }) => (
                                            <Flatpickr
                                                className='form-control'
                                                options={{ 
                                                    time_24hr: true,  
                                                    enableTime: true,
                                                    dateFormat: "d.m.Y H:i",
                                                    locale: Russian
                                                }}
                                                value={endDateTime}
                                                onChange={([date]) => {
                                                    setEndDateTime(date)
                                                    setValue("EndDateTime", endDateTime)
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndDateTime && 
                                            <span className="text-danger font-size-13">{errors.EndDateTime.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-6 col-md-6 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">Бензовоз</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">Замер объема, л. <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="TankTruckVolume"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field }) => (
                                            <CurrencyInput
                                                placeholder="Введите объем"
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                suffix=" л."
                                                className="form-control"
                                                onValueChange={(value) => setValue("TankTruckVolume", value)}
                                            />
                                            )}
                                        />
                                        {errors.TankTruckVolume && 
                                            <span className="text-danger font-size-13">{errors.TankTruckVolume.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">Замер плотности, кг/м3 <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="TankTruckDensity"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field }) => (
                                            <CurrencyInput
                                                placeholder="Введите плотность"
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => setValue("TankTruckDensity", value)}
                                            />
                                            )}
                                        />
                                        {errors.TankTruckDensity && 
                                            <span className="text-danger font-size-13">{errors.TankTruckDensity.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-6 col-md-6 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">Стоимость топлива</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">Цена за литр <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="Price"                                
                                            control={control}
                                            rules={{required:"Обязательное поле"}}
                                            render={({ field }) => (
                                            <CurrencyInput
                                                placeholder="Введите плотность"
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => setValue("Price", value)}
                                            />
                                            )}
                                        />
                                        {errors.Price && 
                                            <span className="text-danger font-size-13">{errors.Price.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                                    <span className="text-black benzamat-font-bold font-size-18">Измерение уровня метроштоком</span>
                                </div>

                                <div className="col-xs-12 col-lg-4 col-md-4 ">
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">На начало слива, см</Form.Label>
                                        <Form.Control 
                                            {...register("Description", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" maxLength="800" isInvalid={errors.Description} placeholder="Введите см" className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-4 col-md-4">
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">На конец слива, см</Form.Label>
                                        <Form.Control 
                                            {...register("Description", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" maxLength="800" isInvalid={errors.Description} placeholder="Введите см" className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-4 col-md-4">
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">Изменение, л.</Form.Label>
                                        <Form.Control 
                                            {...register("Description", {
                                            maxLength : {
                                                value: 800,
                                                message: 'Максимальная длина 800 цифр'
                                            },
                                            })}
                                            type="text" maxLength="800" isInvalid={errors.Description} placeholder="Введите см" className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="form-group mt-3">
                                    <button type="submit" disabled={loadingData.button} className="btn btn-success">
                                        {loadingData.button && (
                                            <Spinner animation="border" variant="light" 
                                                as="span"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                                        {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                                    </button>

                                </div>
                            </>
                            : <Alert className="mt-3" variant='danger'>Для оформления поступления топлива - выберите секцию у станции и проверьте указан ли уровнемер у выбранной секции</Alert>
                        }
                    </div>
                </Form>
            </Modal.Body>
        </Modal>   
        </>
    }
    </>
    :
    <Redirect to='/login'/>
  );

}

import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, ProgressBar, Badge, Form, Offcanvas, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import axios from 'axios';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const FormAddSection = ({translated, translatedForm, setListSections}) => {

    const params = useParams()
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [listFuels, setFuels] = useState([])
    const [loadingData, setLoadingDataButton] = useState({
        button:false
    })

    useEffect(() => {
        api.get('/api/fuels/all', {
            withCredentials:true
          })
          .then(function(response){
              console.log(response);

            const addFuel = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            
            setFuels(addFuel)
          })
          .catch(function (error) {
              console.log(error);
          });
    },[])

    const onSubmit = data => {
        setLoadingDataButton({button:true})
        api.post('/api/stations/details/sections/create', {
            Name: data.Name,
            FuelId: data.Fuel.id,
            StationId: params.id,
            LevelHeightLow: data.LevelHeightLow,
            LevelHeightUp: data.LevelHeightUp,
            VolumeLow: data.VolumeLow,
            VolumeUp: data.VolumeUp,
            Height: data.Height,
            Volume: data.Volume
        })
        .then(function (response) {
            console.log("list of sections after create")
            console.log(response)
            displayToast(response.data.message, response.data.isSuccess)
            setLoadingDataButton({button:false})
            setListSections(response.data.listDatas)
            reset({
                Name:'',
                LevelHeightLow:'',
                LevelHeightUp:'',
                VolumeLow:'',
                VolumeUp:'',
                Volume:'',
                Height:''
            })
        })
        .catch(function (error) {
            console.log(error);
            setLoadingDataButton({button:false})
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Group className="mb-3">
                <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
                <Form.Control 
                    {...register("Name", {
                      minLength : {
                        value: 5,
                        message: translatedForm.MinLengthString(5)
                      },
                      maxLength : {
                        value: 100,
                        message: translatedForm.MaxLenghtString(100)
                      },
                      required: translatedForm.Required
                    })}
                    type="text" maxLength="100" isInvalid={errors.Name}  
                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                    }
                </Form.Group>
          
            <Form.Group className="mb-3 mt-3">
            <Form.Label className="text-black">{translated.Fuel} <sup className="text-danger">*</sup></Form.Label>
            
                <Controller
                    name="Fuel"                                
                    control={control}
                    rules={{ required: translatedForm.Required }}
                    render={({ field }) => (
                    <Select 
                    {...field}                               
                        isSearchable
                        options={listFuels}                        
                        placeholder={translatedForm.SelectFromList}
                        classNamePrefix="select"
                    />
                    )}
                />
                {errors.Fuel && 
                    <span className="text-danger font-size-13">{errors.Fuel.message}</span>
                }   

            </Form.Group>

            <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.Height}</Form.Label>
                <Form.Control 
                    {...register("Height",)}
                    type="number" maxLength="100" isInvalid={errors.Height}  
                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.Height && 
                    <span className="text-danger font-size-13">{errors.Height.message}</span>
                    }
            </Form.Group>

            <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.MinLevel}</Form.Label>
                <Form.Control 
                    {...register("LevelHeightLow")}
                    type="number" maxLength="100" isInvalid={errors.LevelHeightLow}  
                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.LevelHeightLow && 
                    <span className="text-danger font-size-13">{errors.LevelHeightLow.message}</span>
                    }
            </Form.Group>

            <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.MaxLevel}</Form.Label>
                <Form.Control 
                    {...register("LevelHeightUp")}
                    type="number" maxLength="100" isInvalid={errors.LevelHeightUp} 
                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.LevelHeightUp && 
                    <span className="text-danger font-size-13">{errors.LevelHeightUp.message}</span>
                    }
            </Form.Group>

            <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.MinVolume}</Form.Label>
                <Form.Control 
                    {...register("VolumeLow")}
                    type="number" maxLength="100" isInvalid={errors.VolumeLow}  
                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.VolumeLow && 
                    <span className="text-danger font-size-13">{errors.VolumeLow.message}</span>
                    }
            </Form.Group>

            <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.MaxVolume}</Form.Label>
                <Form.Control 
                    {...register("VolumeUp")}
                    type="number" maxLength="100" isInvalid={errors.VolumeUp}  
                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.VolumeUp && 
                    <span className="text-danger font-size-13">{errors.VolumeUp.message}</span>
                    }
            </Form.Group>

            <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.FullVolume}</Form.Label>
                <Form.Control 
                    {...register("Volume")}
                    type="number" maxLength="100" isInvalid={errors.Volume} 
                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.Volume && 
                    <span className="text-danger font-size-13">{errors.Volume.message}</span>
                    }
            </Form.Group>
                
            <div className="form-group mt-3">
            <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                {loadingData.button && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingData.button && <span>{translated.SaveChanges}</span>}
                {!loadingData.button && <span>{translated.SaveChanges}</span>}
                </button>
            </div>
            
        </Form>
    )
}
import React, { useState, useMemo, Component } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from './../../components/Layout';
import { Button, Card, Table, Modal, ListGroup, Accordion, Dropdown, ProgressBar, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { YMaps, Map, Placemark, GeoObject, TypeSelector, ListBoxItem, ListBox } from '@pbe/react-yandex-maps';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import moment from 'moment/moment';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import tank100 from "../../img/tanks/black/64/barrel-black-green-100.png";
import tank90 from "../../img/tanks/black/64/barrel-black-green-090.png";
import tank80 from "../../img/tanks/black/64/barrel-black-green-080.png";
import tank70 from "../../img/tanks/black/64/barrel-black-green-070.png";
import tank60 from "../../img/tanks/black/64/barrel-black-green-060.png";
import tank50 from "../../img/tanks/black/64/barrel-black-green-050.png";
import tank40 from "../../img/tanks/black/64/barrel-black-yellow-040.png";
import tank30 from "../../img/tanks/black/64/barrel-black-yellow-030.png";
import tank20 from "../../img/tanks/black/64/barrel-black-red-020.png";
import tank10 from "../../img/tanks/black/64/barrel-black-red-010.png";
import tank_black from "../../img/tanks/black/64/barrel-black.png";
import { DashboardTranslate } from '../../lang/dashboard';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import { TranslateSection } from '../../lang/stations/translateSection';
import api from '../../utils/axiosInterceptions';

const Dashboard = () => {

    const router = useHistory();
    const [fillings, setFillings] = useState([])
    const [listStations, setListStations] = useState([])
    const [placeMarks, setPlaceMarks] = useState([])
    const translated = useLittera(DashboardTranslate);
    const translatedSection = useLittera(TranslateSection);
    const [centerMap, setCenterMap] = useState([0, 0])
    const methods = useLitteraMethods();
    const [isLoading, setIsLoading] = useState(true)
    const [modalSection, setModalSection] = useState(false)
    const [selectedSection, setSelectedSection] = useState({
        name:'',
        fuel:null,
        fuelBalance:0,
        allVolume:0,
        currentVolume:0,
    })

    const placeMark = {
        geometry: [56.848217, 53.236675],
        properties:{
            balloonContentBody:"",
        },
        options:{
            iconLayout: 'default#image',
            iconImageHref: tank_black,
            iconImageSize: [64, 64],
        }
    }

    const columns = useMemo(
        () => [
          {
            accessorFn: (row) => row.firstname != "" && row.lastname != ""
                ? row.firstname + ' ' + row.lastname
                : "Не указано",
            id: 'userName',
            header: translated.User,
          },
          {
            accessorFn: (row) => moment(row.fillingDateTime).format("DD.MM.YYYY HH:mm"),
            id: 'date',
            header: translated.FillingDate,
          },
          {
            accessorFn: (row) => row.fuelName != "" ? row.fuelName : translated.Unknow,
            id: 'fuel',
            header: translated.Fuel,
          },
          {
            accessorFn: (row) => row.countLitre,
            id: 'liters',
            header: translated.Liters,
          },
          {
            accessorFn: (row) => row.controllerName,
            id: 'controllerName',
            header: translated.Controller,
          }
        ],
    [],
    );

    const getProgressBar = (volume, fuel, section) => {
                        
        let success = `<span style="font-size:14px;color:#000;display:block;margin-top:10px"><strong>${section} (${fuel})</strong></span>`+
        `<div style="margin-top:5px;width:100%;background-color:#e0e0e0;padding:1px;border-radius:50px;text-align:center;font-weight:bold;color:#fff">`+ 
            `<span style="width:${volume}%;padding:1px;display:block;background-color:#238B49;border-radius:50px;">${volume}%</span>` +
        `</div>`;

        let danger = `<span style="font-size:14px;color:#000;display:block;margin-top:10px"><strong>${section} (${fuel})</strong></span>`+
        `<div style="margin-top:5px;width:100%;background-color:#e0e0e0;padding:1px;border-radius:50px;text-align:center;font-weight:bold;color:#fff">`+ 
            `<span style="width:${volume}%;padding:1px;display:block;background-color:#BE2F33;border-radius:50px;">${volume}%</span>` +
        `</div>`;

        let warning = `<span style="font-size:14px;color:#000;display:block;margin-top:10px"><strong>${section} (${fuel})</strong></span>`+
        `<div style="margin-top:5px;width:100%;background-color:#e0e0e0;padding:1px;border-radius:50px;text-align:center;font-weight:bold;color:#fff">`+ 
            `<span style="color:#000;width:${volume}%;padding:1px;display:block;background-color:#FF8B00;border-radius:50px;">${volume}%</span>` +
        `</div>`;

        let none = `<span style="font-size:14px;color:#000;display:block;margin-top:10px"><strong>${section} (${fuel})</strong></span>`+
        `<div style="margin-top:5px;width:100%;background-color:#e0e0e0;padding:1px;border-radius:50px;text-align:center;font-weight:bold;color:#fff">`+ 
            `<span style="color:#000;width:${100}%;padding:1px;display:block;background-color:#e0e0e0;border-radius:50px;">0%</span>` +
        `</div>`;

        let html = ''
        if(volume >= 50 && volume <= 100){
            html += success;
        }

        if(volume >= 30 && volume <= 49)
        {
            html += warning;
        }

        if(volume < 30 && volume > 0)
        {
            html += danger;
        }

        if(volume == 0)
        {
            html += none;
        }

        return html;
    }

    const getTankLevelImage = (balance) => {
        
        if(balance == 100) {
            return tank100
        } else if(balance < 100 && balance >= 90) {
            return tank90
        } else if(balance < 90 && balance >= 80) {
            return tank80
        } else if(balance < 80 && balance >= 70) {
            return tank70
        } else if(balance < 70 && balance >= 60) {
            return tank60
        } else if(balance < 60 && balance >= 50) {
            return tank50
        } else if(balance < 50 && balance >= 40) {
            return tank40
        } else if(balance < 40 && balance >= 30) {
            return tank30
        } else if(balance < 30 && balance >= 20) {
            return tank20
        } else {
            return tank10
        } 

    }

    const user = () => {
        let token = localStorage.getItem("accessToken")
        api.get('/api/users/dashboard', {
            withCredentials:true
        })
        .then(function (response) {
            console.log("dashboard")
            console.log(response)
            setListStations(response.data.dataResult.stations)
            setFillings(response.data.dataResult.fillings)
            let stations_with_sections = []
            if(response.data.dataResult.stations.length != 0)
            {                
                response.data.dataResult.stations.map(item => {                

                    let html_baloon = ''

                    if(item.sections != null)
                    {
                        item.sections.map((stat => {  
                            html_baloon += getProgressBar(stat.fuelBalance.toFixed(2), stat.fuel.name, stat.name)                                           
                        }))
                    }

                    let station = {
                        geometry: [item.lantitude, item.longtitude],
                        properties:{
                             // Зададим содержимое заголовка балуна.
                            balloonContentHeader: item.name,
                            // Зададим содержимое основной части балуна.
                            balloonContentBody: html_baloon,

                            // Зададим содержимое нижней части балуна.
                            balloonContentFooter: `<span style="font-size:14px;color:gray;font-weight:bold">${item.description}</span>`,
                            // Зададим содержимое всплывающей подсказки.
                            hintContent: item.description
                        },
                        options:{
                            iconLayout: 'default#image',
                            iconImageHref: getTankLevelImage(item.currentFuelVolume),
                            iconImageSize: [35, 35],
                        }
                    }
                    stations_with_sections.push(station)

                })

                setPlaceMarks(stations_with_sections)
                setCenterMap([response.data.dataResult.stations[0].lantitude, response.data.dataResult.stations[0].longtitude ])
            }

            setIsLoading(false)
        })
        .catch(function (error) {
            console.log(error);
        });  
    }

    React.useEffect(() => {
        methods.setLocale(CurrentSelectLanguage)
        user()
    }, [])

    const showModalSection = (data, is_show) => {
        console.log(data)
        setModalSection(is_show)
        setSelectedSection({
            name:data.name,
            fuel:data.fuel.name,
            fuelBalance:data.fuelBalance,
            allVolume:data.volume,
            currentVolume:data.fuelLitreBalance,
        })
    }
    return (
        <>
            <div className="row">
            <div className="col-xs-12 col-lg-12 col-md-12">
                <h3 className="benzamat text-black">
                    {translated.StationOnTheMap}
                    <span className="text-secondary benzamat-font-bold font-size-20"> {listStations.length}</span>
                </h3>
            </div>
            
            <div className="col-xs-12 col-md-8 col-lg-8">
            {
                isLoading
                ?
                <Skeleton count={1} height={350}/>
                :
                <YMaps query={{ lang: 'en_RU' }}>
                    <Map width="100%" height="350px"
                    
                        state={{
                            center: centerMap,
                            zoom: 9,
                            controls: ["zoomControl", "fullscreenControl"],
                        }}
                        modules={["control.ZoomControl", "control.FullscreenControl", "geoObject.addon.hint", "geoObject.addon.balloon"]}
                    >
                    <TypeSelector options={{ float: "right" }} />
                    {
                        placeMarks.length != 0
                        ?
                        placeMarks.map((item, index) => (
                            <Placemark key={index} {...item}/>
                        ))
                        : null
                    }
                    </Map>
                </YMaps>
            }
                
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4" style={{overflowY:"scroll", height:"350px"}}>
            {
                isLoading
                ?
                <Skeleton count={1} height={350}/>
                :
                <ListGroup >
                {
                    listStations.length != 0
                    ?
                    listStations.map((item) => (
                        <ListGroup.Item >
                            <span className="font-size-16">
                                <strong>{item.name}</strong>
                            </span>
                            <p className="font-size-14">{item.description}</p>
                            
                            {                            
                                <Accordion defaultActiveKey={item.sections.map((i) => i.id)} alwaysOpen alwaysOpen>
                                    {
                                        item.sections.map((section) => (
                                            <>
                                            <Accordion.Item eventKey={section.id}>
                                                <Accordion.Header style={{"color":"#000","fontSize":"14px"}}>{section.name} ({section.fuel.name})</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="form-group">
                                                            <span className="d-block">{translated.Fuel}: {section.fuel.name}</span>
                                                            <span className="d-block">{translated.Volume}: {section.volume}</span>
                                                            <span className="d-block">{translated.CurrentVolume}: {section.fuelLitreBalance}</span>
                                                            <span className="d-block">{translated.Occupancy}: {section.fuelBalance.toFixed(2)}%</span>
                                                        </div>
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            </>
                                        ))                              
                                    }
                                </Accordion>
                            }
                        
                            <Dropdown className="mt-3  d-grid" style={{width:"100%"}}>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    {translated.Actions}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {
                                        setCenterMap([item.lantitude, item.longtitude])
                                    }}>
                                        {translated.OpenTheMap}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => router.push(`/station/${item.id}`)}>
                                        {translated.More}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ListGroup.Item>
                    ))
                    :                
                    <ListGroup.Item >
                        <span className="font-size-14">
                            {translated.ObjectNotFound}
                        </span>
                    </ListGroup.Item>
                }

            </ListGroup>
            }
            </div>

            <div className="col-xs-12 col-lg-12 col-md-12 mt-4">
                <h4 className="text-black benzamat">
                    {translated.LastFillings}
                </h4>
            </div>
            <div className="col-xs-12 col-lg-12 col-md-12">
            {
                isLoading
                ?
                <Skeleton count={1} height={150}/>
                :
                <MaterialReactTable 
                    columns={columns} 
                    data={fillings}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                    elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                    },
                    }}
                    muiTableBodyCellProps={{
                    sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                    },
                    }}
                />
            }          

            </div>
        </div>

        </>
    );
  
}

export default Dashboard;

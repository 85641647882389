import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Form, OffCanvas, Table, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import './../../boxicons.css';
import { TranslateRole } from '../../lang/roles/translateRole';
import {FormLocale} from '../../lang/form';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';     
import api from '../../utils/axiosInterceptions';

const Roles = (props) => {

  const router = useHistory()
  const [catalogData, setCatalogData] = useState({});
  const [countRoles, setCountRoles] = useState(0)
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [catalogName, setCatalogName] = useState('')
  const [listValues, addValueToList] = useState([])
  const translated = useLittera(TranslateRole);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })
  const {isAuth} = useContext(AuthContext);
  const { register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const handleShow = () => setShowFormAdd(true);
  const handleClose = () => setShowFormAdd(false);

  const onSubmit = data => {

    setLoadingData({button:true})
    api.post("/api/roles/create", {
        Name: data.Name
    })
    .then(function (response) {
      console.log(response);
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingData({button:false})
      addValueToList(response.data.listDatas)
      reset({
        Name:''
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });

    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
  
  }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    api.get("/api/roles/all", {
      headers:{
        "content-type" : "application/json"
      }
    })
    .then(function (response) {
        console.log("roles")
        console.log(response)
        addValueToList(response.data.listDatas)
        setCountRoles(response.data.listDatas.length)
        displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      console.log(error);
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });

  }, [])

  const buttons = (rowData) => {
    return <Button onClick={() => router.push(`/role/${rowData.id}`)} className="btn btn-light btn-sm">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </Button> 
  }

    return(
      isAuth ?
      <>
      <div className="d-sm-flex align-items-center justify-content-between">
            <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                {translated.Title}
                <span className="text-secondary benzamat-font-bold font-size-20"> {countRoles}</span>
            </h2>           
            <div className="page-title-right">
                <Button varian="primary" onClick={handleShow}>
                    <i className="bx bx-plus font-size-16 align-middle me-2 "></i>{translated.Add}
                </Button>
            </div>            
        </div>
        <div className="row">
        <div className="col-xs-12 col-lg-12 col-md-12 ">         
            <div className="mt-4">
            <Table striped bordered hover className="text-black">
              <thead>
                <tr>
                  <th>{translated.Name}</th>
                  <th>{translated.Actions}</th>
                </tr>
              </thead>
              <tbody>
                {
                  listValues.map((item) => (
                    <tr>
                      <td className="text-black">{item.name}</td>
                      <td>
                      <Button onClick={() => router.push(`/role/${item.id}`)} className="btn btn-light btn-sm">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </Button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
            </div>
        </div>
    </div>
    <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>{translated.CreateRole}</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body>  

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
            <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                required : translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  placeholder={translatedForm.EnterValue(100)} className="form-control"  />
              {errors.Name && 
                <span className="text-danger font-size-13">{errors.Name.message}</span>
              }
            </Form.Group>

            <div className="form-group mt-3">
                <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                    {loadingData.button && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingData.button && <span>{translated.Add}</span>}
                    {!loadingData.button && <span>{translated.Add}</span>}
                </button>
            </div>
        
            </Form>

        </Offcanvas.Body>
    </Offcanvas>
    </>
    :
    <Redirect to='/login'/>
    );
}

export default Roles;
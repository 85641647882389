import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateController = {

    TitleSingle:{
        en_US: "Controller",
        ru_RU: "Контроллер"
    },
    ListController: {
        en_US: "Controllers",
        ru_RU: "Контроллеры"
    },
    Undefined:{
        en_US: "Unknow",
        ru_RU: "Неизвестно"
    },
    ListGroup:{
        en_US: "Groups",
        ru_RU: "Группы"
    },
    ActionWithControllers:{
        en_US: "Action with controllers",
        ru_RU: "Действия с контроллерами"
    },
    PermissionDenied:{
        en_US: "Permission denied",
        ru_RU: "У вас недостаточно прав для доступа к разделу"
    },
    AddControllerTitle:{
        en_US: "Add controller",
        ru_RU: "Добавление контроллера"
    },
    AddController:{
        en_US: "Add controller",
        ru_RU: "Добавить контроллер",
    },
    AddGroup:{
        en_US: "Add group",
        ru_RU: "Добавить группу"
    },
    UpdateList:{
        en_US: "Update list of controllers",
        ru_RU: "Обновить список"
    },
    EnableAutoRead:{
        en_US: "Enable auto read",
        ru_RU: "Включить автоопрос"
    },
    DisabledAutoRead:{
        en_US: "Disable auto read",
        ru_RU: "Выключить автоопрос"
    },
    MessageAfterAdd:{
        en_US: "After adding the controller, the automatic process of reading the factory settings and writing them to the system will start",
        ru_RU: "После добавления контроллера будет запущен автоматический процесс считывания заводских настроек и запись их в систему"
    },
    Cancel:{
        en_US: "Cancel",
        ru_RU: "Отмена"
    },
    Submit:{
        en_US: "Submit",
        ru_RU: "Подтвердить"
    },
    SetData:{
        en_US: "Write data",
        ru_RU: "Записать данные"
    },
    SetFactory:{
        en_US: "Reseting settings",
        ru_RU: "Сброс к заводским настройкам"
    },
    TextSetFactory:{
        en_US: "Are you sure you want to reset the controller to factory settings?",
        ru_RU: "Вы уверены, что хотите сбросить контроллер к заводским настройкам?"
    },
    DeleteController: {
        en_US: "Delete the controller",
        ru_RU: "Удаление контроллера"
    },
    TextDeleteController:{
        en_US: "Are you sure you want to delete the controller?",
        ru_RU: "Вы уверены, что хотите удалить контроллер?"
    },
    SynchControllerTime:{
        en_US: "Controller time synchronization",
        ru_RU: "Синхронизировать время на контроллере"
    },
    WriteControllerConfig:{
        en_US: "Write controller configuration",
        ru_RU: "Записать настройки контроллера"
    },
    WriteLimits:{
        en_US: "Write users and transports limits",
        ru_RU: "Записать лимиты пользователей и ТС"
    },
    WriteTable:{
        en_US: "Write calibration charts of level sensor",
        ru_RU: "Записать градуировочные таблицы для уровнемеров"
    },
    ReadLogEvents:{
        en_US: "Read log events",
        ru_RU: "Считать журнал событий"
    },
    ReadLimits:{
        en_US: "Read limits of users and transports",
        ru_RU: "Чтение лимитов пользователей и ТС"
    },
    ReadDataButton:{
        en_US: "Read data",
        ru_RU: "Чтение данных"
    },
    ReadingJournal: {
        en_US: "Reading journal",
        ru_RU: "Чтение журнала"
    },
    ReadingData:{
        en_US: "Reading data",
        ru_RU: "Чтение данных"
    },
    UpdateFirmware:{
        en_US: "Update firmware",
        ru_RU: "Обновление прошивки"
    },
    ReadingSettings:{
        en_US: "Reading settings",
        ru_RU: "Чтение настроек"
    },
    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    ID:{
        en_US: "Controller's ID",
        ru_RU: "ID контроллера"
    },
    Station:{
        en_US: "Station",
        ru_RU: "Станция"
    },
    Sections:{
        en_US: "Sections",
        ru_RU: "Секции"
    },
    VersionSoftware:{
        en_US: "Software version",
        ru_RU: "Версия ПО"
    },
    VersionProtocol:{
        en_US: "Protocol version",
        ru_RU: "Версия протокола"
    },
    TypeController:{
        en_US: "Controller's type",
        ru_RU: "Тип контроллера"
    },
    AutoRead:{
        en_US: "Auto read",
        ru_RU: "Автоопрос"
    },
    Settings:{
        en_US: "Settings",
        ru_RU: "Настройки"
    },
    Enable:{
        en_US: "Enable",
        ru_RU: "Включено"
    },
    Disable:{
        en_US: "Disable",
        ru_RU: "Отключено"
    },
    Readed:{
        en_US: "Readed",
        ru_RU: "Считаны"
    },
    NotReaded:{
        en_US: "Not readed",
        ru_RU: "Не считаны"
    },
    Write:{
        en_US: "A-Z, 0-9 symbols for controller's ID",
        ru_RU: "Введите ID содержащий цифры 0-9 и буквы A-F"
    },
    General:{
        en_US: "General",
        ru_RU: "Основное"
    },
    Users:{
        en_US: "Users",
        ru_RU: "Пользователи"
    },
    Transports:{
        en_US: "Transports",
        ru_RU: "Транспортные средства"
    },
    Companies:{
        en_US: "Companies",
        ru_RU: "Компания"
    },
    LevelMeters:{
        en_US: "Level meters",
        ru_RU: "Уровнемеры"
    },
    Pump:{
        en_US: "Pump",
        ru_RU: "Насос"
    },
    Valves:{
        en_US: "Valves",
        ru_RU: "Клапаны"
    },
    ExternalInputs:{
        en_US: "External inpus",
        ru_RU: "Внешние входы"
    },
    FlowSensor:{
        en_US: "Flow sensors",
        ru_RU: "Датчики расхода"
    },
    Metrology:{
        en_US: "Metrology",
        ru_RU: "Метрология"
    },
    Filling:{
        en_US: "Filling",
        ru_RU: "Налив"
    },
    Modem:{
        en_US: "Modem",
        ru_RU: "Модем"
    },
    Auth:{
        en_US: "Login and password",
        ru_RU: "Логин и пароль"
    },
    Topaz:{
        en_US: "Topaz",
        ru_RU: "Топаз"
    },
    Gilbarco:{
        en_US: "Gilbarco",
        ru_RU: "Гилбарко"
    },
    Tokheim:{
        en_US: "Tokheim India",
        ru_RU: "Токхейм Индия" 
    },
    Livenka:{
        en_US: "Livenka",
        ru_RU: "Ливенка"
    },
    Electro:{
        en_US: "Electromechanical",
        ru_RU: "Электромеханическая"
    },
    PrinteCheque:{
        en_US: "Printer cheques",
        ru_RU: "Принтер чеков"
    },
    ParamTRK:{
        en_US: "Params",
        ru_RU: "Параметры ТРК" 
    },
    ButtonStartStop:{
        en_US: "Button start/stop",
        ru_RU: "Кнопка старт/стоп" 
    },
    Indicators:{
        en_US: "Indicators",
        ru_RU: "Индикаторы" 
    },
    MessageAutoReadActive:{
        en_US: "The controller has auto-polling enabled. Disable auto-request to manually read settings",
        ru_RU: "У контроллера включен автоопрос. Отключите автопрос для ручного чтения настроек" 
    },
    Yes:{
        en_US: "Active",
        ru_RU: "Да"
    },
    No:{
        en_US: "Disabled",
        ru_RU: "Нет"
    },
    ModemInstall:{
        en_US: "The moded is installed",
        ru_RU: "Модем установлен"
    },
    TurnModemPower:{
        en_US: "Turn on modem power",
        ru_RU: "Автономный налив"
    },
    OpenCollector:{
        en_US: "Open collector",
        ru_RU: "Автономный налив"
    },
    RegularOutput:{
        en_US: "Regular output",
        ru_RU: "Обычный вход"
    },
    SelfFilling:{
        en_US: "Self-contained filling",
        ru_RU: "Автономный налив"
    },
    RefuelingComputer:{
        en_US: "Refueling from a computer",
        ru_RU: "Заправки с компьютера"
    },
    OfflineComputer:{
        en_US: "Offline or from a computer",
        ru_RU: "Автономный или с компьютера"
    },
    ReadDataFromController:{
        en_US: "Read data from a controller",
        ru_RU: "Чтение данных с контроллера"
    },
    SetDataToController:{
        en_US: "Set settings to controller",
        ru_RU: "Запись настроек в контроллер"
    },
    SetConfiguration:{
        en_US: "Set configuration",
        ru_RU: "Установка конфигурации"
    },
    DefaultSettings:{
        en_US: "Set default settings",
        ru_RU: "Сброс к заводским настройкам"
    },
    FuelIntake:{
        en_US: "Fuel intake",
        ru_RU: "Прием топлива"
    },
    RfidReader:{
        en_US: "Rfid reader",
        ru_RU: "Считыватель"
    },
    TRKWork:{
        en_US: "Is working",
        ru_RU: "ТРК работает"
    },
    OperationMode:{
        en_US: "Operating mode",
        ru_RU: "Режим работы"
    },
    Sum:{
        en_US: "Total",
        ru_RU: "Сумма"
    },
    RemoveSurvey:{
        en_US: "Remove survey",
        ru_RU: "Снять с опроса"
    },
    Topaz20:{
        en_US: "Topaz 2.0",
        ru_RU: "Топаз 2.0"
    },
    InterfacePanel:{
        en_US: "Interface panel",
        ru_RU: "Интерфейсная панель"
    },
    PumpControl:{
        en_US: "Pump control",
        ru_RU: "Управление насосом"
    },
    TurningPumpOn:{
        en_US: "Turning pump on",
        ru_RU: "Включение насоса"
    },
    PumpSwitchDelay:{
        en_US: "Pump switching on delay, sec",
        ru_RU: "Задержка включения насоса, с."
    },
    PumpRelay1:{
        en_US: "Relay 1",
        ru_RU: "Реле 1"
    },
    PumpOut1:{
        en_US: "Out 1",
        ru_RU: "Выход 1"
    },
    PumpConnect:{
        en_US: "Connection",
        ru_RU: "Замыкание"
    },
    ShutOffValve:{
        en_US: "Shut-off valve",
        ru_RU: "Отсечной клапан"
    },
    ShutOffControlValve:{
        en_US: "Shut-off valve control",
        ru_RU: "Управление отсченым клапаном"
    },
    TurnShutOffValve:{
        en_US: "Turn shut-off valve on",
        ru_RU: "Включение клапана"
    },
    ReducingValve:{
        en_US: "Reducing valve",
        ru_RU: "Клапан снижения"
    },
    ReducingControlValve:{
        en_US: "Reducing valve control",
        ru_RU: "Управление клапаном снижения"
    },
    TurnReducingValve:{
        en_US: "Turn reducing valve on",
        ru_RU: "Включение клапана снижения"
    },
    Relay3:{
        en_US: "Relay 3",
        ru_RU: "Реле 3"
    },
    Relay2:{
        en_US: "Relay 2",
        ru_RU: "Реле 2"
    },
    NotConnected:{
        en_US: "Not connected",
        ru_RU: "Не подключен"
    },
    Connected:{
        en_US: "Connected",
        ru_RU: "Подключен"
    },
    FlowSensor:{
        en_US: "Flow sensor",
        ru_RU: "Датчик расхода"
    },
    CountImpulseFW:{
        en_US: "Count impulse while",
        ru_RU: "Счет импульсов при"
    },
    FirstChannelFW:{
        en_US: "First channel in",
        ru_RU: "Вход первого канал"
    },
    SecondChannelFW:{
        en_US: "Second channel in",
        ru_RU: "Вход второго канал"
    },
    ImpulseDurationFW:{
        en_US: "Impulse duration, msec",
        ru_RU: "Длительность импульса, с."
    },
    WaitingTimeFirst:{
        en_US: "Waiting time of the first impulse (flow), sec",
        ru_RU: "Время ожидания первого импульса (расход), с."
    },
    WaitingTimeNext:{
        en_US: "Waiting time of the subsequent impulses (flow), sec",
        ru_RU: "Время ожидания следующих импульсов (расход), с."
    },
    SingleChannel:{
        en_US: "Single channel pulse sensor",
        ru_RU: "Импульсный одноканальный"
    },
    DoubleChannel:{
        en_US: "Double channel pulse sensor",
        ru_RU: "Импульсный двухканальный"
    },
    MassFlowSensor:{
        en_US: "Mass flow sensor",
        ru_RU: "Массомер Sealand"
    },
    MassFlowEmis:{
        en_US: "Mass flow EMIS",
        ru_RU: "Массомер EMIS"
    },
    DigitalBenza:{
        en_US: "Digital Benza",
        ru_RU: "Цифровая Benza"
    },
    ClosedToOpen:{
        en_US: "Closed switches to open",
        ru_RU: "Переход из замкнуто в разамкнуто"
    },
    OpenToClose:{
        en_US: "Open switches to closed",
        ru_RU: "Переход и разамкнуто в замкнуто"
    },
    IN_FW:{
        en_US: "IN 1",
        ru_RU: "Вход 1"
    },
    IN2_FW:{
        en_US: "IN 2",
        ru_RU: "Вход 2"
    },
    ImpulseCountPerMetro:{
        en_US: "Impulse count per liter",
        ru_RU: "Число импульсов на литр"
    },
    AdjustmentFactor:{
        en_US: "Adjustment factor",
        ru_RU: "Юстировочный коэффицент"
    },
    Totaliser:{
        en_US: "Totaliser, L.",
        ru_RU: "Сумматор, л."
    },
    ElectronicSeal:{
        en_US: "Metrological significant change counter parts (Electronical seal)",
        ru_RU: "Электронная пломба"
    },
    CommonSensor:{
        en_US: "Common sensor",
        ru_RU: "Общий сенсор"
    },
    Input1:{
        en_US: "Input 1",
        ru_RU: "Вход 1"
    },
    Input2:{
        en_US: "Input 2",
        ru_RU: "Вход 2"
    },
    Input3:{
        en_US: "Input 3",
        ru_RU: "Вход 3"
    },
    HatchOpenType:{
        en_US: "Hatch open type",
        ru_RU: "Тип сенсора открытяи крышки"
    },
    OpeningSensor:{
        en_US: "Opening sensor input",
        ru_RU: "Вход сенсора открытия входа"
    },
    PrinterType:{
        en_US: "Printer type",
        ru_RU: "Тип принтера"
    },
    ChequeHeader:{
        en_US: "Cheque header",
        ru_RU: "Заголовок чека"
    },
    ChequeString:{
        en_US: "String 1",
        ru_RU: "Строка 1"
    },
    ChequeString2:{
        en_US: "String 2",
        ru_RU: "Строка 2"
    },
    TotalCheque:{
        en_US: "Cheque total",
        ru_RU: "Итог чека"
    },
    AccessPoint:{
        en_US: "Access point (APN)",
        ru_RU: "Точка доступа (APN)"
    },
    UserName:{
        en_US: "User name",
        ru_RU: "Пользователь"
    },
    Port:{
        en_US: "Port",
        ru_RU: "Порт"
    },
    Login:{
        en_US: "Login",
        ru_RU: "Логин"
    },
    Password:{
        en_US: "Password",
        ru_RU: "Пароль"
    },
    Address:{
        en_US: "Network address",
        ru_RU: "Сетевой адрес"
    },
    IPAddress:{
        en_US: "IP address",
        ru_RU: "IP адрес"
    },
    Address:{
        en_US: "Address",
        ru_RU: "Адрес"
    },
    Server:{
        en_US: "Server",
        ru_RU: "Сервер"
    },

    ExternalButton:{
        en_US: "External button",
        ru_RU: "Внешняя кнопка"
    },
    ButtonExternalType:{
        en_US: "Button external type",
        ru_RU: "Тип внешней кнопки"
    },
    ButtonInput:{
        en_US: "Button input",
        ru_RU: "Вход кнопки"
    },
    MinStartSignal:{
        en_US: "Minimal start/stop signal length, sec.",
        ru_RU: "Мин. длительность сигнала старт/стоп, с."
    },
    StartButtonPanel:{
        en_US: "Start refueling with the Start button on thecontroller panel without identyifying the user or vehicle",
        ru_RU: "Пуск заправки кнопкой Старт на панели контроллера без идентификации пользователя или ТС"
    },
    RefuelingTypeButton:{
        en_US: "Refueling type by start button",
        ru_RU: "Тип заправки по кнопке старт"
    },
    ActivationViewReadingButton:{
        en_US: "Activation of viewing the readings of the level gauges for the button 0",
        ru_RU: "Активация просмотра показаний уровнемеров на кнопку 0"
    },
    SettingsButton:{
        en_US: "Settings button",
        ru_RU: "Настройки режима работы"
    },
    ButtonOnDisconnect:{
        en_US: "Button on disconnection",
        ru_RU: "Кнопка на размыкании"
    },
    ButtonOnConnection:{
        en_US: "Button on connection",
        ru_RU: "Кнопка на замыкании"
    },
    GunOnDisconnect:{
        en_US: "Gun on disconnection",
        ru_RU: "Пистолет на размыкании"
    },
    GunOnConnect:{
        en_US: "Gun on connection",
        ru_RU: "Пистолет на замыкании"
    },
    NotDose:{
        en_US: "Not dose injection",
        ru_RU: "Без набора дозы"
    },
    WithDose:{
        en_US: "Dose injection",
        ru_RU: "С набором дозы"
    },

    FuelType:{
        en_US: "Fuel type",
        ru_RU: "Топливо"
    },
    MinimalDose:{
        en_US: "Minimal dose, l(kg)",
        ru_RU: "Минимальная доза, л (кг)"
    },
    MaximalDose:{
        en_US: "Maximal dose, l(kg)",
        ru_RU: "Максимальная доза, л (кг)"
    },
    ReduceEndFill:{
        en_US: "Reduce capacity of the end of fill, l (kg)",
        ru_RU: "Снижение расхода в конце налива, л (кг)"
    },
    FullCapacityAfterFilling:{
        en_US: "Full capacity after filling, l (kg)",
        ru_RU: "Полный расход после отпуска, л (кг)"
    },
    EndFillAfterSwitch:{
        en_US: "End fill after switching pump off, sec",
        ru_RU: "Завершение налива после выкл. насоса через, с."
    },
    StopFill:{
        en_US: "Stop fill in case of flow sensor error",
        ru_RU: "Остановка налива при ошибке от датчиков расхода"
    },
    TimeLastFill:{
        en_US: "Time of last fill indication, sec",
        ru_RU: "Время индикации последнего налива, с."
    },

    InputIn:{
        en_US: "Input IN1",
        ru_RU: "Вход IN1"
    },
    InputIn2:{
        en_US: "Input IN2",
        ru_RU: "Вход IN2"
    },
    InputIn3:{
        en_US: "Input IN3",
        ru_RU: "Вход IN3"
    },
    InputIn4:{
        en_US: "Input IN4",
        ru_RU: "Вход IN4"
    },
    MininalSignalDuration:{
        en_US: "Minimal signal duration for the external button, ms",
        ru_RU: "Мин. длительность сигнала для внешней кнопки, мс"
    },
    MessageButtonExternalInputs:{
        en_US: "Meaning Closing pushbutton or Opening pushbutton, must be in only one input",
        ru_RU: "Значение Кнопка на замыкание или Кнопка на размыкание, должна быть только в одном входе"
    },
    NotSet:{
        en_US: "Not set",
        ru_RU: "Не задано"
    },
    TamperSensor:{
        en_US: "Tamper sensor",
        ru_RU: "Датчик вскрытия"
    },
    ClosingButton:{
        en_US: "Closing button (normally open)",
        ru_RU: "Кнопка на замыкание (нормально разомкнутая)"
    },
    OpenButton:{
        en_US: "Open button (normally closed)",
        ru_RU: "Кнопка на размыкание (нормально замкнутая)"
    },

    ModalHardwareTitle:{
        en_US: "Set controller configuration",
        ru_RU: "Установка конфигурации"
    },
    MessageModalHardware:{
        en_US: "Before installong, make sure that the controller supports selected hardware and software configuration",
        ru_RU: "Перед установкой убедитесь, что контроллер поддерживает выбранную аппаратно-программную конфигурацию"
    },
    SaveConfiguration:{
        en_US: "Install hardware configuration",
        ru_RU: "Установить конфигурацию"
    },
    SelectFileForUpload:{
        en_US: "Select file for upload",
        ru_RU: "Выберите файл для загрузки"
    },
    GetLevelMeterData:{
        en_US: "Get level meters data",
        ru_RU: "Показания уровнемеров"
    },
    MessageSelectTypeLevelMeter:{
        en_US: "To successfully record controller and level meter settings, all cells must have the same types. Different types will not be written to the controller",
        ru_RU: "Для успешной записи настроек контроллера и уровнемеров, во всех ячейках должны быть одинаковые типы. Разные типы не запишутся в контроллер"
    },
    SettingCriticalLevel:{
        en_US: "Settings critical levels",
        ru_RU: "Настройки критических уровней"
    },
    PeriodSaveLevelMeterData:{
        en_US: "Period save level meter data",
        ru_RU: "Период сохранения показаний уровнемеров"
    },
    Period:{
        en_US: "Period (min)",
        ru_RU: "Период, мин"
    },
    Disable:{
        en_US: "Disable",
        ru_RU: "Отключено"
    },
    SettingOnController:{
        en_US: "Settings on controller",
        ru_RU: "Настройки на контроллере"
    },
    ControlBalance:{
        en_US: "Controler fuel's balance",
        ru_RU: "Контроль остатка топлива"
    },
    HeaderLevelMeterData:{
        en_US: "Reading of level meters",
        ru_RU: "Показания уровнемеров"
    },
    LevelMeter_1:{
        en_US: "Level meter 1",
        ru_RU: "Уровнемер 1"
    },
    LevelMeter_2:{
        en_US: "Level meter 2",
        ru_RU: "Уровнемер 2"
    },
    LevelMeter_3:{
        en_US: "Level meter 3",
        ru_RU: "Уровнемер 3"
    },
    LevelMeter_4:{
        en_US: "Level meter 4",
        ru_RU: "Уровнемер 4"
    },
    Level:{
        en_US: "Level",
        ru_RU: "Уровень"
    },
    Temperature:{
        en_US: "Temperature",
        ru_RU: "Температура"
    },
    Filling:{
        en_US: "Filling",
        ru_RU: "Заполнение"
    },
    FullVolume:{
        en_US: "Full volume",
        ru_RU: "Общий объем"
    },
    Volume:{
        en_US: "Volume",
        ru_RU: "Объем"
    },
    Massa:{
        en_US: "Mass",
        ru_RU: "Масса"
    },
    Density:{
        en_US: "Density",
        ru_RU: "Плотность"
    },
    StateCriticalLevel:{
        en_US: "State of critical level",
        ru_RU: "Состояние критических уровней"
    },
    FuelVolume:{
        en_US: "Fuel volume",
        ru_RU: "Объем основного продукта"
    },
    ProductFluidLevel:{
        en_US: "Product fluid level",
        ru_RU: "Уровень подтоварной жидкости"
    },
    CodeError:{
        en_US: "Code error",
        ru_RU: "Код ошибки"
    },
    CodeErrorText:{
        en_US: "Error code 1: No response from the level gauge. Error code 2: CRC error. Error code 3: Data is invalid",
        ru_RU:"Код ошибки 1: Нет ответа от уровнемера. Код ошибки 2: Ошибка CRC. Код ошибки 3: Данные недостоверны"
    },
    LevelMeter:{
        en_US: "Level meter ",
        ru_RU: "Уровнемер " 
    },
    Struna:{
        en_US: "PPP Struna+",
        ru_RU: "ППП Струна+" 
    },
    Type:{
        en_US: "Type",
        ru_RU: "Тип"
    },
    Address:{
        en_US: "Address",
        ru_RU: "Адрес"
    },
    GradTable:{
        en_US: "Calibration size",
        ru_RU: "Градуировочная таблица"
    },
    Params:{
        en_US: "Params",
        ru_RU: "Параметры"
    },

    Occupancy:{
        en_US: "Occupancy %",
        ru_RU: "Заполненность %"
    },
    LowLevel:{
        en_US: "Low level",
        ru_RU: "Нижний уровень"
    },
    LevelSensorSetting:{
        en_US: "Level sensor settings",
        ru_RU: "Настройки параметров уровнемера"
    },
    RelativeUnits:{
        en_US: "Relative units per 10 mm",
        ru_RU: "Относительных единиц на 10 мм"
    },
    OffsetFromBottomSection:{
        en_US: "Offset from the bottom of section, mm",
        ru_RU: "Смещение от дна секции, мм" 
    },
    CalibrationChartSize:{
        en_US: "Calibration chart size",
        ru_RU: "Размер градуировочной таблицы" 
    },
    MeasuringRange:{
        en_US: "Measuring range",
        ru_RU: "Диапазон измерения" 
    },
    LengthDUT:{
        en_US: "Length of DUT in mm",
        ru_RU: "Длина ДУТа в мм" 
    },
    CalibrationTable:{
        en_US: "Calibration table",
        ru_RU: "Градуировочная таблица" 
    },
    MessageErrorLengthDut4096:{
        en_US: "With a measurement range of 4096, the length of the DUT must be at least 640 mm",
        ru_RU: "При диапазоне измерения 4096, длина ДУТа должна быть не меньше 640 мм" 
    },
    MessageErrorLengthDut4096:{
        en_US: "With a measurement range of 1024, the length of the DUT must be at least 160 mm",
        ru_RU: "При диапазоне измерения 1024, длина ДУТа должна быть не меньше 160 мм"
    },
    MessageErroCalibrationSize:{
        en_US: "The size of the calibration table cannot be less than 100 and more than 400",
        ru_RU: "Размер градуировочной таблицы не может быть меньше 100 и больше 400"
    },
    IgnoreButtonStart:{
        en_US: "Ingore the Start button on the fuel distributor",
        ru_RU: "Игнорировать кнопку Пуск на ТРК" 
    },
    ReadSwtich:{
        en_US: "Read switch",
        ru_RU: "Геркона" 
    },
    SleeveGrade:{
        en_US: "Sleeve (Grade)",
        ru_RU: "Рукав (сорт)" 
    },
    AddressParty1:{
        en_US: "Address for party 1",
        ru_RU: "Адрес для стороны 1" 
    },
    AddressParty2:{
        en_US: "Address for party 2",
        ru_RU: "Адрес для стороны 2" 
    },
    SleeveGrade1:{
        en_US: "Sleeve 1 (Grade 1)",
        ru_RU: "Рукав 1 (сорт 1)" 
    },
    SleeveGrade2:{
        en_US: "Sleeve 2 (Grade 2)",
        ru_RU: "Рукав 2 (сорт 2)" 
    },
    SleeveGrade3:{
        en_US: "Sleeve 3 (Grade 3)",
        ru_RU: "Рукав 3 (сорт 3)" 
    },
    SleeveGrade4:{
        en_US: "Sleeve 4 (Grade 4)",
        ru_RU: "Рукав 4 (сорт 4)" 
    },
    Topaz610:{
        en_US: "Topaz 610(611), 510(511)",
        ru_RU: "Топаз 610(611), 510(511)" 
    },
    Topaz420:{
        en_US: "Topaz 420 (421)",
        ru_RU: "Топаз 420 (421)" 
    },
    ModelDispenser:{
        en_US: "Model dispencer",
        ru_RU: "Модель колонки" 
    },
    Sleeve1:{
        en_US: "Sleeve 1",
        ru_RU: "Рукав 1" 
    },
    Sleeve2:{
        en_US: "Sleeve 2",
        ru_RU: "Рукав 2" 
    },
    Sleeve3:{
        en_US: "Sleeve 3",
        ru_RU: "Рукав 3" 
    },
    Sleeve4:{
        en_US: "Sleeve 4",
        ru_RU: "Рукав 4" 
    },
    Sleeve5:{
        en_US: "Sleeve 5",
        ru_RU: "Рукав 5" 
    },
    Sleeve6:{
        en_US: "Sleeve 6",
        ru_RU: "Рукав 6" 
    },
    CountDispencer:{
        en_US: "Count dispencer",
        ru_RU: "Кол-во ТРК подключено" 
    },
    Params1:{
        en_US: "Params 1",
        ru_RU: "Параметры ТРК 1" 
    },
    Params2:{
        en_US: "Params 2",
        ru_RU: "Параметры ТРК 2" 
    },
    Params3:{
        en_US: "Params 3",
        ru_RU: "Параметры ТРК 3" 
    },
    Params4:{
        en_US: "Params 4",
        ru_RU: "Параметры ТРК 4" 
    },
    Sides:{
        en_US: "Sides",
        ru_RU: "Стороны" 
    },
    CountFuels:{
        en_US: "Count fuels",
        ru_RU: "Кол-во сортов топлива"
    },
    AddPoint:{
        en_US: "Add point",
        ru_RU: "Добавить точку"
    },
    DeleteLastPoint:{
        en_US: "Delete last point",
        ru_RU: "Удалить последнюю точку"
    },
    DeleteCalibrTable:{
        en_US: "Delete calibration table",
        ru_RU: "Удалить таблицу"
    },
    DownloadTemplateTable:{
        en_US: "Download template Excel",
        ru_RU: "Скачать шаблон градуировочной таблицы"
    },
    Point:{
        en_US: "Point",
        ru_RU: "Точка"
    },
    Sm:{
        en_US: "ct",
        ru_RU: "см"
    },
    ShortLiter:{
        en_US: "litres",
        ru_RU: "л"
    },
    Kg:{
        en_US: "kg",
        ru_RU: "кг"
    },
    KgM3:{
        en_US: "kg/m3",
        ru_RU: "л"
    }
};

import React, { Component, useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Button, Offcanvas, Form, Dropdown, DropdownButton } from 'react-bootstrap';
import logo from './../../img/benzamatlogo.png';
import LogoBenzapatPro from './../../img/white_bpro.svg';
import ru from './../../img/russia.jpg';
import us from './../../img/us.jpg';
import axios from 'axios';
import './../../app.css';
import './../../style.css';
import './../../bootstrap.css';
import * as signalR from "@microsoft/signalr"
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { translations } from '../../lang/main';
import parse from "html-react-parser";
import api from '../../utils/axiosInterceptions';

export const Header = () => {

    const router = useHistory();
    const {isAuth, setIsAuth} = useContext(AuthContext);
    const [userAuth, setUserAuth] = useState({})
    const [role, setRole] = useState('')
    const [warningMessage, setWarningMessage] = useState({
        is_active:false,
        text:''
    })
    const [countMessages, setCountMessages] = useState(0)
    const translated = useLittera(translations);
    const methods = useLitteraMethods();

    const getCurrentAuth = () => {
        api.get('/api/users/current-auth', {
            withCrenditials: true
        })
        .then(function (response) {
            methods.setLocale("ru_RU");
            console.log("auth user")
            console.log(response)
            setUserAuth(response.data.currentAuth)

            setWarningMessage({
                is_active: response.data.messageIsActive,
                text: response.data.textMessage
            })

            if(response.data.currentAuth.roleId == 1)
            {
                setRole(translated.AdminWebService)
            }

            if(response.data.currentAuth.roleId == 2)
            {
                setRole(translated.Admin)
            }

            if(response.data.currentAuth.roleId == 3)
            {
                setRole(translated.MajorOperator)
            }

            if(response.data.currentAuth.roleId == 4)
            {
                setRole(translated.Operator)
            }

            if(response.data.currentAuth.roleId == 5)
            {
                setRole(translated.Driver)
            }
            
            createNotificationJob()
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getCurrentAuth()
    }, [])

    const showMenu = () => {
        
        document.body.className = "sidebar-enable"
        let verticalButton = document.getElementById('vertical-menu-btn')

        if(verticalButton.classList.contains("isClick"))
        {
            verticalButton.classList.remove("isClick")
            document.body.classList.remove('sidebar-enable')
        }
        else
        {
            verticalButton.classList.add("isClick")
            document.body.classList.add('sidebar-enable')
        }
    }

    const createNotificationJob = () => {
        api.post('/api/notifications/job/create', {
            withCredentials:true
          })
        .then(function (response) {
          console.log("notification")
          console.log(response)
          let job_id = response.data.dataResult
  
        let connection = new signalR.HubConnectionBuilder()
          .withUrl("/notification-progress")
          .withAutomaticReconnect()
          .build();
  
        connection.on("notification-task", (data, job_id) => {
          console.log("notification task job")
          console.log(data + ' ' + job_id);
          setCountMessages(data)
        });
  
        connection.start()
          .then(() => connection.invoke("AssociateJob", job_id))
          .catch(err => console.error(err.toString()));
        
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const logOut = () => {
        localStorage.removeItem("accessToken")
        router.push("/login")
    }

      return (        
        <header id="page-topbar">
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">                       
                        <div className="navbar-brand-box d-none d-lg-block" id="navbar-brand-box-hide">
                            <a href="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src="img/logo/shotrlogo.png" alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={LogoBenzapatPro} alt="" height="35" />
                                </span>
                            </a>
                        </div>
                        <button onClick={() => showMenu()} type="button" className="btn d-xs-block d-lg-none d-md-block btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="#000000"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-list"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l11 0" /><path d="M9 12l11 0" /><path d="M9 18l11 0" /><path d="M5 6l0 .01" /><path d="M5 12l0 .01" /><path d="M5 18l0 .01" /></svg>
                        </button>
                    </div>

                    {
                        warningMessage.is_active
                        ?
                        <div className="d-flex d-none small-768-1024 d-sm-block" style={{"width":"55%"}}>
                            <span>
                                {parse(warningMessage.text)}
                            </span>
                        </div>
                        : null
                    }                    

                    <div className="d-flex">                 

                        <Dropdown className="d-inline-block">
                            <Dropdown.Toggle onClick={() => router.push("/notifications")} bsPrefix="none-toggle" style={{border:'none'}} className="bg-white me-2 text-center mt-3 noti-icon">
                                <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.82951 8.93745C4.82837 8.12208 4.98862 7.31455 5.30103 6.5614C5.61344 5.80826 6.07182 5.1244 6.64976 4.54923C7.2277 3.97407 7.91376 3.51898 8.6684 3.2102C9.42304 2.90142 10.2313 2.74506 11.0467 2.75012C14.4489 2.77541 17.1703 5.60336 17.1703 9.01512V9.62495C17.1703 12.7029 17.8142 14.4889 18.3814 15.4651C18.4425 15.5694 18.475 15.688 18.4757 15.8089C18.4763 15.9297 18.4451 16.0487 18.3851 16.1536C18.3251 16.2586 18.2385 16.3458 18.134 16.4066C18.0296 16.4675 17.9109 16.4996 17.79 16.4999H4.20909C4.08819 16.4996 3.96952 16.4674 3.86503 16.4066C3.76055 16.3458 3.67395 16.2585 3.61398 16.1535C3.55401 16.0486 3.52278 15.9296 3.52345 15.8087C3.52412 15.6878 3.55666 15.5693 3.61778 15.465C4.18525 14.4887 4.8295 12.7027 4.8295 9.62495L4.82951 8.93745Z" stroke="#3B3B3B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.25 16.5V17.1875C8.25 17.9168 8.53973 18.6163 9.05546 19.132C9.57118 19.6478 10.2707 19.9375 11 19.9375C11.7293 19.9375 12.4288 19.6478 12.9445 19.132C13.4603 18.6163 13.75 17.9168 13.75 17.1875V16.5" stroke="#3B3B3B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <span className="badge bg-danger rounded-pill" id="notificationCount">{countMessages != 0 ? countMessages >= 99 ? "99+" : countMessages : null}</span>  
                            </Dropdown.Toggle>
                        </Dropdown>                

                        <Dropdown className="d-inline-block">
                            <Dropdown.Toggle bsPrefix="btn-light" className="bg-white me-2 text-center mt-3 noti-icon">
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 11.25C11.4853 11.25 13.5 9.23528 13.5 6.75C13.5 4.26472 11.4853 2.25 9 2.25C6.51472 2.25 4.5 4.26472 4.5 6.75C4.5 9.23528 6.51472 11.25 9 11.25Z" stroke="#000" stroke-width="1.3" stroke-miterlimit="10" />
                                    <path d="M2.17969 15.1868C2.87125 13.9898 3.86565 12.9959 5.06298 12.3048C6.26031 11.6138 7.61841 11.25 9.00085 11.25C10.3833 11.25 11.7414 11.6138 12.9387 12.3049C14.136 12.996 15.1304 13.9899 15.8219 15.187" stroke="#000" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {/*<span className="d-none benzamat-font-bold text-black d-xl-inline-block ms-1">
                                    {userAuth.login}
                                    <i className="mdi mdi-chevron-down font-size-16 d-none d-xl-inline-block"></i>
                                </span><br />                            
                                <span className="d-none text-secondary d-xl-inline-block ms-1">{role}</span>*/}  
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu-end">
                                <Dropdown.Item>
                                    <span  onClick={() => router.push(`/user/${userAuth.id}`)} className="benzamat-font-bold text-black ms-1">
                                        {userAuth.login}
                                    </span><br />                            
                                    <span className="text-secondary d-xl-inline-block ms-1">{role}</span>
                                </Dropdown.Item>
                                {/*<Dropdown.Item>
                                    <i className="bx bx-user font-size-16 align-middle me-1"></i>
                                    <span key="t-profile" onClick={() => router.push(`/user/${userAuth.id}`)}>{translated.settings}</span>
                                </Dropdown.Item>*/}
                                <div className="dropdown-divider"></div>
                                <Dropdown.Item onClick={() => logOut()}>
                                    <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                                    <span key="t-logout">{translated.Logout}</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>                        
                    </div>
                </div>
            </header>
            <div className="d-flex">                
                <div className="navbar-brand-box">
                    <a href="/" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src="~/img/shotrlogo.png" alt="" height="22"/>
                        </span>
                        <span className="logo-lg">
                            <img src="~/img/shotrlogo.png" alt="" height="22" />
                        </span>
                    </a>
                    <a href="/" className="logo logo-light">
                        <span className="logo-sm">
                            <img src="~/img/shotrlogo.png" alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src="~/img/shotrlogo.png" alt="" height="19" />
                        </span>
                    </a>
                </div>
            </div>
        </header>         
      );

}

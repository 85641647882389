import React, { useContext, useEffect, useMemo, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Badge, Tab, Nav, Alert, Tooltip, OverlayTrigger, Form, Card, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import './../../boxicons.css';
import { Account } from "./account";
import { FillingPlan } from './FillingPlan';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';   
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { BalanceLang } from '../../lang/company/balance_lang';
import { CurrentSelectLanguage } from '../../helpers/language';
import api from '../../utils/axiosInterceptions';

export const CompanyBalance = () => {

    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [btnDayLoad, setBtnDayLoad] = useState(false)
    const [btnMonthLoad, setBtnMonthLoad] = useState(false)
    const [listLimits, setListLimits] = useState({})
    const [checkedIsFuelWallet, setCheckedIsFuelWallet] = useState(false)
    const [historyLimit, setHistoryLimit] = useState([])
    const [liters, setLiters] = useState(0)
    const translated = useLittera(BalanceLang);
    const methods = useLitteraMethods();
    const [isLoading, setIsLoading] = useState(false)
    const [loadingDataGrid, setLoadingDataGrid] = useState(false)
    const [dayLimit, setDayLimit] = useState()
    const [monthLimit, setMonthLimit] = useState();
    const [loadingDataBtn, setLoadingDataButton] = useState(false)
    const [limit, setLimit] = useState(0)
    const [balance, setBalance] = useState(0)
    const [money, setMoney] = useState(0)
    const [typeLimit, setTypeLimit] = useState(0)
    const [listTypeLimit, setListTypeLimit] = useState([])
    const [isUnlimited, setIsUnlimited] = useState(false)

    const listTypeLimits = [
        {label:translated.Unlimited, value:4},
        {label:translated.Absolute, value:3}
    ]

    const handleShow = () =>  {
        getTypeLimit()
        setShowFormAdd(true)
    }
    const handleClose = () => setShowFormAdd(false);
      
    const handleDayLimit = () => {
        api.get("/api/companies/details", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            setDayLimit(response.data.dataResult.countDayLimit)
            setMoney(response.data.dataResult.account)
            displayToast(response.data.message, response.data.isSuccess)
            setCurrentTime(moment().format("DD.MM.YYYY hh:mm"))
            setBtnDayLoad(false)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleUpdateHistoryLimit = () => {
        setLoadingDataButton(true)
        setLoadingDataGrid(true)
        
        api.get("/api/transport/details/history-limits", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            setLoadingDataButton(false)
            setLoadingDataGrid(false)
            setHistoryLimit(response.data.listDatas)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleGetHistory = () => {
        api.get("/api/company/details/limits/history", {
            headers:{
                "content-type" : "application/json",
                "companyId":params.id
            },
            params:{
                companyId: params.id,
            }
        })
        .then(function (response) {
            console.log("history limit")
            console.log(response)
            setHistoryLimit(response.data.listDatas)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const onSubmit = data => {
        setLoadingDataButton(true)
        api.post('/api/company/details/limits/update', {
            Id: params.id,
            CountLitre: liters,
            TypeLimitId: data.TypeLimit.value
        })
        .then(function (response) {

            console.log("update limits")
            console.log(response)
            setLimit(response.data.dataResult.limit)
            setBalance(response.data.dataResult.balance)
            setLoadingDataButton(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingDataButton(false)
        });
    }

    const getCompany = () => {
        setIsLoading(true)
        api.get("/api/company/details", {
            headers:{
              "content-type" : "application/json",
              "id":params.id
            },
            params:{
              id: params.id,
            },
            withCrenditials:true
        })
          .then(function (response) {
            console.log("permission balance")
            console.log(response)
            setLimit(response.data.dataResult.limit)
            setBalance(response.data.dataResult.balance) 
            setMoney(response.data.dataResult.account)
            setTypeLimit(response.data.dataResult.typeLimitId)
            setCheckedIsFuelWallet(!response.data.dataResult.isFuelWallet)

            let findLimit = listTypeLimits.filter((item) => item.value === response.data.dataResult.typeLimitId)
            setValue("TypeLimit", findLimit[0])

            if(response.data.dataResult.typeLimitId == 3) {
                setIsUnlimited(false)
            } else {
                setIsUnlimited(true)
            }

            setIsLoading(false)
        })
          .catch(function (error) {
            console.log(error);
        });
    }

    const getTypeLimit = () => {
        api.get("/api/enum-type-limits/all", {
            headers:{
              "content-type" : "application/json",
              "id":params.id
            },
            params:{
              id: params.id,
            },
            withCrenditials:true
        })
        .then(function (response) {
            console.log("enum type limit")
            console.log(response)

            let limit = response.data.listDatas.filter((item) => item.id == 3 || item.id == 4).map((i) => ({
                ...i,
                label:i.id == 3 ? translated.Absolute : translated.Unlimited,
                value:i.id
            }))
            setListTypeLimit(limit)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleUpdateDataLimit = () => {
        setLoadingDataButton(true)
        api.get("/api/company/details", {
            headers:{
              "content-type" : "application/json",
              "id":params.id
            },
            params:{
              id: params.id,
            },
            withCrenditials:true
        })
          .then(function (response) {  
            setLimit(response.data.dataResult.limit)
            setBalance(response.data.dataResult.balance) 
            setMoney(response.data.dataResult.account)
            displayToast("Данные по лимиту и счету успешно обновлены", true)  
            setLoadingDataButton(false) 
        })
          .catch(function (error) {
            console.log(error);
        });
    }

    const dateCreate = (rowData) => {
        return moment(rowData.createdAt).format("DD.MM.YYYY")
    }
    
    useEffect(() => {
        methods.setLocale(CurrentSelectLanguage);
        handleGetHistory()
        getCompany()
        setCurrentTime(moment().format("DD.MM.YYYY hh:mm"))
    }, [])

    const displayToast = (message, status) => {

        if(status) {
            toast.success(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });    
        } else {
            toast.error(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        }
    }

    const updateFuelWallet = () => {
        api.post('/api/company/details/limits/update-type-wallet', {
            Id: params.id,
            IsFuelWallet: checkedIsFuelWallet,
        })
        .then(function (response) {
            getCompany()
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingDataButton(false)
        });
    }

    const columns = useMemo(
        () => [
          {
            accessorFn: (row) => row.countLitre,
            id: 'countLitre',
            header: translated.Liters,
          },
          {
            accessorFn: (row) => row.user.userName,
            accessorKey: 'user', //normal accessorKey
            header: translated.User,
          },
          {
            accessorFn: (row) => moment(row.createdAt).format("DD.MM.YYYY"),
            accessorKey: 'date', //normal accessorKey
            header: translated.DateRecord,
          },
          {
            accessorFn: (row) => row.comment,
            accessorKey: 'comment', //normal accessorKey
            header: translated.Comment,
          },
        ],
    [],
    );

    return (
        <>
        {
            isLoading
            ? <Spinner animation="border" variant="info" />
            : <>
            <div className="row mt-4">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <div className="col-xs-12 col-lg-3 col-md-3">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">
                                {translated.LimitPlan}
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="plan">
                            {translated.PlannedСonsumption}
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="history_limit">
                                {translated.HistoryChanges}
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="second">
                                {translated.Account}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>

                <div className="col-xs-12 col-lg-9 col-md-9">
                    <Tab.Content>

                    <Tab.Pane eventKey="first">
                    <div className="row">

                    <div className="col-xs-12 col-lg-12 col-md-12">
                        <Form.Check
                            type="checkbox"
                            id="checkbox-read-users-limits"
                            label={translated.UseMoneyWallet}
                            className="text-black"
                            checked={checkedIsFuelWallet}
                            onChange={(e) => {
                                updateFuelWallet()
                                setCheckedIsFuelWallet(e.target.checked)
                            }}
                        />
                    </div>

                    {
                        checkedIsFuelWallet
                        ? 
                        <div className="col-xs-12 col-lg-12 col-md-12">
                            <Alert variant='danger' className="mt-3">
                                {translated.MessageRecalcLimit}
                            </Alert>
                        </div>
                        : null
                    }

                        <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                            <button type="button" onClick={() => handleUpdateDataLimit()} disabled={loadingDataBtn} className="btn btn-primary">
                                {loadingDataBtn && (
                                    <Spinner animation="border" variant="light" 
                                        as="span"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loadingDataBtn && <span>{translated.RefreshLimitAndAccount}</span>}
                                {!loadingDataBtn && <span>{translated.RefreshLimitAndAccount}</span>}
                            </button>
                        </div>

                        {
                            !checkedIsFuelWallet
                            ?
                            <div className="col-xs-12 col-md-6 col-lg-6 mt-2">
                                <Card border="light" className="border shadow-none">
                                <Card.Body>
                                <div className="d-flex">
                                <h5 className="benzamat-font-bold flex-fill text-black">{translated.Limit}</h5>
                                
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                    <Tooltip style={{fontSize:14+"px"}}>
                                        {translated.EditLimit}
                                    </Tooltip>
                                    }
                                >
                                <Button onClick={handleShow} className="btn btn-light justify-content-end btn-sm">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.45986 16.8349L3.16455 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </Button> 
                                </OverlayTrigger>

                                </div>
                                {
                                    !isUnlimited
                                    ? <span className="benzamat-font-bold text-black d-block mb-3 mt-2" style={{fontSize:18+"px"}}>{translated.Balance}: {balance}</span>
                                    : <Badge bg="danger" className="font-size-16">{translated.Unlimited}</Badge>
                                }
                                <hr/>
                                <label className="text-black">{translated.BalanceOn}</label>
                                    <input type="text" readonly value={currentTime} className="form-control" />
                                </Card.Body>
                                </Card>
                            </div>
                            : null
                        }

                        <div className="col-xs-12 col-md-6 col-lg-6 mt-2">
                            <Card border="light" className="border shadow-none">
                            <Card.Body>
                            <div className="d-flex">
                            <h5 className="benzamat-font-bold flex-fill text-black">{translated.Account}</h5>

                            </div>
                            <span className="benzamat-font-bold text-black d-block mb-3 mt-2" style={{fontSize:18+"px"}}>{translated.Balance}: {money}</span>
                            <hr/>
                            <label className="text-black">{translated.BalanceOn}</label>
                                <input type="text" readonly value={currentTime} className="form-control" />
                            </Card.Body>
                            </Card>
                        </div>
                    </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="history_limit">
                        <div className="col-xs-12 col-md-12 col-lg-12">

                            <Button className="mb-3" bg="primary" onClick={(() => handleGetHistory())}>
                                {translated.UpdateData}
                            </Button>
                        
                            <MaterialReactTable 
                                columns={columns} 
                                data={historyLimit}
                                localization={MRT_Localization_EN}
                                initialState={{ showColumnFilters: true }}
                                enableTopToolbar={false}
                                enableRowVirtualization
                                muiTablePaperProps={{
                                elevation: 1,
                                }}
                                muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                    fontWeight: 'bold',
                                    color:"#000",
                                    backgroundColor:'#F3F3F3',
                                    fontFamily: 'BenzamatRegular',
                                },
                                }}
                                muiTableBodyCellProps={{
                                sx: {
                                    color:"#000",
                                    fontFamily: 'BenzamatRegular',
                                },
                            }}
                        />

                        </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="plan">
                        <FillingPlan />
                    </Tab.Pane >

                    <Tab.Pane eventKey="second">
                        <Account />
                    </Tab.Pane >

                    </Tab.Content>
                </div>

                </Tab.Container>
            </div>

            <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                    {translated.TitleEditLimit}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <div className="dropdown-divider"></div>
                <Offcanvas.Body> 
                    <Form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Group>                    
                        <Form.Label className="text-black mt-2">{translated.TypeLimit}</Form.Label>
                            <Controller
                                name="TypeLimit"                                
                                control={control}
                                render={({ field: {value} }) => (
                                <Select                          
                                    isSearchable
                                    value={value}
                                    options={listTypeLimit}                        
                                    placeholder={translated.SelectValueFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        setValue("TypeLimit", item)
                                        
                                        if(item.value === 4) {
                                            setIsUnlimited(true)
                                        } else {
                                            setIsUnlimited(false)
                                        }
                                    }}
                                />
                                )}
                            />
                        </Form.Group>

                        {
                            !isUnlimited
                            ?
                            <Form.Group>                    
                            <Form.Label className="text-black mt-3">{translated.Liters}</Form.Label>
                                <Controller
                                    name="Liters"                                
                                    control={control}
                                    render={({ field }) => (
                                    <CurrencyInput
                                        placeholder={translated.EnterLiters}
                                        defaultValue={0}
                                        decimalsLimit={2}
                                        suffix=" л."
                                        className="form-control"
                                        onValueChange={(value) => setLiters(value)}
                                    />
                                    )}
                                />
                            </Form.Group>
                            : null
                        }

                        <div className="form-group mt-3">
                        <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                            {loadingDataBtn && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loadingDataBtn && <span>{translated.SaveChanges}</span>}
                            {!loadingDataBtn && <span>{translated.SaveChanges}</span>}
                            </button>
                        </div>

                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            </>
        }
    </>
    )
}
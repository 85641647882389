import React, { useContext, useState, useEffect, useMemo, Redirect } from 'react';
import { NavLink, Link,  useHistory, useParams } from 'react-router-dom';
import { Button, Offcanvas, Badge, Alert, Form, Spinner } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { CompanyFormAdd } from './CompanyFormAdd';
import { useForm, Controller } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import './../../boxicons.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';        
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { TranslateClients } from '../../lang/company/translateClients';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import { TranslateCompanyModule } from '../../lang/company';
import { FormLocale } from '../../lang/form';
import api from '../../utils/axiosInterceptions';
 
export const CompanyService = ({companyData}) => {

    const router = useHistory();
    const params = useParams()
    
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false);
    const [listCompanies, setListCompanies] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [listTimezone, setListTimezone] = useState([])
    const translated = useLittera(TranslateClients)
    const translatedCompany = useLittera(TranslateCompanyModule)
    const translatedForm = useLittera(FormLocale)
    const methods = useLitteraMethods();
    const [permission, setPermission] = useState({
        read:false,
        create:false,
        update:false,
        delete:false,
        fullAccess:false,
    })
    const [loadingData, setLoadingData] = useState({
        button:false,
    });    

    const handleShow = () => setShowFormAdd(true);
    const handleClose = () => setShowFormAdd(false);
    const getTimezones = () => {
        api.get('/api/timezones/all', {
            withCrenditials:true
        })
        .then(function(response){
          console.log(response)
    
        const add = response.data.listDatas.map(item => ({
            ...item,
            value: item.id,
            label: item.name + ' ' + item.utcOffset
        }))
          setListTimezone(add)
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const getServiceCompanies = () => {
        api.get("/api/company/details/service-companies",{
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
          })
          .then(function (response) {
            console.log("service companies")
            console.log(response)
            setPermission({
                read: response.data.read,
                create: response.data.create,
                delete: response.data.delete,
                fullAccess: response.data.fullAccess
            })
            const addCompany = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
              }))
            setListCompanies(addCompany)
            setIsLoading(false)
          })
          .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        methods.setLocale(CurrentSelectLanguage);
        setIsLoading(true)
        getTimezones()
        getServiceCompanies()
    }, [])

    const onSubmit = data => {
        console.log(data)
        setLoadingData({button:true})
        api.post('/api/company/create', {
            Name: data.Name,
            INN: data.INN,
            KPP: data.KPP,
            OGRN: data.OGRN,
            Address: data.Address,
            MailAddress: data.MailAddress,
            IsParent: false,
            Timezone: data.Timezone,
            CompanyServiceId: companyData.id
        })
        .then(function (response) {
            console.log(response)
            setLoadingData({button:false})
            displayToast(response.data.message, response.data.isSuccess);
        
            reset({
                Name:'',
                INN:'',
                OGRN:'',
                KPP:'',
                Address:'',
                MailAddress:'',
            })

            getServiceCompanies()
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    

    const displayToast = (message, status) => {

        if(status){
          toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
      
        } else {
          toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
        }
    }

    const columns = useMemo(
        () => [
          {
            accessorKey: 'name', //access nested data with dot notation
            header: translated.Name,
          },
          {
            accessorFn: (row) => moment(row.dateCreate).format("DD.MM.YYYY"),
            id: 'dateCreate',
            header: translated.CreatedDate,
          },
          {
            accessorFn: (row) => row.isBlocked != null 
              ? <Badge bg="success" className="font-size-13">{translated.Active}</Badge> 
              : <Badge bg="danger" className="font-size-13">{translated.Blocked}</Badge>,
            accessorKey: 'isBlocked', //normal accessorKey
            header: translated.Blocked,
          },
          {
            accessorFn: (row) => row.isParent != null
              ? <Badge bg="danger" className="font-size-13">{translated.NotServed}</Badge> 
              : <Badge bg="info" className="font-size-13">{translated.Served}</Badge>,
            accessorKey: 'isParent',
            header: translated.Served,
          },
          {
            accessorFn: (row) => 
            <>
              <Button onClick={() => router.push(`/company/${row.id}`)} className="btn btn-light btn-sm">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </Button> 
          
              <Button  className="btn btn-danger btn-sm" style={{marginLeft:'20px'}}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.875 4.375L3.125 4.37504" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.125 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.875 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M15.625 4.375V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M13.125 4.375V3.125C13.125 2.79348 12.9933 2.47554 12.7589 2.24112C12.5245 2.0067 12.2065 1.875 11.875 1.875H8.125C7.79348 1.875 7.47554 2.0067 7.24112 2.24112C7.0067 2.47554 6.875 2.79348 6.875 3.125V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </Button>
            </>,
            accessorKey: 'button',
            header: translated.Actions,
          },
        ],
        [],
      );

    return (
        <>

        {
            isLoading
            ? <Spinner animation="border" variant="info" />
            : <>
                {
                permission.create || permission.fullAccess || permission.read
                ?
                <Button onClick={() => handleShow()} bg="primary" className="mt-3">
                    <i className="bx bx-plus font-size-16 align-middle me-2 "></i> 
                    {translatedCompany.AddCompany}
                </Button>
                : ''
            }

            <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                <Alert variant='info'>
                    {translated.Message(companyData.name)}
                </Alert>
            </div>
        
            <div className="mt-4">
              {
                permission.read || permission.create || permission.fullAccess
                ?
                <MaterialReactTable 
                    columns={columns} 
                    data={listCompanies}
                    localization={MRT_Localization_RU}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                      elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                      },
                }}
                />
                : 
                <Alert variant='danger'>
                    {translated.PermissionDenied}    
                </Alert>
              }
            </div>

            <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                    {translatedCompany.CreateCompany}
                </Offcanvas.Title>
                </Offcanvas.Header>
                <div className="dropdown-divider"></div>
                <Offcanvas.Body>  

                    <h4 className="text-black">{translatedCompany.LegalInfo}</h4>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                    
                    <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translatedCompany.Name} <sup className="text-danger">*</sup></Form.Label>
                    <Form.Control 
                        {...register("Name", {
                        maxLength : {
                            value: 500,
                            message: translatedForm.MaxLenghtString(500)
                        },
                        minLength : {
                            value: 5,
                            message: translatedForm.MinLengthString(5)
                        },
                        required : translatedForm.Required
                        })}
                        type="text" maxLength="500" isInvalid={errors.Name}  placeholder={translatedCompany.EnterName} className="form-control"  />
                        {errors.Name && 
                        <span className="text-danger font-size-13">{errors.Name.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translatedCompany.TIN} <sup className="text-danger">*</sup></Form.Label>
                    <Form.Control 
                        {...register("INN", {
                        maxLength : {
                            value: 12,
                            message: translatedForm.MaxNumber(12)
                        },
                        minLength : {
                            value: 10,
                            message: translatedForm.MinNumber(10)
                        },
                        required : translatedForm.Required
                        })}
                        type="number" maxLength="12" minLength="10" isInvalid={errors.INN}  
                        placeholder={translatedCompany.EnterINN} className="form-control"  />
                        {errors.INN && 
                        <span className="text-danger font-size-13">{errors.INN.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translatedCompany.OGRN}  <sup className="text-danger">*</sup></Form.Label>
                    <Form.Control 
                        {...register("OGRN", {
                        maxLength : {
                            value: 15,
                            message: translatedForm.MaxNumber(15)
                        },
                        minLength : {
                            value: 13,
                            message: translatedForm.MinNumber(13)
                        },
                        required : translatedForm.Required
                        })}
                        type="number" maxLength="15" minLength="13" isInvalid={errors.OGRN} placeholder={translatedCompany.EnterOGRN}  className="form-control"  />
                        {errors.OGRN && 
                        <span className="text-danger font-size-13">{errors.OGRN.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translatedCompany.KPP} </Form.Label>
                    <Form.Control 
                        {...register("KPP", {
                        minLength : {
                            value: 9,
                            message: translatedForm.MaxLenghtString(9)
                        },
                        maxLength : {
                            value: 9,
                            message: translatedForm.MinLengthString(9)
                        },
                        })}
                        type="text" maxLength="9" isInvalid={errors.KPP}  placeholder={translatedCompany.EnterKPP}  className="form-control"  />
                        {errors.KPP && 
                        <span className="text-danger font-size-13">{errors.KPP.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translatedCompany.LegalAddress} <sup className="text-danger">*</sup></Form.Label>
                    <Form.Control 
                        {...register("Address", {
                        maxLength : {
                            value: 800,
                            message: translatedForm.MaxLenghtString(800)
                        },
                        minLength : {
                            value: 5,
                            message: translatedForm.MinLengthString(5)
                        },
                        required : translatedForm.Required
                        })}
                        type="text" maxLength="800" isInvalid={errors.Address}  
                        placeholder={translatedCompany.EnterLegalAddress} className="form-control"  />
                        {errors.Address && 
                        <span className="text-danger font-size-13">{errors.Address.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translatedCompany.MailAddress} <sup className="text-danger">*</sup></Form.Label>
                    <Form.Control 
                        {...register("MailAddress", {
                        maxLength : {
                            value: 800,
                            message: translatedForm.MaxLenghtString(800)
                        },
                        minLength : {
                            value: 5,
                            message: translatedForm.MinLengthString(5)
                        },
                        required : translatedForm.Required
                        })}
                        type="text" maxLength="800" isInvalid={errors.MailAddress}  
                        placeholder={translatedCompany.EnterMailAddress} className="form-control"  />
                        {errors.MailAddress && 
                        <span className="text-danger font-size-13">{errors.MailAddress.message}</span>
                        }
                    </Form.Group>
                    
                    <div className="form-group mt-3">
                        <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                            {loadingData.button && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loadingData.button && <span>{translatedCompany.AddCompanyProgress}</span>}
                            {!loadingData.button && <span>{translatedCompany.AddCompany}</span>}
                        </button>
                    </div>
                
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
            </>
        }
        </>
    )
}
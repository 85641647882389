import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Alert, Tab, Form, Offcanvas, Table, Spinner, Card, Dropdown, Modal, DropdownButton, Tooltip, OverlayTrigger, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment/moment';
import './../../boxicons.css';
import { LevelMeterSingle } from './LevelMeter/single';
import { LevelMeterPeriod } from './LevelMeter/LevelMeterPeriod';
import api from '../../utils/axiosInterceptions';

export const LevelMeter = ({controllerInfo, translated, translatedForm, periodLevelMeterRead}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [listLevelMeters, setListLevelMeters] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalLm, setModalLm] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [levelMeterIsLoading, setLevelMeterIsLoading] = useState(false)
    const [listLevelMeterData, setListLevelMeterData] = useState([])
    const [openPeriod, setOpenPeriod] = useState(false)
    const [levelMeterFail, setLevelMeterFail] = useState({
        status:false,
        message:''
    })
    const [sumLevelMeters, setSumLevelMeters] = useState({
        mass:0,
        all_volume:0,
        fuel_volume:0
    })
    const [permission, setPermission] = useState({
        read: false,
        update: false,
        fullAccess: false
    })

    const handleGetCritical = () => {
        setShowModal(true)
        api.get('/api/controllers/details/critical-levels/details', {
            headers:{
                "content-type" : "application/json",
                "ControllerId":controllerInfo.id
            },
            params:{
                ControllerId: controllerInfo.id,
            },
            withCredentials:true
        })
        .then(function (response) {
            console.log("critical level")
            console.log(response)
            setValue("LevelLow", response.data.dataResult.levelLow)
            setValue("TemperatureLow", response.data.dataResult.temperatureLow)
            setValue("AllVolumeLow", response.data.dataResult.allVolumeLow)
            setValue("FillLow", response.data.dataResult.fillLow)
            setValue("MassLow", response.data.dataResult.massLow)
            setValue("DensityLow", response.data.dataResult.densityLow)
            setValue("FuelVolumeLow", response.data.dataResult.fuelVolumeLow)
            setValue("LevelWaterLow", response.data.dataResult.levelWaterLow)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleGetGroupLm = () => {
        setModalLm(true)
        groupGetLevelMeter()
    }

    const handleShowPeriod = () => {
        setOpenPeriod(true)
    }

    const groupGetLevelMeter = () => {
        setLevelMeterIsLoading(true)
        api.get('/api/controllers/details/level-meters/group-get-level-meter', {
            headers:{
                "content-type" : "application/json",
                "controllerId":controllerInfo.id
            },
            params:{
                controllerId: controllerInfo.id,
            },
            withCredentials:true
        })
        .then(function (response) {
            console.log("group level meter")
            console.log(response)
            setListLevelMeterData(response.data.listDatas)

            if(controllerInfo.type != 3)
            {
                let sum_all_volume = 0
                let sum_mass = 0;
                let sum_fuel_volume = 0;
    
                response.data.listDatas.filter((i) => i.mass).map((item) => {
                    sum_mass += item.mass.data
                })
    
                response.data.listDatas.filter((i) => i.all_volume).map((item) => {
                    sum_all_volume += item.all_volume.data
                })
    
                response.data.listDatas.filter((i) => i.fuel_volume).map((item) => {
                    sum_fuel_volume += item.fuel_volume.data
                })
    
                setSumLevelMeters({
                    mass:sum_mass,
                    all_volume:sum_all_volume,
                    fuel_volume:sum_fuel_volume
                })
    
            }

            setLevelMeterIsLoading(false)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    const getLevelMeters = () => {
        api.get("/api/controllers/details/level-meters", {
            headers:{
              "content-type" : "application/json",
              "controllerId":params.id
            },
            params:{
              controllerId: params.id,
            },
            withCredentials:true
        })
        .then(function (response) {
            console.log("level meters controller")
            console.log(response)
            setListLevelMeters(response.data.listDatas)
            setPermission({
                read: response.data.read,
                update: response.data.update,
                fullAccess: response.data.fullAccess
            })
            setIsLoading(false)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const onSubmit = data => {
        setLoadingBtn(true)
        api.post('/api/controllers/details/critical-levels/update', {
            LevelLow: data.LevelLow,
            TemperatureLow: data.TemperatureLow,
            AllVolumeLow: data.AllVolumeLow,
            FillLow: data.FillLow,
            MassLow: data.MassLow,
            DensityLow: data.DensityLow,
            FuelVolumeLow: data.FuelVolumeLow,
            LevelWaterLow: data.LevelWaterLow,
            ControllerId: params.id,
            withCredentials:true
        })
        .then(function (response) {
            console.log("critical level")
            console.log(response)
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    useEffect(() => {
        getLevelMeters()
    }, [])

    return(
      isAuth ?
      <>
         <div className="row mt-4">
            {
                permission.update || permission.fullAccess
                ?
                <>
                    <div className="col-xs-12 col-lg-3 col-md-3">
                        <DropdownButton title={translated.SettingOnController} className="mb-3">
                            <Dropdown.Item onClick={() => handleGetCritical()}>{translated.SettingCriticalLevel}</Dropdown.Item>
                            {/*<Dropdown.Item >{translated.ControlBalance}</Dropdown.Item>*/}
                            <Dropdown.Item onClick={() => handleShowPeriod()}>{translated.PeriodSaveLevelMeterData}</Dropdown.Item>
                        </DropdownButton>
                    </div>
                    <div className="col-xs-12 col-lg-3 col-md-3">
                        <button onClick={() => handleGetGroupLm()} type="button" className="btn btn-light">
                            {translated.GetLevelMeterData}
                        </button>
                    </div>
                    <div className="col-xs-12 col-lg-12 col-md-12 mt-2 mb-2">
                        <Alert variant="warning">
                            {translated.MessageSelectTypeLevelMeter}
                        </Alert>
                    </div>
                </>
                : null
            }

        <div className="col-xs-12 col-lg-12 col-md-12">
            <div className="row">
                {
                    listLevelMeters.map((item, index) => (
                        <LevelMeterSingle controllerInfo={controllerInfo} translatedForm={translatedForm} translated={translated} index={index} key={item.id} item={item} />
                    ))
                }
            </div>
        </div>

        </div>

        {
            controllerInfo.type != 3
            ?
            <Modal
                size="xl"
                show={modalLm}
                onHide={() => setModalLm(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-90w"
            >            
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg" className="text-black benzamat-font-bold font-size-22">
                {translated.HeaderLevelMeterData}
            </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    {
                        !levelMeterIsLoading
                        ?
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>{moment(new Date()).format("DD.MM.YYYY HH:mm")}</th>
                                    <th>{translated.LevelMeter_1}</th>
                                    <th>{translated.LevelMeter_2}</th>
                                    <th>{translated.LevelMeter_3}</th>
                                    <th>{translated.LevelMeter_4}</th>
                                    <th>{translated.Sum}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{translated.Level} ({translated.Sm})</th>
                                    {
                                        listLevelMeterData.filter((item) => item.level).map((i, index) => (
                                            <td>{(i.level.data * 100).toFixed(1)}</td>
                                        ))
                                    }
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>{translated.Temperature}</th>
                                    {
                                        listLevelMeterData.filter((item) => item.temperature).map((i, index) => (
                                            <td>{(i.temperature.data).toFixed(1)}</td>
                                        ))
                                    }
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>{translated.Filling} (%)</th>
                                    {
                                        listLevelMeterData.filter((item) => item.fill).map((i, index) => (
                                            <td>{(i.fill.data).toFixed(1)}</td>
                                        ))
                                    }
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>{translated.FullVolume} ({translated.ShortLiter})</th>
                                    {
                                        listLevelMeterData.filter((item) => item.all_volume).map((i, index) => (
                                            <td>{(i.all_volume.data * 1000).toFixed(1)}</td>
                                        ))
                                    }
                                    <td>{(sumLevelMeters.all_volume * 1000).toFixed(1)}</td>
                                </tr>
                                <tr>
                                    <th>{translated.Massa} ({translated.Kg})</th>
                                    {
                                        listLevelMeterData.filter((item) => item.mass).map((i, index) => (
                                            <td>{(i.mass.data * 1000).toFixed(1)}</td>
                                        ))
                                    }
                                    <td>{(sumLevelMeters.mass * 1000).toFixed(1)}</td>
                                </tr>
                                <tr>
                                    <th>{translated.Density} ({translated.KgM3})</th>
                                    {
                                        listLevelMeterData.filter((item) => item.density).map((i, index) => (
                                            <td>{(i.density.data * 1000).toFixed(1)}</td>
                                        ))
                                    }
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>{translated.FuelVolume} ({translated.ShortLiter})</th>
                                    {
                                        listLevelMeterData.filter((item) => item.fuel_volume).map((i, index) => (
                                            <td>{(i.fuel_volume.data * 1000).toFixed(1)}</td>
                                        ))
                                    }
                                    <td>{(sumLevelMeters.fuel_volume * 1000).toFixed(1)}</td>
                                </tr>
                                <tr>
                                    <th>{translated.ProductFluidLevel} ({translated.Sm})</th>
                                    {
                                        listLevelMeterData.filter((item) => item.fuel_volume).map((i, index) => (
                                            <td>{(i.water_level.data * 100).toFixed(1)}</td>
                                        ))
                                    }
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>{translated.StateCriticalLevel}</th>
                                    {
                                        listLevelMeterData.filter((item) => item.critical_level).map((i, index) => (
                                            <td>{(i.critical_level.data)}</td>
                                        ))
                                    }
                                    <td></td>
                                </tr>
                            </tbody>
                        </Table>
                        : 
                        <div className="d-flex justify-content-center">                        
                            <Spinner animation="border" variant="info" />
                        </div>
                    }
                </Modal.Body>
            </Modal>
            : 
            <Modal
                size="xl"
                show={modalLm}
                onHide={() => setModalLm(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                dialogClassName="modal-90w"
            >            
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg" className="text-black benzamat-font-bold font-size-22">
                {translated.HeaderLevelMeterData}
            </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    {
                        !levelMeterIsLoading
                        ?
                        <>
                        <Alert variant="warning">{translated.CodeErrorText}</Alert>
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>{moment(new Date()).format("DD.MM.YYYY HH:mm")}</th>
                                    <th>{translated.LevelMeter_1}</th>
                                    <th>{translated.LevelMeter_2}</th>
                                    <th>{translated.LevelMeter_3}</th>
                                    <th>{translated.LevelMeter_4}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{translated.Level} ({translated.Sm})</th>
                                    {
                                        listLevelMeterData.map((i, index) => (
                                            <td style={{"background": i.codeError == 1 || i.codeError == 2 || i.codeError == 3 
                                                ? "#fde1e1"
                                                : "#fff"}}>
                                                {
                                                    i.codeError != 1 && i.codeError != 2 && i.codeError != 3
                                                    ? (i.level).toFixed(1)
                                                    : "-"
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{translated.Temperature}</th>
                                    {
                                        listLevelMeterData.map((i, index) => (
                                            <td style={{"background": i.codeError == 1 || i.codeError == 2 || i.codeError == 3 
                                                ? "#fde1e1"
                                                : "#fff"}}>
                                                {
                                                    i.codeError != 1 && i.codeError != 2 && i.codeError != 3
                                                    ? (i.temperature).toFixed(1)
                                                    : "-"
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{translated.Filling} (%)</th>
                                    {
                                        listLevelMeterData.map((i, index) => (
                                            <td style={{"background": i.codeError == 1 || i.codeError == 2 || i.codeError == 3 
                                                ? "#fde1e1"
                                                : "#fff"}}>
                                                {
                                                    i.codeError != 1 && i.codeError != 2 && i.codeError != 3
                                                    ? (i.fill).toFixed(1)
                                                    : "-"
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{translated.FullVolume} ({translated.ShortLiter})</th>
                                    {
                                        listLevelMeterData.map((i, index) => (
                                            <td style={{"background": i.codeError == 1 || i.codeError == 2 || i.codeError == 3 
                                                ? "#fde1e1"
                                                : "#fff"}}>
                                                {
                                                    i.codeError != 1 && i.codeError != 2 && i.codeError != 3
                                                    ? (i.allVolume).toFixed(1)
                                                    : "-"
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{translated.Massa} ({translated.Kg})</th>
                                    {
                                        listLevelMeterData.map((i, index) => (
                                            <td style={{"background": i.codeError == 1 || i.codeError == 2 || i.codeError == 3 
                                                ? "#fde1e1"
                                                : "#fff"}}>
                                                {
                                                    i.codeError != 1 && i.codeError != 2 && i.codeError != 3
                                                    ? (i.mass).toFixed(1)
                                                    : "-"
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{translated.Density} ({translated.KgM3})</th>
                                    {
                                        listLevelMeterData.map((i, index) => (
                                            <td style={{"background": i.codeError == 1 || i.codeError == 2 || i.codeError == 3 
                                                ? "#fde1e1"
                                                : "#fff"}}>
                                                {
                                                    i.codeError != 1 && i.codeError != 2 && i.codeError != 3
                                                    ? (i.density).toFixed(1)
                                                    : "-"
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{translated.FuelVolume} ({translated.ShortLiter})</th>
                                    {
                                        listLevelMeterData.map((i, index) => (                                            
                                            <td style={{"background": i.codeError == 1 || i.codeError == 2 || i.codeError == 3 
                                                ? "#fde1e1"
                                                : "#fff"}}>
                                                {
                                                    i.codeError != 1 && i.codeError != 2 && i.codeError != 3
                                                    ? (i.fuelVolume).toFixed(1)
                                                    : "-"
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{translated.ProductFluidLevel} ({translated.Sm})</th>
                                    {
                                        listLevelMeterData.map((i, index) => (
                                            <td style={{"background": i.codeError == 1 || i.codeError == 2 || i.codeError == 3 
                                                ? "#fde1e1"
                                                : "#fff"}}>
                                                {
                                                    i.codeError != 1 && i.codeError != 2 && i.codeError != 3
                                                    ? (i.levelWater).toFixed(1)
                                                    : "-"
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                                <tr>
                                    <th>{translated.CodeError}</th>
                                    {
                                        listLevelMeterData.map((i, index) => (
                                            <td>{i.codeError}</td>
                                        ))
                                    }
                                </tr>
                            </tbody>
                        </Table>
                        </>
                        : 
                        <div className="d-flex justify-content-center">                        
                            <Spinner animation="border" variant="info" />
                        </div>
                    }
                </Modal.Body>
            </Modal>
        }

        <Modal
            size="md"
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="modal-90w"
        >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="text-black benzamat-font-bold font-size-22">
            {translated.SettingCriticalLevel} - {translated.LowLevel}
          </Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-xs-12 col-lg-12 col-md-12">
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Level} </Form.Label>
                                <Form.Control 
                                    {...register("LevelLow", {
                                    minLength : {
                                        value: 1,
                                        message: translatedForm.MinNumber(1)
                                    },
                                    maxLength : {
                                        value: 6000,
                                        message: translatedForm.MaxNumber(6000)
                                    },
                                    })}
                                    type="text" isInvalid={errors.LevelLow}
                                    placeholder={translatedForm.EnterValue(6000)} className="form-control"  />
                                {errors.LevelLow && 
                                    <span className="text-danger font-size-13">{errors.LevelLow.message}</span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Temperature}</Form.Label>
                                <Form.Control 
                                    {...register("TemperatureLow", {
                                    minLength : {
                                        value: -50,
                                        message: translatedForm.MinNumber(-50)
                                    },
                                    maxLength : {
                                        value: 100,
                                        message: translatedForm.MaxNumber(100)
                                    },
                                    })}
                                    type="text" isInvalid={errors.TemperatureLow}
                                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                                {errors.TemperatureLow && 
                                    <span className="text-danger font-size-13">{errors.TemperatureLow.message}</span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Occupancy} </Form.Label>
                                <Form.Control 
                                    {...register("FillLow", {
                                    minLength : {
                                        value: 1,
                                        message: translatedForm.MinNumber(1)
                                    },
                                    maxLength : {
                                        value: 100,
                                        message: translatedForm.MaxNumber(100)
                                    },
                                    })}
                                    type="text" isInvalid={errors.FillLow}  
                                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                                {errors.FillLow && 
                                    <span className="text-danger font-size-13">{errors.FillLow.message}</span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.FullVolume} </Form.Label>
                                <Form.Control 
                                    {...register("AllVolumeLow", {
                                    minLength : {
                                        value: 1,
                                        message: translatedForm.MinNumber(1)
                                    },
                                    maxLength : {
                                        value: 100000,
                                        message: translatedForm.MaxNumber(100000)
                                    },
                                    //required: translatedForm.Required,
                                    })}
                                    type="text" isInvalid={errors.AllVolumeLow}  placeholder="Введите объем" className="form-control"  />
                                {errors.AllVolumeLow && 
                                    <span className="text-danger font-size-13">{errors.AllVolumeLow.message}</span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Massa} </Form.Label>
                                <Form.Control 
                                    {...register("MassLow", {
                                    minLength : {
                                        value: 1,
                                        message: translatedForm.MinNumber(1)
                                    },
                                    maxLength : {
                                        value: 100000,
                                        message: translatedForm.MaxNumber(100000)
                                    },
                                    //required: translatedForm.Required,
                                    })}
                                    type="text" isInvalid={errors.MassLow} 
                                    placeholder={translatedForm.EnterValue(100000)} className="form-control"  />
                                {errors.MassLow && 
                                    <span className="text-danger font-size-13">{errors.MassLow.message}</span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Density}</Form.Label>
                                <Form.Control 
                                    {...register("DensityLow", {
                                    minLength : {
                                        value: 1,
                                        message: translatedForm.MinNumber(1)
                                    },
                                    maxLength : {
                                        value: 100000,
                                        message: translatedForm.MaxNumber(100000)
                                    },
                                    //required: translatedForm.Required,
                                    })}
                                    type="text" isInvalid={errors.DensityLow} 
                                    placeholder={translatedForm.EnterValue(100000)} className="form-control"  />
                                {errors.DensityLow && 
                                    <span className="text-danger font-size-13">{errors.DensityLow.message}</span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.FuelVolume} </Form.Label>
                                <Form.Control 
                                    {...register("FuelVolumeLow", {
                                    minLength : {
                                        value: 1,
                                        message: translatedForm.MinNumber(1)
                                    },
                                    maxLength : {
                                        value: 100000,
                                        message: translatedForm.MaxNumber(100000)
                                    },
                                    //required: translatedForm.Required,
                                    })}
                                    type="text" isInvalid={errors.FuelVolumeLow}  
                                    placeholder={translatedForm.EnterValue(100000)} className="form-control"  />
                                {errors.FuelVolumeLow && 
                                    <span className="text-danger font-size-13">{errors.FuelVolumeLow.message}</span>
                                }
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.ProductFluidLevel} </Form.Label>
                                <Form.Control 
                                    {...register("LevelWaterLow", {
                                    minLength : {
                                        value: 1,
                                        message: translatedForm.MinNumber(1)
                                    },
                                    maxLength : {
                                        value: 100000,
                                        message: translatedForm.MaxNumber(100000)
                                    },
                                    //required: translatedForm.Required,
                                    })}
                                    type="text"  isInvalid={errors.LevelWaterLow} 
                                    placeholder={translatedForm.EnterValue(100000)} className="form-control"  />
                                {errors.LevelWaterLow && 
                                    <span className="text-danger font-size-13">{errors.LevelWaterLow.message}</span>
                                }
                            </Form.Group>
                            <div className="form-group mt-3">
                                <button type="submit" disabled={loadingBtn} className="btn btn-success">
                                    {loadingBtn && (
                                        <Spinner animation="border" variant="light" 
                                            as="span"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                                    {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>

        {
            openPeriod
            ? <LevelMeterPeriod 
                controllerInfo={controllerInfo} 
                periodLevelMeterRead={periodLevelMeterRead} 
                openPeriod={openPeriod} 
                setOpenPeriod={setOpenPeriod}
                translated={translated}
                translatedForm={translatedForm}
            />
            : null
        }
      </>
    :
    <Redirect to='/login'/>
    );
}
import React, { useContext, useEffect, useState, useMemo, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Card, ProgressBar, Form, Badge, Offcanvas, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { FormAddTransport } from '../Transport/FormAddTransport';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Select from 'react-select';
import axios from 'axios';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import './../../boxicons.css';
import StatusConnect01 from "../../img/image_connect/StatusConnect-01.png";
import StatusConnect02 from "../../img/image_connect/StatusConnect-02.png";
import StatusConnect03 from "../../img/image_connect/StatusConnect-03.png";
import StatusConnect04 from "../../img/image_connect/StatusConnect-04.png";
import StatusConnect05 from "../../img/image_connect/StatusConnect-05.png";
import StatusConnect06 from "../../img/image_connect/StatusConnect-06.png";
import StatusConnect07 from "../../img/image_connect/StatusConnect-07.png";
import StatusConnect08 from "../../img/image_connect/StatusConnect-08.png";
import StatusConnect09 from "../../img/image_connect/StatusConnect_09.png";
import StatusConnect009 from "../../img/image_connect/StatusConnect-09.png";
import StatusConnect10 from "../../img/image_connect/StatusConnect-10.png";
import { TranslateController } from '../../lang/controllers/translateController';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const UserControllers = ({stationData}) => {

  const params = useParams()
  const router = useHistory()
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listController, setListController] = useState([])
  const [loadingDataBtn, setLoadingDataButton] = useState(false)
  const translated = useLittera(TranslateController);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();

  const gridStyle = { minHeight: 550 }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    api.get("/api/users/details/controllers", {
        headers:{
          "content-type" : "application/json",
          "id":params.id
        },
        params:{
          id: params.id,
        },
      })
      .then(function (response) {
        console.log(response)
        setListController(response.data.listDatas)
      })
    
  }, [])


  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: translated.Name,
      },
      {
        accessorFn: (row) => row.connectId,
        id: 'connectId',
        size:300,
        header: translated.ID,
      },
      {
        accessorFn: (row) => row.firstConfigRead 
          ? <Badge bg="success" className="font-size-13">{translated.Readed}</Badge> 
          : <Badge bg="danger" className="font-size-13">{translated.NotReaded}</Badge>,
        accessorKey: 'firstConfigRead', //normal accessorKey
        header: translated.Settings,
      },
      {
        accessorFn: (row) => row.doScanning == 1 
          ? <span className="badge bg-success font-size-12">{translated.Enable}</span> 
          : <span className="badge bg-danger font-size-12">{translated.Disable}</span>,
        accessorKey: 'doScanning',
        header: translated.AutoRead,
      },
      {
        accessorFn: (row) => row.versionSoftware != null && row.versionSoftware != "" ? row.versionSoftware : translated.Undefined,
        accessorKey: 'versionSoftware',
        header: translated.VersionSoftware,
      },
      {
        accessorFn: (row) => row.stationName,
        accessorKey: 'stationName',
        header: translated.Station,
      },
      {
        accessorFn: (row) => {

          if(row.statusConnect == -1 || row.statusConnect == -2 || row.statusConnect == -99 || row.statusConnect == -97 
          || row.statusConnect == -96 || row.statusConnect == -95 || row.statusConnect == -93)
          {
            return <img className="d-flex justify-content-center" src={StatusConnect06} />
          }

          if(row.statusConnect == -98) {
            return <img src={StatusConnect07} />
          }

          if(row.statusConnect == -94) {
            return <img src={StatusConnect10} />
          }

          if(row.statusConnect >= 0 && row.statusConnect <= 4) {
            return <img src={StatusConnect05} />
          }

          if(row.statusConnect > 4 && row.statusConnect <= 9) {
            return <img src={StatusConnect04} />
          }

          if(row.statusConnect > 9 && row.statusConnect <= 16) {
            return <img src={StatusConnect03} />
          }

          if(row.statusConnect > 16 && row.statusConnect <= 21) {
            return <img src={StatusConnect02} />
          }

          if(row.statusConnect > 21 && row.statusConnect <= 31) {
            return <img className="d-flex justify-content-center" src={StatusConnect01} />
          }

          if(row.statusConnect == 99) {
            return <img src={StatusConnect06} />
          }

          if(row.statusConnect == 128) {
            return <img src={StatusConnect09} />
          }
        },
        accessorKey: 'statusConnect',
        header: "GSM",
      }
    ],
    [],
  );
  return(
    <>
      <div className="mt-3">
        <MaterialReactTable 
            columns={columns} 
            data={listController}
            localization={MRT_Localization_RU}
            initialState={{ showColumnFilters: true }}
            enableRowVirtualization
            enableTopToolbar={false}
            muiTablePaperProps={{
              elevation: 1,
            }}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: 'bold',
                color:"#000",
                backgroundColor:'#F3F3F3',
                fontFamily: 'BenzamatRegular',
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                color:"#000",
                fontFamily: 'BenzamatRegular',
              },
          }}
        />
      </div>
    </>
  );
}
import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Card, Spinner, Nav, Table, InputGroup} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../../../boxicons.css';
import api from '../../../../utils/axiosInterceptions';

export const Tokheim = ({translated, translatedForm, listSmartData, controllerInfo}) => {

    let arr = []
    const router = useHistory()
    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showSecondSleeva, setShowSecondSleeve] = useState(false)
    const [fuels, setFuels] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [arrayTrTable, setArrayTrTable] = useState([])
    const [buttonTrk, setButtonTrk] = useState(false)
    const [gerkon, setGerkon] = useState(false)
    const [valueCountSide, setValueCountSide] = useState(1)

    const CountStorona = [
        {label:"1", value:1},
        {label:"2", value:2},
    ]

    const listSubtypes = [
        {label:"Q330", value:2},
        {label:"Q130", value:1},
    ]

    const listCountFuels = [
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
    ]

    const addresses = [
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
        {label:"5", value:5},
        {label:"6", value:6},
        {label:"7", value:7},
        {label:"8", value:8},
        {label:"9", value:9},
        {label:"10", value:10},
        {label:"11", value:11},
        {label:"12", value:12},
        {label:"13", value:13},
        {label:"14", value:14},
        {label:"15", value:15},
        {label:"16", value:16},
        {label:"17", value:17},
        {label:"18", value:18},
        {label:"19", value:19},
        {label:"20", value:20},
    ]

    const getFuels = () => {
        api.get(`/api/fuels/all`, {
            withCredentials:true
        })
        .then(function (response) { 
            console.log(response)
            const add = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setFuels(add)

            let subtype  = listSubtypes.filter((item) => item.value == listSmartData[0].subType)
            console.log(listSmartData[0].subType)
            let address = addresses.filter((item) => item.value == listSmartData[0].address)
            let address2 = addresses.filter((item) => item.value == listSmartData[0].address2)
            let fuetype1 =  listSmartData[0].fuelType != 0 
                ? response.data.listDatas.filter((item) => item.id == listSmartData[0].fuelType)
                : response.data.listDatas.filter((item) => item.id == 1)
            let fuetype2 =  response.data.listDatas.filter((item) => item.id == listSmartData[0].fuelType2)
    
            setValue("Subtype", subtype[0])
            setValue("Address", address[0])
            setValue("Address2", address2[0])

            setValue("FuelType", {label:fuetype1[0].name, value:fuetype1[0].id})
            setValue("FuelType2", {label:fuetype2[0].name, value:fuetype2[0].id})

        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getFuels()
    }, [])

    const deleteFuel = () => {
        console.log("delete")
    }

    const onSubmit = data => {
        setLoadingBtn(true)
        console.log(data)
        api.post('/api/controllers/details/tokheim/update', {
            ControllerId: params.id,
            SubType: data.Subtype.value,
            Address: data.Address.value,
            Address2: data.Address2.value,
            FuelType: data.FuelType != undefined ? data.FuelType.id : 1,
            FuelType2: data.FuelType2 != undefined ? data.FuelType2.value : 1,
            CountDispenser: 1,
            withCredentials:true
        })
        .then(function (response) {
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    return(      
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <h4 className="text-black">Tokheim India</h4>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.ModelDispenser} </Form.Label>
                            <Controller
                                name="Subtype"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field:{value} }) => (
                                <Select
                                    value={value}
                                    options={listSubtypes}
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        console.log(item)
                                        setValue("Subtype", item)
                                    }}
                                />
                                )}
                            />
                            {errors.Subtype1 && 
                                <span className="text-danger font-size-13">{errors.Subtype1.message}</span>
                            }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-6 col-md-6">
                    <Card border="light" className="border shadow-none">
                        <Card.Body>
                        <h3 className="text-black benzamat-font-bold">{translated.Sleeve1}</h3>
                        <div>
                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                <Controller
                                    name="Address"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field: {value} }) => (
                                    <Select                      
                                        isSearchable
                                        options={addresses}
                                        value={value}               
                                        placeholder={translatedForm.EnterSingleValue}
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("Address", item)}
                                    />
                                    )}
                                />
                                {errors.Address && 
                                    <span className="text-danger font-size-13">{errors.Address.message}</span>
                                } 
                            </Form.Group>

                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                <Controller
                                    name="FuelType"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field: {value} }) => (
                                    <Select                      
                                        isSearchable
                                        options={fuels}
                                        value={value}               
                                        placeholder={translatedForm.EnterSingleValue}
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("FuelType", item)}
                                    />
                                    )}
                                />
                                {errors.FuelType && 
                                    <span className="text-danger font-size-13">{errors.FuelType.message}</span>
                                } 
                            </Form.Group>
                        </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="col-xs-12 col-lg-6 col-md-6">
                    <Card border="light" className="border shadow-none">
                        <Card.Body>
                        <h3 className="text-black benzamat-font-bold">{translated.Sleeve2}</h3>
                        <div>
                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                <Controller
                                    name="Address2"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field: {value} }) => (
                                    <Select                      
                                        isSearchable
                                        isDisabled={showSecondSleeva}
                                        options={addresses}
                                        value={value}               
                                        placeholder={translatedForm.EnterSingleValue}
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("Address2", item)}
                                    />
                                    )}
                                />
                                {errors.Address2 && 
                                    <span className="text-danger font-size-13">{errors.Address2.message}</span>
                                } 
                            </Form.Group>

                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                <Controller
                                    name="FuelType2"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field: {value} }) => (
                                    <Select                      
                                        isSearchable
                                        isDisabled={showSecondSleeva}
                                        options={fuels}
                                        value={value}               
                                        placeholder={translatedForm.EnterSingleValue}
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("Address2", item)}
                                    />
                                    )}
                                />
                                {errors.FuelType2 && 
                                    <span className="text-danger font-size-13">{errors.FuelType2.message}</span>
                                } 
                            </Form.Group>
                        </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className="form-group ">
                    <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                        {loadingBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                    </button>
                </div>
            </div>
        </Form>
    );
}
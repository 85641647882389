import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Card, Spinner, Alert, Table, ProgressBar} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import Chart from "react-apexcharts";
import moment from 'moment/moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru'; 
import './../../boxicons.css';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateReport } from '../../lang/reports/translateReport';
import {TranslatePouring} from '../../lang/pourings/translatePouring';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import { Chart as ChartJS } from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';
import api from '../../utils/axiosInterceptions';

export const ReportGsmConnect = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listStation, setListStation] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [listControllers, setListControllers] = useState([])
  const [showDiagramm, setShowDiagramm] = useState(false)
  const translated = useLittera(TranslateReport);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [diagrammColumn, setDiagrammColumn] = useState({
    series:[],
    test:{}
  })
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:0
  })
  const [selectedStation, setSelectedStation] = useState({
    label:'',
    value:0
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });
  
  const displayToast = (message, status, typeMessage) => {
    
    if(typeMessage == "success") {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    } else {
        toast.info(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    }
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")
        console.log(response);

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))

        setListCompanies(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getStations = companyId => {
    api.get('/api/company/details/controllers', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("stations of company")
        console.log(response);

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListControllers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getControllers = stationId => {
    api.get('/api/stations/details/controllers', {
        headers:{
            "content-type" : "application/json",
            "Id":stationId
        },
        params:{
            Id: stationId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("controllers of station")
        console.log(response);

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListControllers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = data => {
    console.log(data)
    console.log(data.EndPeriod.toISOString())
    setLoadingData({button:true})
    api.post('/api/reports/gsm-connects', {
        ControllerId: data.ControllerId.id,
        EndPeriod: data.EndPeriod.toISOString(),
        StartPeriod: data.StartPeriod.toISOString(),
        withCredentials:true
    })
    .then(function (response) {
        console.log("messages")
        console.log(response);
        setLoadingData({button:false})
        setIsLoading(true)
        setPermission({
            read: response.data.read,
            fullAccess: response.data.fullAccess
        })

        if(response.data.read || response.data.fullAccess)
        {
            console.log("connect series diagramm")
            console.log(response.data)
            setData(response.data.listDatas)
            displayToast(response.data.message, response.data.isSuccess, response.data.typeMessage)
    
            let array_series_data = []
            let localLabels = []
            response.data.listDatas.filter((i) => i.csq >= 6 && i.csq <= 31).map((item) => {
                array_series_data.push(item.csq)
                localLabels.push(moment(item.date).format("DD.MM.YYYY HH:mm"))
            })

            setDiagrammColumn({
                test: {
                    labels: localLabels,
                    datasets: [
                        {
                            label: 'CSQ',
                            borderColor: 'rgb(53, 162, 235)',
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            data: array_series_data,
                            tension: 0.1,
                            fill: false
                        },
                    ],
                }
            })
        }
    })
    .catch(function (error) {
        console.log(error);
        setIsLoading(false)
        setLoadingData({button:false})
    });
  }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getCompanies()
    setValue("StartPeriod", new Date())
    setValue("EndPeriod", new Date())
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => moment(row.date).format("DD.MM.YYYY HH:mm"),
        accessorKey: 'date', 
        header: translated.Time,
      },
      {
        accessorFn: (row) => row.csq,
        id: 'csq',
        header: 'CSQ',
      },
      {
        accessorFn: (row) => row.errorConnect,
        accessorKey: 'error', //normal accessorKey
        header: translated.Error,
      },
    ],
    [],
  );

  const options = {
    chart: {
        height: 250,
        type: 'area',
        zoom: {
            enabled: true
        },
        animations: {
            enabled: false
          }
    },
    markers: {
        size: 0
    },
    toolbar: {
        autoSelected: 'zoom'
    },
    dataLabels: {
        enabled: false
    },
    colors:['#0B61A4', '#F44336'],
    stroke: {
        curve: 'straight'
    },
    title: {
        text: translated.ReportGsmConnect,
        align: 'center'
    },
    grid: {
        row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
        },
    },

  }

  const handleShowDiagramm = (status) => {
    if(!showDiagramm) {
        setShowDiagramm(status)
    }
  }
  
  const optionsTest = {
    responsive: true,
    animation:false,
    plugins: {
      legend: {
        position: 'top',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        }
      }
    },
  };

  return (
    isAuth ?
    <>

    <div className="d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
            {translated.Analytics} - <span className="text-secondary benzamat-font-bold font-size-18">{translated.ReportGsmConnect}</span>
        </h2>
        <div className="page-title-right">
            <Button varian="primary" onClick={() => setShowFormAdd(true)}>
                {translated.FilterReport}
            </Button>
        </div>           
    </div>
    <div className="row mt-4">

        {
            isLoading
            ?
            permission.read || permission.fullAccess
            ? 
            <>
            
            <div className="col-xs-12 col-lg-4 col-md-4">
                <button type="button" onClick={() => handleShowDiagramm(true)} className="btn mt-2 btn-success">
                    Отобразить график
                </button>
            </div>

            {
                showDiagramm
                ?
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Line options={optionsTest} data={diagrammColumn.test} />
                </div>
                : null
            }

            <div className="col-xs-12 col-lg-12 col-md-12 mt-2">         
                <div className="mt-2">
                <MaterialReactTable 
                    columns={columns} 
                    data={data}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableRowVirtualization
                    enableTopToolbar={false}
                    muiTablePaperProps={{
                        elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                        },
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                        },
                    }}
                    />
                </div>
            </div>
            </>
            : 
            <div className="col-xs-12 col-lg-12 col-md-12">
                <Alert variant='danger'>
                    {translated.PermissionDenied}
                </Alert>
            </div>
            : 
            <div className="col-xs-12 col-lg-12 col-md-12">
                <Alert variant='info'>{translated.DataNotLoad}</Alert>
            </div>
        }
        
    </div>

    <Offcanvas placement="end" show={showFormAdd} onHide={() => setShowFormAdd(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                {translated.FilterReport}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>
          <Offcanvas.Body>
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Company}</Form.Label>
                            <Controller
                                name="Station"                                
                                control={control}
                                render={({ field }) => (
                                <Select
                                    options={listCompanies}                   
                                    isSearchable                  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        getStations(item.id)
                                        setSelectedCompany({label:item.label, value:item.value})
                                        setValue("ControllerId", null)
                                    }}
                                />
                                )}
                            />
                        {errors.Station && 
                            <span className="text-danger font-size-13">{errors.Station.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Controller}</Form.Label>
                            <Controller
                                name="ControllerId"                                
                                control={control}
                                render={({ field: {value} }) => (
                                <Select
                                    options={listControllers}                   
                                    isSearchable                  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    value={value}
                                    onChange={(item) => {
                                        console.log(item)
                                        setValue("ControllerId", item)
                                    }}
                                />
                                )}
                            />
                        {errors.Controller && 
                            <span className="text-danger font-size-13">{errors.Controller.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.StartDate}</Form.Label>
                        <Controller
                            name="StartPeriod"                                
                            control={control}
                            rules={{required:translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y",
                                    locale: Russian
                                }}
                                value={value}
                                placeholder={translatedForm.EnterSingleValue}
                                onChange={([date]) => {
                                    setValue("StartPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.StartPeriod && 
                            <span className="text-danger font-size-13">{errors.StartPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.EndDate}</Form.Label>
                        <Controller
                            name="EndPeriod"                                
                            control={control}
                            rules={{required:translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y",
                                    locale: Russian
                                }}
                                placeholder={translatedForm.EnterSingleValue}
                                value={value}
                                onChange={([date]) => {
                                    setValue("EndPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.EndPeriod && 
                            <span className="text-danger font-size-13">{errors.EndPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <button type="submit" disabled={loadingData.button} className="btn mt-2 btn-primary">
                        {loadingData.button && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingData.button && <span>{translated.GenerateReport}</span>}
                        {!loadingData.button && <span>{translated.GenerateReport}</span>}
                    </button>
                </div>
            </div>
        </form>
          </Offcanvas.Body>
    </Offcanvas>
    </>
    :
    <Redirect to='/login'/>
  );

}

import React, { useState, useEffect, useMemo, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Card, Spinner, Alert, Table, ProgressBar} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import Chart from "react-apexcharts";
import moment from 'moment/moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import './../../boxicons.css';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateReport } from '../../lang/reports/translateReport';
import {TranslatePouring} from '../../lang/pourings/translatePouring';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const ReportActions = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listStation, setListStation] = useState([])
  const [data, setData] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [listControllers, setListControllers] = useState([])
  const [countStations, setCountStations] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const translated = useLittera(TranslateReport);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();

  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:0
  })
  const [selectedStation, setSelectedStation] = useState({
    label:'',
    value:0
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });
  
  const displayToast = (message, status, typeMessage) => {
    
    if(typeMessage == "success") {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    } else {
        toast.info(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    }
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")
        console.log(response);

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))

        setListCompanies(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getUsers = id => {
    api.get('/api/company/details/users', {
        headers:{
            "content-type" : "application/json",
            "CompanyId": id
        },
        params:{
            CompanyId: id
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")
        console.log(response);

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.lastname + ' ' + item.firstname
        }))

        setListControllers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }


  const onSubmit = data => {
    setLoadingData({button:true})
    api.get("/api/reports/actions", {
        headers:{
            "content-type" : "application/json",
            "CompanyId": selectedCompany.value,
            "UserId": data.UserId,
            "EndPeriod": data.EndPeriod.toISOString(),
            "StartPeriod": data.StartPeriod.toISOString()
        },
        params:{
            UserId: data.UserId,
            CompanyId: selectedCompany.value,
            EndPeriod: data.EndPeriod.toISOString(),
            StartPeriod: data.StartPeriod.toISOString()
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("messages")
        console.log(response);
        setLoadingData({button:false})
        setIsLoading(true)
        setPermission({
            read: response.data.read,
            fullAccess: response.data.fullAccess
        })

        if(response.data.read || response.data.fullAccess)
        {
            setData(response.data.listDatas)
            displayToast(response.data.message, response.data.isSuccess, response.data.typeMessage)
        }
    })
    .catch(function (error) {
        console.log(error);
        setIsLoading(false)
        setLoadingData({button:false})
    });
  }

  const columns = useMemo(() => [
        {
            accessorFn: (row) => row.message,
            accessorKey: 'message', //access nested data with dot notation
            header: translated.Action,
        },
        {
            accessorFn: (row) => row.ipAddress,
            accessorKey: 'ipAddress', //access nested data with dot notation
            header: translated.IP,
        },
        {
            accessorFn: (row) => row.fullname,
            accessorKey: 'fullName', //access nested data with dot notation
            header: translated.User,
        },
        {
            accessorFn: (row) => moment(row.createdAt).format("DD.MM.YYYY HH:ss"),
            accessorKey: 'date', //access nested data with dot notation
            header: translated.DateDefault,
        },
    ],
    [],
  );

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getCompanies()
    setValue("StartPeriod", new Date())
    setValue("EndPeriod", new Date())
  }, []);

  return (
    isAuth ?
    <>
    <div className="d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
            {translated.Analytics} - <span className="text-secondary benzamat-font-bold font-size-18">{translated.ReportUserLogs}</span>
        </h2>
        <div className="page-title-right">
            <Button varian="primary" onClick={() => setShowFormAdd(true)}>
             {translated.FilterReport}
            </Button>
        </div>             
    </div>
    <div className="row mt-4">

        {
            isLoading
            ?
                permission.read || permission.fullAccess
                ?
                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">         
                    <div className="mt-2">
                        <MaterialReactTable 
                            columns={columns} 
                            data={data}
                            localization={MRT_Localization_EN}
                            initialState={{ showColumnFilters: true }}
                            enableTopToolbar={false}
                            enableRowVirtualization
                            muiTablePaperProps={{
                            elevation: 1,
                            }}
                            muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                                fontWeight: 'bold',
                                color:"#000",
                                backgroundColor:'#F3F3F3',
                                fontFamily: 'BenzamatRegular',
                                zIndex:5
                            },
                            }}
                            muiTableBodyCellProps={{
                            sx: {
                                color:"#000",
                                fontFamily: 'BenzamatRegular',
                                zIndex:5
                            },
                            }}
                        />
                    </div>
                </div>
                : 
                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                    <Alert variant='danger'>
                        {translated.PermissionDenied}
                    </Alert>
                </div>
            :
            <div className="col-xs-12 col-lg-12 col-md-12">
                <Alert variant='info'>{translated.DataNotLoad}</Alert>
            </div>
        }

    </div>

    <Offcanvas placement="end" show={showFormAdd} onHide={() => setShowFormAdd(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                {translated.FilterReport}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>
          <Offcanvas.Body>
        
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Company}</Form.Label>
                            <Controller
                                name="Company"                                
                                control={control}
                                render={({ field }) => (
                                <Select
                                    options={listCompanies}                   
                                    isSearchable                  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        getUsers(item.id)
                                        setSelectedCompany({label:item.label, value:item.value})
                                    }}
                                />
                                )}
                            />
                        {errors.Station && 
                            <span className="text-danger font-size-13">{errors.Station.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.User}</Form.Label>
                            <Controller
                                name="UserId"                                
                                control={control}
                                render={({ field: {value} }) => (
                                <Select
                                    options={listControllers}                   
                                    isSearchable                  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    value={value}
                                    onChange={(item) => {
                                        console.log(item)
                                        setValue("UserId", item)
                                    }}
                                />
                                )}
                            />
                        {errors.Controller && 
                            <span className="text-danger font-size-13">{errors.Controller.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.StartDate}</Form.Label>
                        <Controller
                            name="StartPeriod"                                
                            control={control}
                            rules={{required:translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y",
                                    locale: Russian
                                }}
                                value={value}
                                placeholder={translatedForm.EnterSingleValue}
                                onChange={([date]) => {
                                    setValue("StartPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.StartPeriod && 
                            <span className="text-danger font-size-13">{errors.StartPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.EndDate}</Form.Label>
                        <Controller
                            name="EndPeriod"                                
                            control={control}
                            rules={{required:translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y",
                                    locale: Russian
                                }}
                                placeholder={translatedForm.EnterSingleValue}
                                value={value}
                                onChange={([date]) => {
                                    setValue("EndPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.EndPeriod && 
                            <span className="text-danger font-size-13">{errors.EndPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <button type="submit" disabled={loadingData.button} className="btn mt-2 btn-primary">
                        {loadingData.button && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingData.button && <span>{translated.GenerateReport}</span>}
                        {!loadingData.button && <span>{translated.GenerateReport}</span>}
                    </button>
                </div>
            </div>
        </form>

          </Offcanvas.Body>
    </Offcanvas>

    </>
    :
    <Redirect to='/login'/>
  );

}

import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Card, Spinner, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../../../boxicons.css';
import api from '../../../../utils/axiosInterceptions';

export const TopazBs2v2 = ({translated, translatedForm, listSmartData, countDispenser, controllerInfo}) => {

    const router = useHistory()
    const params = useParams();
    const { control, register, handleSubmit, reset, setValue,  getValues, formState, formState: { errors }} = useForm();
    const [showSecondSleeva, setShowSecondSleeve] = useState({
        first_sleeve:false,
        second_sleeve:false,
        thrid_sleeve:false,
        four_sleeve:false
    })
    const [fuels, setFuels] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)

    const listSubtypes = [
        {label:translated.Topaz610, value:0},
        {label:translated.Topaz420, value:1}
    ]

    const listCountDispenser = [
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4}
    ]

    const addresses = [
        {label:"0", value:0},
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
        {label:"5", value:5},
        {label:"6", value:6},
        {label:"7", value:7},
        {label:"8", value:8},
        {label:"9", value:9},
        {label:"10", value:10},
    ]

    function setSmartData(data, index, list_fuel)
    {
        index++
        console.log("index++")
        console.log(list_fuel.filter((item) => item.id == data.fuelType))
        let subtype  = listSubtypes.filter((item) => item.value == data.subType)
        let address1 = addresses.filter((item) => item.value == data.address)
        let address12 = addresses.filter((item) => item.value == data.address2)
        let address13 = addresses.filter((item) => item.value == 3)
        let address14 = addresses.filter((item) => item.value == 4)
        let fuetype1 =  data.fuelType != 0 
            ? list_fuel.filter((item) => item.id == data.fuelType)
            : list_fuel.filter((item) => item.id == 1)
        let fuetype12 =  list_fuel.filter((item) => item.id == data.fuelType2)
        let fuetype13 =  list_fuel.filter((item) => item.id == 1)
        let fuetype14 =  list_fuel.filter((item) => item.id == 1)

        console.log(subtype)
        if(subtype[0].value == 0) {
            setShowSecondSleeve({
                first_sleeve:true
            })
        } else {
            setShowSecondSleeve({
                first_sleeve:false
            })
        }

        setValue("Subtype"+index, subtype)
        setValue("Address"+index, address1)
        setValue("Address"+index+"2", address12)
        setValue("Address"+index+"3", address13)
        setValue("Address"+index+"4", address14)
        setValue("FuelType"+index, {label:fuetype1[0].name, value:fuetype1[0].id})
        setValue("FuelType"+index+"2", {label:fuetype12[0].name, value:fuetype12[0].id})
        setValue("FuelType"+index+"3", {label:fuetype13[0].name, value:fuetype13[0].id})
        setValue("FuelType"+index+"4", {label:fuetype14[0].name, value:fuetype14[0].id})
    }

    const getSmarts = (fuels) => {
        api.get(`/api/controllers/details/smarts`, {
            headers:{
                "content-type" : "application/json",
                "controllerId":controllerInfo.id
            },
            params:{
                controllerId: controllerInfo.id,
            },
            withCredentials:true
        })
        .then(function (response) 
        { 
            console.log("SMART DATA method")
            console.log(response.data)
            let dispenser = listCountDispenser.filter((item) => item.value == response.data.listDatas[0].countDispenser)
            setValue("CountDispenser", {label:dispenser[0].label, value:dispenser[0].value})

            for(let i = 0; i < 4; i++)
            {
                setSmartData(response.data.listDatas[i], i, fuels)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getFuels = () => {
        api.get(`/api/fuels/all`, {
            withCredentials:true
        })
        .then(function (response) { 
            const add = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setFuels(add)
            getSmarts(add)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getFuels()
    }, [])

    const onSubmit = data => {
        setLoadingBtn(true)
        console.log(data)

        let smarts = []

        let trk = {
            //ControllerId: params.id,
            SubType: data.Subtype1.value,
            Address: data.Address1.value,
            Address2: data.Address12.value,
            Address3: 3,
            Address4: 4,
            FuelType: data.FuelType1 != undefined ? data.FuelType1.value : 1,
            FuelType2: data.FuelType12 != undefined ? data.FuelType12.value : 1,
            FuelType3: 1,
            FuelType4: 1,
        }

        let trk2 = {
            //ControllerId: params.id,
            SubType: data.Subtype2.value,
            Address: data.Address2.value,
            Address2: data.Address22.value,
            Address3: 3,
            Address4: 4,
            FuelType: data.FuelType2 != undefined ? data.FuelType2.value : 1,
            FuelType2: data.FuelType22 != undefined ? data.FuelType22.value : 1,
            FuelType3: 1,
            FuelType4: 1,
        }

        let trk3 = {
           // ControllerId: params.id,
            SubType: data.Subtype3.value,
            Address: data.Address3.value,
            Address2: data.Address32.value,
            Address3: 3,
            Address4: 4,
            FuelType: data.FuelType3 != undefined ? data.FuelType3.value : 1,
            FuelType2: data.FuelType32 != undefined ? data.FuelType32.value : 1,
            FuelType3: 1,
            FuelType4: 1,
        }

        let trk4 = {
            //ControllerId: params.id,
            SubType: data.Subtype4.value,
            Address: data.Address4.value,
            Address2: data.Address42.value,
            Address3: 3,
            Address4: 4,
            FuelType: data.FuelType4 != undefined ? data.FuelType4.value : 1,
            FuelType2: data.FuelType42 != undefined ? data.FuelType42.value : 1,
            FuelType3: 1,
            FuelType4: 1,
        }

        smarts.push(trk)
        smarts.push(trk2)
        smarts.push(trk3)
        smarts.push(trk4)

        console.log(smarts)

        api.post('/api/controllers/details/topaz/update', {
            Smarts: smarts,
            ControllerId: params.id,
            CountDispenser: data.CountDispenser.value,
            withCredentials:true
        })
        .then(function (response) {
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    return(      
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <h4 className="text-black">{translated.Topaz}</h4>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.CountDispencer}</Form.Label>
                            <Controller
                                name="CountDispenser"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field:{value} }) => (
                                <Select
                                    value={value}
                                    options={listCountDispenser}
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        setValue("CountDispenser", {label:item.label, value:item.value})
                                    }}
                                />
                                )}
                            />
                            {errors.CountDispenser && 
                                <span className="text-danger font-size-13">{errors.CountDispenser.message}</span>
                            }
                    </Form.Group>
                </div>

                <Tabs defaultActiveKey="trk" className="mt-3" transition={false} id="noanim-tab-example">
                    
                    <Tab eventKey="trk" title={translated.Params1}>                        
                        <div className="row">
                            <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-black">{translated.ModelDispenser} </Form.Label>
                                        <Controller
                                            name="Subtype1"                                
                                            control={control}
                                            rules={{ required: translatedForm.Required }}
                                            render={({ field:{value} }) => (
                                            <Select
                                                value={value}
                                                options={listSubtypes}
                                                placeholder={translatedForm.SelectFromList}
                                                classNamePrefix="select"
                                                onChange={(item) => {
                                                    console.log(item)
                                                    setValue("Subtype1", item)

                                                    if(item.value == 0) {
                                                        setShowSecondSleeve({
                                                            first_sleeve:true
                                                        })
                                                    } else {
                                                        setShowSecondSleeve({
                                                            first_sleeve:false
                                                        })
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.Subtype1 && 
                                            <span className="text-danger font-size-13">{errors.Subtype1.message}</span>
                                        }
                                </Form.Group>

                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve1}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address1"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address1", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address1 && 
                                                <span className="text-danger font-size-13">{errors.Address1.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType1"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType1", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType1 && 
                                                <span className="text-danger font-size-13">{errors.FuelType1.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve2}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address12"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled={showSecondSleeva.first_sleeve}
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address12", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address12 && 
                                                <span className="text-danger font-size-13">{errors.Address12.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType12"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled={showSecondSleeva.first_sleeve}
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType12", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType12 && 
                                                <span className="text-danger font-size-13">{errors.FuelType12.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            
                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve3}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address13"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address13", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address13 && 
                                                <span className="text-danger font-size-13">{errors.Address13.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType13"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType13", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType13 && 
                                                <span className="text-danger font-size-13">{errors.FuelType13.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve4}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address14"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address14", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address14 && 
                                                <span className="text-danger font-size-13">{errors.Address14.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType14"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType14", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType14 && 
                                                <span className="text-danger font-size-13">{errors.FuelType14.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Tab>

                    <Tab eventKey="trk2" title={translated.Params2}>                        
                        <div className="row">
                            <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-black">{translated.ModelDispenser} </Form.Label>
                                        <Controller
                                            name="Subtype2"                                
                                            control={control}
                                            rules={{ required: translatedForm.Required }}
                                            render={({ field:{value} }) => (
                                            <Select
                                                value={value}
                                                options={listSubtypes}
                                                placeholder={translatedForm.SelectFromList}
                                                classNamePrefix="select"
                                                onChange={(item) => {
                                                    console.log(item)
                                                    setValue("Subtype2", item)

                                                    if(item.value == 0) {
                                                        setShowSecondSleeve({
                                                            second_sleeve:true
                                                        })
                                                    } else {
                                                        setShowSecondSleeve({
                                                            second_sleeve:false
                                                        })
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.Subtype2 && 
                                            <span className="text-danger font-size-13">{errors.Subtype2.message}</span>
                                        }
                                </Form.Group>

                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve1}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address2"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address2", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address2 && 
                                                <span className="text-danger font-size-13">{errors.Address2.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType2"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType2", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType2 && 
                                                <span className="text-danger font-size-13">{errors.FuelType2.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve2}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address22"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled={showSecondSleeva.second_sleeve}
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address22", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address22 && 
                                                <span className="text-danger font-size-13">{errors.Address22.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType22"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled={showSecondSleeva.second_sleeve}
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType22", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType22 && 
                                                <span className="text-danger font-size-13">{errors.FuelType22.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            
                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve3}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address23"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address23", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address23 && 
                                                <span className="text-danger font-size-13">{errors.Address23.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType23"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType23", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType23 && 
                                                <span className="text-danger font-size-13">{errors.FuelType23.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve4}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address24"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address24", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address24 && 
                                                <span className="text-danger font-size-13">{errors.Address24.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType24"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType24", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType24 && 
                                                <span className="text-danger font-size-13">{errors.FuelType24.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Tab>
                    
                    <Tab eventKey="trk3" title={translated.Params3}>                        
                        <div className="row">
                                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="text-black">{translated.ModelDispenser} </Form.Label>
                                            <Controller
                                                name="Subtype3"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field:{value} }) => (
                                                <Select
                                                    value={value}
                                                    options={listSubtypes}
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => {
                                                        console.log(item)
                                                        setValue("Subtype3", item)

                                                        if(item.value == 0) {
                                                            setShowSecondSleeve({
                                                                thrid_sleeve:true
                                                            })
                                                        } else {
                                                            setShowSecondSleeve({
                                                                thrid_sleeve:false
                                                            })
                                                        }
                                                    }}
                                                />
                                                )}
                                            />
                                            {errors.Subtype3 && 
                                                <span className="text-danger font-size-13">{errors.Subtype3.message}</span>
                                            }
                                    </Form.Group>

                                </div>

                                <div className="col-xs-12 col-lg-6 col-md-6">
                                    <Card border="light" className="border shadow-none">
                                        <Card.Body>
                                        <h3 className="text-black benzamat-font-bold">{translated.Sleeve1}</h3>
                                        <div>
                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                                <Controller
                                                    name="Address3"                                
                                                    control={control}
                                                    rules={{ required: translatedForm.Required }}
                                                    render={({ field: {value} }) => (
                                                    <Select                      
                                                        isSearchable
                                                        options={addresses}
                                                        value={value}               
                                                        placeholder={translatedForm.SelectFromList}
                                                        classNamePrefix="select"
                                                        onChange={(item) => setValue("Address3", item)}
                                                    />
                                                    )}
                                                />
                                                {errors.Address3 && 
                                                    <span className="text-danger font-size-13">{errors.Address3.message}</span>
                                                } 
                                            </Form.Group>

                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                                <Controller
                                                    name="FuelType3"                                
                                                    control={control}
                                                    rules={{ required: translatedForm.Required }}
                                                    render={({ field: {value} }) => (
                                                    <Select                      
                                                        isSearchable
                                                        options={fuels}
                                                        value={value}               
                                                        placeholder={translatedForm.SelectFromList}
                                                        classNamePrefix="select"
                                                        onChange={(item) => setValue("FuelType3", item)}
                                                    />
                                                    )}
                                                />
                                                {errors.FuelType3 && 
                                                    <span className="text-danger font-size-13">{errors.FuelType3.message}</span>
                                                } 
                                            </Form.Group>
                                        </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="col-xs-12 col-lg-6 col-md-6">
                                    <Card border="light" className="border shadow-none">
                                        <Card.Body>
                                        <h3 className="text-black benzamat-font-bold">{translated.Sleeve2}</h3>
                                        <div>
                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                                <Controller
                                                    name="Address32"                                
                                                    control={control}
                                                    rules={{ required: translatedForm.Required }}
                                                    render={({ field: {value} }) => (
                                                    <Select                      
                                                        isSearchable
                                                        isDisabled={showSecondSleeva.thrid_sleeve}
                                                        options={addresses}
                                                        value={value}               
                                                        placeholder={translatedForm.SelectFromList}
                                                        classNamePrefix="select"
                                                        onChange={(item) => setValue("Address32", item)}
                                                    />
                                                    )}
                                                />
                                                {errors.Address32 && 
                                                    <span className="text-danger font-size-13">{errors.Address32.message}</span>
                                                } 
                                            </Form.Group>

                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                                <Controller
                                                    name="FuelType32"                                
                                                    control={control}
                                                    rules={{ required: translatedForm.Required }}
                                                    render={({ field: {value} }) => (
                                                    <Select                      
                                                        isSearchable
                                                        isDisabled={showSecondSleeva.thrid_sleeve}
                                                        options={fuels}
                                                        value={value}               
                                                        placeholder={translatedForm.SelectFromList}
                                                        classNamePrefix="select"
                                                        onChange={(item) => setValue("FuelType32", item)}
                                                    />
                                                    )}
                                                />
                                                {errors.FuelType32 && 
                                                    <span className="text-danger font-size-13">{errors.FuelType32.message}</span>
                                                } 
                                            </Form.Group>
                                        </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                
                                <div className="col-xs-12 col-lg-6 col-md-6">
                                    <Card border="light" className="border shadow-none">
                                        <Card.Body>
                                        <h3 className="text-black benzamat-font-bold">{translated.Sleeve3}</h3>
                                        <div>
                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                                <Controller
                                                    name="Address33"                                
                                                    control={control}
                                                    rules={{ required: translatedForm.Required }}
                                                    render={({ field: {value} }) => (
                                                    <Select                      
                                                        isSearchable
                                                        isDisabled
                                                        options={addresses}
                                                        value={value}               
                                                        placeholder={translatedForm.SelectFromList}
                                                        classNamePrefix="select"
                                                        onChange={(item) => setValue("Address33", item)}
                                                    />
                                                    )}
                                                />
                                                {errors.Address33 && 
                                                    <span className="text-danger font-size-13">{errors.Address33.message}</span>
                                                } 
                                            </Form.Group>

                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                                <Controller
                                                    name="FuelType33"                                
                                                    control={control}
                                                    rules={{ required: translatedForm.Required }}
                                                    render={({ field: {value} }) => (
                                                    <Select                      
                                                        isSearchable
                                                        isDisabled
                                                        options={fuels}
                                                        value={value}               
                                                        placeholder={translatedForm.SelectFromList}
                                                        classNamePrefix="select"
                                                        onChange={(item) => setValue("FuelType33", item)}
                                                    />
                                                    )}
                                                />
                                                {errors.FuelType33 && 
                                                    <span className="text-danger font-size-13">{errors.FuelType33.message}</span>
                                                } 
                                            </Form.Group>
                                        </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="col-xs-12 col-lg-6 col-md-6">
                                    <Card border="light" className="border shadow-none">
                                        <Card.Body>
                                        <h3 className="text-black benzamat-font-bold">{translated.Sleeve4}</h3>
                                        <div>
                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                                <Controller
                                                    name="Address34"                                
                                                    control={control}
                                                    rules={{ required: translatedForm.Required }}
                                                    render={({ field: {value} }) => (
                                                    <Select                      
                                                        isSearchable
                                                        isDisabled
                                                        options={addresses}
                                                        value={value}               
                                                        placeholder={translatedForm.SelectFromList}
                                                        classNamePrefix="select"
                                                        onChange={(item) => setValue("Address34", item)}
                                                    />
                                                    )}
                                                />
                                                {errors.Address34 && 
                                                    <span className="text-danger font-size-13">{errors.Address34.message}</span>
                                                } 
                                            </Form.Group>

                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                                <Controller
                                                    name="FuelType34"                                
                                                    control={control}
                                                    rules={{ required: translatedForm.Required }}
                                                    render={({ field: {value} }) => (
                                                    <Select                      
                                                        isSearchable
                                                        isDisabled
                                                        options={fuels}
                                                        value={value}               
                                                        placeholder={translatedForm.SelectFromList}
                                                        classNamePrefix="select"
                                                        onChange={(item) => setValue("FuelType34", item)}
                                                    />
                                                    )}
                                                />
                                                {errors.FuelType34 && 
                                                    <span className="text-danger font-size-13">{errors.FuelType34.message}</span>
                                                } 
                                            </Form.Group>
                                        </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                        </div>
                    </Tab>

                    <Tab eventKey="trk4" title={translated.Params4}>
                        <div className="row">
                            <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                                <Form.Group className="mb-3">
                                    <Form.Label className="text-black">{translated.ModelDispenser} </Form.Label>
                                        <Controller
                                            name="Subtype4"                                
                                            control={control}
                                            rules={{ required: translatedForm.Required }}
                                            render={({ field:{value} }) => (
                                            <Select
                                                value={value}
                                                options={listSubtypes}
                                                placeholder={translatedForm.SelectFromList}
                                                classNamePrefix="select"
                                                onChange={(item) => {
                                                    console.log(item)
                                                    setValue("Subtype4", item)

                                                    if(item.value == 0) {
                                                        setShowSecondSleeve({
                                                            four_sleeve:true
                                                        })
                                                    } else {
                                                        setShowSecondSleeve({
                                                            four_sleeve:false
                                                        })
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.Subtype4 && 
                                            <span className="text-danger font-size-13">{errors.Subtype4.message}</span>
                                        }
                                </Form.Group>

                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve1}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address4"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address4", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address4 && 
                                                <span className="text-danger font-size-13">{errors.Address4.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType4"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType4", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType4 && 
                                                <span className="text-danger font-size-13">{errors.FuelType4.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve2}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address42"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled={showSecondSleeva.four_sleeve}
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address42", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address42 && 
                                                <span className="text-danger font-size-13">{errors.Address42.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType42"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled={showSecondSleeva.four_sleeve}
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType42", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType42 && 
                                                <span className="text-danger font-size-13">{errors.FuelType42.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            
                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve3}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address43"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address43", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address43 && 
                                                <span className="text-danger font-size-13">{errors.Address43.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType43"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType43", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType43 && 
                                                <span className="text-danger font-size-13">{errors.FuelType43.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-xs-12 col-lg-6 col-md-6">
                                <Card border="light" className="border shadow-none">
                                    <Card.Body>
                                    <h3 className="text-black benzamat-font-bold">{translated.Sleeve4}</h3>
                                    <div>
                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                                            <Controller
                                                name="Address44"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={addresses}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("Address44", item)}
                                                />
                                                )}
                                            />
                                            {errors.Address43 && 
                                                <span className="text-danger font-size-13">{errors.Address44.message}</span>
                                            } 
                                        </Form.Group>

                                        <Form.Group className="mb-3 mt-3">
                                            <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                            <Controller
                                                name="FuelType44"                                
                                                control={control}
                                                rules={{ required: translatedForm.Required }}
                                                render={({ field: {value} }) => (
                                                <Select                      
                                                    isSearchable
                                                    isDisabled
                                                    options={fuels}
                                                    value={value}               
                                                    placeholder={translatedForm.SelectFromList}
                                                    classNamePrefix="select"
                                                    onChange={(item) => setValue("FuelType44", item)}
                                                />
                                                )}
                                            />
                                            {errors.FuelType44 && 
                                                <span className="text-danger font-size-13">{errors.FuelType44.message}</span>
                                            } 
                                        </Form.Group>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </Tab>

                </Tabs>

                <div className="form-group ">
                    <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                        {loadingBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                    </button>
                </div>
            </div>
        </Form>
    );
}
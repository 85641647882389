import React, { useContext, useState, useEffect, Redirect, useMemo } from 'react';
import { NavLink, Link,  useHistory } from 'react-router-dom';
import { Button, Offcanvas, Badge, Alert, Spinner } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { CompanyFormAdd } from './CompanyFormAdd';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import './../../boxicons.css';          
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateCompanyModule } from '../../lang/company';
import { CurrentSelectLanguage } from '../../helpers/language';
import api from '../../utils/axiosInterceptions';

const Company = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [listOfCompanies, addCompanyInList] = useState({});
  const [countCompanies, setCountCompanies] = useState(0);
  const translated = useLittera(TranslateCompanyModule);
  const methods = useLitteraMethods();
  const [isLoading, setIsLoading] = useState(false)
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [loadingData, setLoadingData] = useState({
    grid:false
  });
  const handleShow = () => setShowFormAdd(true);
  const handleClose = () => setShowFormAdd(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: translated.Name,
      },
      {
        accessorFn: (row) => moment(row.dateCreate).format("DD.MM.YYYY"),
        id: 'dateCreate',
        header: translated.CreatedDate,
      },
      {
        accessorFn: (row) => row.isBlocked != null 
          ? <Badge bg="success" className="font-size-13">{translated.CompanyIsActive}</Badge> 
          : <Badge bg="danger" className="font-size-13">{translated.CompanyIsBlocked}</Badge>,
        accessorKey: 'isBlocked',
        header: translated.IsBlocked,
      },
      {
        accessorFn: (row) => row.isParent
          ? <Badge bg="danger" className="font-size-13">{translated.CompanyIsVendor}</Badge> 
          : <Badge bg="info" className="font-size-13">{translated.CompanyIsClient}</Badge>,
        accessorKey: 'isParent',
        header: translated.CompanyType,
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => router.push(`/company/${row.id}`)} className="btn btn-light btn-sm">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </Button> 
        </>,
        accessorKey: 'button',
        header: translated.Actions,
      },
    ],
    [],
  );

  const getCompanies = () => {

    setIsLoading(true)
    setLoadingData({grid:true})
    api.get('/api/company/all', {
        withCrenditials:true
    })
    .then(function(response){
      console.log(response)
      addCompanyInList(response.data.listDatas);
      setCountCompanies(response.data.listDatas.length);
      setLoadingData({grid:false})
      setPermission({
        read: response.data.read,
        create: response.data.create,
        fullAccess: response.data.fullAccess
      })
      setIsLoading(false)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getCompanies();
  }, []);

  return (
    isAuth ?
    <>

    {
      isLoading
      ? <Spinner animation="border" variant="info" />
      :
      <>
      <div className="d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
            {translated.Companies}
            <span className="text-secondary benzamat-font-bold font-size-20"> {countCompanies}</span>
        </h2>
        {
          permission.create || permission.fullAccess
          ?       
          <div className="page-title-right">
            <Button varian="primary" onClick={handleShow}>
                <i className="bx bx-plus font-size-16 align-middle me-2 "></i>{translated.AddCompany}
            </Button>
          </div>    
          : ''
        }             
      </div>
      <div className="row">
          <div className="col-xs-12 col-lg-12 col-md-12 ">         
              <div className="mt-4">
                {
                  permission.read || permission.create || permission.fullAccess
                  ?               
                  <MaterialReactTable 
                    columns={columns} 
                    data={listOfCompanies}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                      elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                  />
                  : 
                  <Alert variant='danger'>{translated.AccessDenied}</Alert>
                }
              </div>
          </div>
      </div>
      <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>{translated.CreateCompany}</Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>
          <Offcanvas.Body>  

            <h4 className="text-black">{translated.LegalInfo}</h4>

            <CompanyFormAdd translated={translated} addCompanyInList={addCompanyInList} IsParent={true} setCountCompanies={setCountCompanies} />

          </Offcanvas.Body>
      </Offcanvas>
      </>
    }
    </>
    :
    <Redirect to='/login'/>
  );

}

export default Company;

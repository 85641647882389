import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Tooltip, OverlayTrigger, Form, Card, Alert, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const LimitDayMonth = ({translated, translatedForm}) => {

    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [btnDayLoad, setBtnDayLoad] = useState(false)
    const [btnMonthLoad, setBtnMonthLoad] = useState(false)
    const [listLimits, setListLimits] = useState({})
    const [liters, setLiters] = useState(0)
    const [loadingDataGrid, setLoadingDataGrid] = useState(false)
    const [dayLimit, setDayLimit] = useState({
        limit:0,
        balance:0
    })
    const [monthLimit, setMonthLimit] = useState({
        limit: 0,
        balance:0
    });
    const [permission, setPermission] = useState({
        read: false,
        update: false,
        fullAccess: false
    })
    const [loadingDataBtn, setLoadingDataButton] = useState(false)
    const [currentActiveTab, setCurrentActiveTab] = useState('')

    const handleShow = () => {
        setShowFormAdd(true)
        getEnumTypeLimits()
    }

    const handleClose = () => setShowFormAdd(false);

    const handleDayLimit = () => {
        setBtnDayLoad(true)
        api.get("/api/users/details/limit-day", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            console.log(response)
            setDayLimit({
                balance: response.data.dataResult.balanceDay,
                limit: response.data.dataResult.countDayLimit
            })
            displayToast(response.data.message, response.data.isSuccess)
            setCurrentTime(moment().format("DD.MM.YYYY"))
            setBtnDayLoad(false)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleMonthLimit = () => {
        setBtnMonthLoad(true)
        api.get("/api/users/details/limit-month", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            console.log(response)
            setMonthLimit({
                limit: response.data.dataResult.countMonthLimit,
                balance: response.data.dataResult.balanceMonth
            })
            displayToast(response.data.message, response.data.isSuccess)
            setCurrentTime(moment().format("DD.MM.YYYY"))
            setBtnMonthLoad(false)
        })
        .catch(function (error) {
            console.log(error);
            setBtnMonthLoad(false)
        });
    }

    const onHandleUpdateLimit = dataLimit => {    
        setLoadingDataButton(true)
        api.post('/api/users/details/update-limit', {
            Id: params.id,
            Liters: liters,
            EnumTypeLimitId: dataLimit.EnumTypeLimit.id
        })
        .then(function (response) {
            
            setLoadingDataButton(false)
            displayToast(response.data.message, response.data.isSuccess)

            if (response.data.dataResult.enumTypeLimitId == 1) {
                setDayLimit({
                    limit: response.data.dataResult.countDayLimit,
                    balance: response.data.dataResult.balanceDay
                })
            } else {
                setMonthLimit({
                    limit: response.data.dataResult.countMonthLimit,
                    balance: response.data.dataResult.balanceMonth
                })
            }
        })
        .catch(function (error) {
            console.log(error);
            displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
            setLoadingDataButton(false)
        });
    }

    const getEnumTypeLimits = () => {
        api.get("/api/enum-type-limits/all", {
        })
        .then(function (response) {
            const addTypeLimit = response.data.listDatas.filter((i) => i.id == 1 || i.id == 2).map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setListLimits(addTypeLimit)
        })
        .catch(function (error) {
            console.log(error);
        });    
    }

    const getLimits = () => {
        api.get("/api/users/details/limit-day", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            console.log("limits")
            console.log(response)
            setPermission({
                read: response.data.read,
                update: response.data.update,
                fullAccess: response.data.fullAccess
            })
            setDayLimit({
                limit: response.data.dataResult.countDayLimit,
                balance: response.data.dataResult.balanceDay
            })
            setCurrentTime(moment().format("DD.MM.YYYY"))
        })
        .catch(function (error) {
            console.log(error);
        });

        api.get("/api/users/details/limit-month", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            setMonthLimit({
                limit: response.data.dataResult.countMonthLimit,
                balance: response.data.dataResult.balanceMonth
            })
            setCurrentTime(moment().format("DD.MM.YYYY"))
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getLimits()
    }, [])

    const displayToast = (message, status) => {

        if(status) {
            toast.success(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        
            } else {
            toast.error(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        }
    
    }

    return (
        <>
            <div className="col-xs-12 col-md-6 col-lg-6">
                <Card border="light" className="border shadow-none">
                <Card.Body>
                <div className="d-flex">
                <h5 className="benzamat-font-bold flex-fill text-black">{translated.LimitDay} {currentTime}</h5>

                {
                    permission.update || permission.fullAccess
                    ?
                    <OverlayTrigger
                        placement="left"
                        overlay={
                        <Tooltip style={{fontSize:14+"px"}}>
                            {translated.EditLimit}
                        </Tooltip>
                        }
                    >
                    <Button onClick={handleShow} className="btn btn-light justify-content-end btn-sm">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.45986 16.8349L3.16455 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </Button> 
                    </OverlayTrigger>
                    : null
                }

                </div>
                <span className="benzamat-font-bold text-black d-block mb-3 mt-2" style={{fontSize:18+"px"}}>{translated.Limit}: {dayLimit.limit}</span>
                <span className="benzamat-font-bold text-black d-block mb-3" style={{fontSize:18+"px"}}>{translated.Balance}: {dayLimit.balance}</span>

                {
                    permission.update || permission.fullAccess
                    ?
                    <>
                    <hr/>
                        <label className="text-black">{translated.Balance}:</label>
                        <div className="input-group">
                            <input type="text" readonly value={currentTime} className="form-control" />
                            <button onClick={() => handleDayLimit()}  type="button" disabled={btnDayLoad} className="btn btn-primary">
                                {btnDayLoad && (
                                    <Spinner animation="border" variant="light" 
                                        as="span"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                {!btnDayLoad && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.23682 7.79016H2.48682V4.04016" stroke="#fff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.8612 5.13864C14.2228 4.50024 13.4649 3.99383 12.6308 3.64833C11.7967 3.30283 10.9027 3.125 9.99983 3.125C9.09699 3.125 8.20299 3.30283 7.36888 3.64833C6.53476 3.99383 5.77687 4.50024 5.13847 5.13864L2.48682 7.79029" stroke="#fff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.7632 12.2098H17.5132V15.9598" stroke="#fff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M5.13867 14.8615C5.77707 15.4999 6.53497 16.0063 7.36908 16.3518C8.2032 16.6973 9.09719 16.8751 10 16.8751C10.9029 16.8751 11.7969 16.6973 12.631 16.3518C13.4651 16.0063 14.223 15.4999 14.8614 14.8615L17.513 12.2098" stroke="#fff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                }
                            </button>                           
                    </div>
                    </>
                    : ''
                }                            
                </Card.Body>
                </Card>
            </div>

            <div className="col-xs-12 col-md-6 col-lg-6">
                <Card border="light" className="border shadow-none">
                <Card.Body>
                <div className="d-flex">
                <h5 className="benzamat-font-bold flex-fill text-black">{translated.LimitMonth} {currentTime}</h5>

                {
                    permission.update || permission.fullAccess
                    ?
                    <OverlayTrigger
                        placement="left"
                        overlay={
                        <Tooltip style={{fontSize:14+"px"}}>
                            {translated.EditLimit}
                        </Tooltip>
                        }
                    >
                    <Button onClick={handleShow} className="btn btn-light justify-content-end btn-sm">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.45986 16.8349L3.16455 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </Button> 
                    </OverlayTrigger>
                    : null
                }

                </div>
                <span className="benzamat-font-bold text-black d-block mb-3 mt-2" style={{fontSize:18+"px"}}>{translated.Limit}: {monthLimit.limit}</span>
                <span className="benzamat-font-bold text-black d-block mb-3" style={{fontSize:18+"px"}}>{translated.Balance}: {monthLimit.balance}</span>

                {
                    permission.update || permission.fullAccess
                    ?
                    <>
                    <hr/>
                    <label className="text-black">{translated.Balance}:</label>
                    <div className="input-group">
                        <input type="text" value={currentTime} readonly className="form-control" />
                        <button onClick={() => handleMonthLimit()}  type="button" disabled={btnMonthLoad} className="btn btn-primary">
                            {btnMonthLoad && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            {!btnMonthLoad && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.23682 7.79016H2.48682V4.04016" stroke="#fff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.8612 5.13864C14.2228 4.50024 13.4649 3.99383 12.6308 3.64833C11.7967 3.30283 10.9027 3.125 9.99983 3.125C9.09699 3.125 8.20299 3.30283 7.36888 3.64833C6.53476 3.99383 5.77687 4.50024 5.13847 5.13864L2.48682 7.79029" stroke="#fff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.7632 12.2098H17.5132V15.9598" stroke="#fff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.13867 14.8615C5.77707 15.4999 6.53497 16.0063 7.36908 16.3518C8.2032 16.6973 9.09719 16.8751 10 16.8751C10.9029 16.8751 11.7969 16.6973 12.631 16.3518C13.4651 16.0063 14.223 15.4999 14.8614 14.8615L17.513 12.2098" stroke="#fff" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            }
                        </button>
                    </div>
                    </>
                    : ''
                }
                
                </Card.Body>
                </Card>
            </div>
                    
            <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                        {translated.EditLimit}
                    </Offcanvas.Title>
                </Offcanvas.Header>
            <div className="dropdown-divider"></div>
            <Offcanvas.Body> 
                <Form onSubmit={handleSubmit(onHandleUpdateLimit)}>

                    <Form.Group>

                    <Form.Label className="text-black">{translated.TypeLimit}</Form.Label>
                    <Controller
                        name="EnumTypeLimit"                                
                        control={control}
                        rules={{ required: translated.Required}}
                        render={({ field }) => (
                        <Select 
                            {...field}                               
                            isSearchable
                            options={listLimits}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                        />
                        )}
                    />
                    {errors.EnumTypeLimit && 
                        <span className="text-danger font-size-13">{errors.EnumTypeLimit.message}</span>
                    }   
                    
                    <Form.Label className="text-black mt-3">{translated.Liters}</Form.Label>
                    <Controller
                        name="Liters"                                
                        control={control}
                        render={({ field }) => (
                        <CurrencyInput
                            placeholder={translatedForm.EnterValue(2)}
                            defaultValue={0}
                            decimalsLimit={2}
                            className="form-control"
                            onValueChange={(value) => setLiters(value)}
                        />
                        )}
                    />

                    </Form.Group>

                    <div className="form-group mt-3">
                    <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                        {loadingDataBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingDataBtn && <span>{translatedForm.SaveChanges}</span>}
                        {!loadingDataBtn && <span>{translatedForm.SaveChanges}</span>}
                        </button>
                    </div>

                </Form>
            </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Offcanvas, Form, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import moment from 'moment';
import axios from 'axios';
import Select from 'react-select';
import './../../boxicons.css';
import CurrencyInput from 'react-currency-input-field';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import { FormLocale } from '../../lang/form';
import { TranslateAccount } from '../../lang/company/account';
import api from '../../utils/axiosInterceptions';

export const Account = () => {
    
    const params = useParams()
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false);
    const [listHistory, setListHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [money, setMoney] = useState(0)
    const translated = useLittera(TranslateAccount)
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();
    const handleShow = () => setShowFormAdd(true);
    const handleClose = () => setShowFormAdd(false);

    useEffect(() => {
        methods.setLocale(CurrentSelectLanguage);
        api.get("/api/company/details/account/history", {
            method: 'get',
            url: `/api/company/details/account/history`,
            headers:{
              "content-type" : "application/json",
              "companyId":params.id
            },
            params:{
              companyId: params.id,
            }
        })
          .then(function (response) {
            console.log("accounts")
            console.log(response)
            setListHistory(response.data.listDatas)
        })
          .catch(function (error) {
            console.log(error);
        });
    }, [])

    const onSubmit = data => {
        setLoading(true)
        api.post("/api/company/details/account/create", {
            Id: params.id,
            Money: money,
            Comment: data.Comment
        })
        .then(function (response) {
            displayToast(response.data.message, response.data.isSuccess)
            setLoading(false)
            setListHistory(response.data.listDatas)
            reset({
                Money:'',
                Comment:''
            })
        })
        .catch(function (error) {
            console.log(error);
            setLoading(false)
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }

    const displayToast = (message, status) => {

        if(status) {
            toast.success(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        } else {
            toast.error(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        }
    }

    const dateCreated = (rowData) => {
        return moment(rowData.createdDate).format("DD.MM.YYYY")
    }

    const columns = useMemo(
        () => [
          {
            accessorFn: (row) => moment(row.createdDate).format("DD.MM.YYYY"),
            id: 'dateCreate',
            header: translated.DateRecord,
          },
          {
            accessorFn: (row) => row.countMoney,
            accessorKey: 'sum', //normal accessorKey
            header: translated.Sum,
          },
          {
            accessorFn: (row) => row.user.userName,
            accessorKey: 'user', //normal accessorKey
            header: translated.User,
          },
        ],
    [],
    );
    
    return (
        <>
            <Button bg="primary" onClick={(() => handleShow())}>
                <i className="bx bx-plus font-size-16 align-middle me-2 "></i> 
                {translated.RefillAccount}
            </Button>

            <div className="mt-2">

                <MaterialReactTable 
                    columns={columns} 
                    data={listHistory}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                      elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                />

            </div>
            <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                        {translated.RefillAccount}
                    </Offcanvas.Title>
                </Offcanvas.Header>

                <div className="dropdown-divider"></div>
                <Offcanvas.Body>

                <Form onSubmit={handleSubmit(onSubmit)}>                    
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.Sum} <sup className="text-danger">*</sup></Form.Label>                        
                            <Controller
                                name="Money"                                
                                control={control}
                                render={({ field }) => (
                                <CurrencyInput
                                    placeholder={translated.EnterSum}
                                    defaultValue={0}
                                    decimalsLimit={2}
                                    suffix={' '+ translated.Valute}
                                    className="form-control"
                                    onValueChange={(value) => setMoney(value)}
                                />
                                )}
                            />
                        {errors.Money && 
                            <span className="text-danger font-size-13">{errors.Money.message}</span>
                        } 
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3">
                        <Form.Label className="text-black">{translated.Comment}</Form.Label>
                        <Form.Control 
                        {...register("Comment", {
                            maxLength : {
                            value: 500,
                            message: translatedForm.MaxLenghtString(500)
                            },
                            minLength : {
                            value: 5,
                            message: translatedForm.MinLengthString(2)
                            },
                        })}
                        type="text" maxLength="500" isInvalid={errors.Comment}  placeholder={translated.EnterComment} className="form-control"  />
                        {errors.Comment && 
                            <span className="text-danger font-size-13">{errors.Comment.message}</span>
                        }
                    </Form.Group>

                    <div className="form-group mt-3">
                        <button type="submit" disabled={loading} className="btn btn-primary">
                            {loading && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loading && <span>{translated.SaveChanges}</span>}
                            {!loading && <span>{translated.SaveChanges}</span>}
                        </button>
                    </div>
                </Form>        
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
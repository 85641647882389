import React, { useContext, useState, useEffect } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { Button, Offcanvas, Form, Badge, Spinner } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../boxicons.css';
import Select from 'react-select';
import api from '../../utils/axiosInterceptions';

export const CompanyFormAdd = ({translated, companyData, IsParent, setCompanyService ,addCompanyInList, setCountCompanies}) => {

const params = useParams();
const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
const [listTimezone, setListTimezone] = useState([])
const [loadingData, setLoadingData] = useState({
    button:false,
});

const onSubmit = data => {
    console.log(data)
    setLoadingData({button:true})
    api.post('/api/company/create', {
        Name: data.Name,
        INN: data.INN,
        KPP: data.KPP,
        OGRN: data.OGRN,
        Address: data.Address,
        MailAddress: data.MailAddress,
        IsParent: IsParent,
        CompanyServiceId: companyData === undefined ? 0 : companyData.id
    })
    .then(function (response) {
        console.log(response)
        setLoadingData({button:false})
        displayToast(response.data.message, response.data.isSuccess);

        if(IsParent) {
            addCompanyInList(response.data.listDatas);
            setCountCompanies(response.data.listDatas.length);
        } else {
            setCompanyService(response.data.listDatas)
        }

        reset({
            Name:'',
            INN:'',
            OGRN:'',
            KPP:'',
            Address:'',
            MailAddress:'',
        })
    })
    .catch(function (error) {
        console.log(error);
    });
}

const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
}

return (
    <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            
        <Form.Group className="mb-3 mt-3">
        <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("Name", {
            maxLength : {
                value: 500,
                message: translated.MaxLenghtString(500)
            },
            minLength : {
                value: 5,
                message: translated.MinLengthString(5)
            },
            required : translated.Required
            })}
            type="text" maxLength="500" isInvalid={errors.Name}  placeholder={translated.EnterName} className="form-control"  />
            {errors.Name && 
            <span className="text-danger font-size-13">{errors.Name.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3">
        <Form.Label className="text-black">{translated.TIN} <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("INN", {
            maxLength : {
                value: 12,
                message: translated.MaxNumber(12)
            },
            minLength : {
                value: 10,
                message: translated.MinNumber(10)
            },
            required : translated.Required
            })}
            type="number" maxLength="12" minLength="10" isInvalid={errors.INN}  
            placeholder={translated.EnterINN} className="form-control"  />
            {errors.INN && 
            <span className="text-danger font-size-13">{errors.INN.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3">
        <Form.Label className="text-black">{translated.OGRN}  <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("OGRN", {
            maxLength : {
                value: 15,
                message: translated.MaxNumber(15)
            },
            minLength : {
                value: 13,
                message: translated.MinNumber(13)
            },
            required : translated.Required
            })}
            type="number" maxLength="15" minLength="13" isInvalid={errors.OGRN} placeholder={translated.EnterOGRN}  className="form-control"  />
            {errors.OGRN && 
            <span className="text-danger font-size-13">{errors.OGRN.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3 mt-3">
        <Form.Label className="text-black">{translated.KPP} </Form.Label>
        <Form.Control 
            {...register("KPP", {
            minLength : {
                value: 9,
                message: translated.MaxLenghtString(9)
            },
            maxLength : {
                value: 9,
                message: translated.MinLengthString(9)
            },
            })}
            type="text" maxLength="9" isInvalid={errors.KPP}  placeholder={translated.EnterKPP}  className="form-control"  />
            {errors.KPP && 
            <span className="text-danger font-size-13">{errors.KPP.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3 mt-3">
        <Form.Label className="text-black">{translated.LegalAddress} <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("Address", {
            maxLength : {
                value: 800,
                message: translated.MaxLenghtString(800)
            },
            minLength : {
                value: 5,
                message: translated.MinLengthString(5)
            },
            required : translated.Required
            })}
            type="text" maxLength="800" isInvalid={errors.Address}  
            placeholder={translated.EnterLegalAddress} className="form-control"  />
            {errors.Address && 
            <span className="text-danger font-size-13">{errors.Address.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3 mt-3">
        <Form.Label className="text-black">{translated.MailAddress} <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("MailAddress", {
            maxLength : {
                value: 800,
                message: translated.MaxLenghtString(800)
            },
            minLength : {
                value: 5,
                message: translated.MinLengthString(5)
            },
            required : translated.Required
            })}
            type="text" maxLength="800" isInvalid={errors.MailAddress}  
            placeholder={translated.EnterMailAddress} className="form-control"  />
            {errors.MailAddress && 
            <span className="text-danger font-size-13">{errors.MailAddress.message}</span>
            }
        </Form.Group>
                
        <div className="form-group mt-3">
            <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                {loadingData.button && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingData.button && <span>{translated.AddCompanyProgress}</span>}
                {!loadingData.button && <span>{translated.AddCompany}</span>}
            </button>
        </div>
    
        </Form>
    </>
    )
}
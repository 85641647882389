import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Tooltip, OverlayTrigger, Form, Card, Alert, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import './../../boxicons.css';
import { HistoryLimits } from './historylimits';
import { LimitDayMonth } from './limitDayMonth';
import { LimitAbsolute } from './limitAbsolute';
import { LimitUnlimited } from './limitUnlimited';
import api from '../../utils/axiosInterceptions';

export const UserLimits = ({translated, translatedForm, permission, userData}) => {

    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [btnDayLoad, setBtnDayLoad] = useState(false)
    const [btnMonthLoad, setBtnMonthLoad] = useState(false)
    const [listLimits, setListLimits] = useState([])
    const [liters, setLiters] = useState(0)
    const [typeLimit, setTypeLimit] = useState(0)
    const [loadingDataGrid, setLoadingDataGrid] = useState(false)
    const [dayLimit, setDayLimit] = useState({
        limit:0,
        balance:0
    })
    const [monthLimit, setMonthLimit] = useState({
        limit: 0,
        balance:0
    });
    const [loadingDataBtn, setLoadingDataButton] = useState(false)
    const [currentActiveTab, setCurrentActiveTab] = useState('')

    const handleTabActive = data =>{
        setCurrentActiveTab(data)
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    }

    const changeTypeLimit = (typeLimitId) => {
        api.post('/api/users/details/change-type-limit', {
            Id: userData.id,
            EnumTypeLimitId:typeLimitId,
            withCredentials:true
        })
        .then(function (response) {
            setTypeLimit(response.data.dataResult.typeLimitId)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }

    const getEnumTypeLimits = (userTypeLimitId) => {
        api.get("/api/enum-type-limits/all", {
        })
        .then(function (response) {
            console.log(response)
            const addTypeLimit = response.data.listDatas.filter((i) => i.id == 5 || i.id == 3 || i.id == 4).map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setListLimits(addTypeLimit)

            let typeLimit = response.data.listDatas.filter((item) => item.id == userTypeLimitId)
            setValue("TypeLimit", {label:typeLimit[0].name, value:typeLimit[0].id})
        })
        .catch(function (error) {
            console.log(error);
        });    
    }

    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = () => {
        api.get("/api/users/details", {
          headers:{
            "content-type" : "application/json",
            "id":userData.id
          },
          params:{
            id: userData.id,
          }
        })
        .then(function (response) {
        console.log("user ssss")
          setTypeLimit(response.data.dataResult.typeLimitId)
          getEnumTypeLimits(response.data.dataResult.typeLimitId)
        })
        .catch(function (error) {
          console.log(error);
        });
      }

    return (
        <>
        <div className="row mt-4">
            <Tab.Container id="left-tabs-example" defaultActiveKey="limit-plan">

            <div className="col-xs-12 col-lg-3 col-md-3">
                <Nav onClick={(e) => handleTabActive(e.target.dataset.rrUiEventKey)} variant="pills" className="flex-column">
                    <Nav.Item>
                        <Nav.Link eventKey="limit-plan">
                            {translated.LimitPlan}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="history-limit">
                            {translated.HistoryChangeLimit}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            <div className="col-xs-12 col-lg-9 col-md-9">

                <Alert variant="warning">
                    {translated.MessageWarningUpdateLimits}
                </Alert>
                
                <Tab.Content>

                <Tab.Pane eventKey="limit-plan">            
                    <div className="row">

                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.TypeLimit}</Form.Label>
                            <Controller
                                name="TypeLimit"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field:{value} }) => (
                                <Select
                                    value={value}
                                    options={listLimits}                        
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        setValue("TypeLimit", {label:item.label, value:item.value})
                                        changeTypeLimit(item.value)
                                    }}
                                />
                                )}
                            />
                            {errors.TypeLimit && 
                                <span className="text-danger font-size-13">{errors.TypeLimit.message}</span>
                            }
                    </Form.Group>

                        {
                            typeLimit == 5
                            ? <LimitDayMonth translated={translated} translatedForm={translatedForm} />
                            : typeLimit == 3
                               ? <LimitAbsolute translated={translated} translatedForm={translatedForm} userData={userData} permission={permission}/>
                               : typeLimit == 4
                                 ? ''
                                 : null
                        }
                    
                    </div>
                 
                </Tab.Pane>

                <Tab.Pane eventKey="history-limit">
                    {
                        currentActiveTab == "history-limit" && (permission.read || permission.fullAccess)
                        ? <HistoryLimits translated={translated} translatedForm={translatedForm} />
                        : 
                        <Alert variant="danger">
                            {translated.PermissionDenied}
                        </Alert>
                    }
                </Tab.Pane>

                </Tab.Content>
            </div>

            </Tab.Container>
        </div>

    </>
    )
}
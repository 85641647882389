import React, { useContext, useEffect, useState, Redirect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Card, ProgressBar, Form, Offcanvas, Spinner} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Select from 'react-select';
import axios from 'axios';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const Stations = () => {

  const router = useHistory()
  const params = useParams();
  const [listStation, setListStation] = useState([])
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })

  const gridStyle = { minHeight: 550 }

  const stationsColumns = [
    { name: 'name', header: 'Название', minWidth: 50, defaultFlex: 1,
      render: ({ value, data }) => {  
        return data.station.name
      }
    },
    { name: 'description', header: 'Описание', minWidth: 50, defaultFlex: 1,
      render: ({ value, data }) => {  
        return data.description == null || data.description == "" ? "Не указано" : data.description
      } 
    },
    { name: 'company', header: 'Компания', minWidth: 50, defaultFlex: 1,

      render: ({ value, data }) => {  
          return data.station.company == null 
          ? "Не указано" 
          : 
          <Button variant="link" onClick={() => router.push(`/company/${data.station.company.id}`)}>
            {data.station.company.name}
          </Button> 
      }

    },
    { name: 'buttons', header: 'Действие', minWidth:5,
      render: ({ value, data }) => {  
        return <>
        <Button onClick={() => router.push(`/station/${data.station.id}`)} className="btn me-3 btn-light btn-sm">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </Button> 
        <Button onClick={() => onDelete(data)} className="btn btn-danger me-2 btn-sm">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.875 4.375L3.125 4.37504" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.125 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.875 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.625 4.375V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.125 4.375V3.125C13.125 2.79348 12.9933 2.47554 12.7589 2.24112C12.5245 2.0067 12.2065 1.875 11.875 1.875H8.125C7.79348 1.875 7.47554 2.0067 7.24112 2.24112C7.0067 2.47554 6.875 2.79348 6.875 3.125V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </Button> 
        </>
    } 
}
]

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  
  }

  const onDelete = data => {

    console.log(data)
    setLoadingData({grid:true})
    
    api.post('/api/transport/details/stations/delete', {
      SingleStationId: data.station.id,
      SingleTransportId: data.transport.id,
      withCredentials:true
    })
    .then(function (response) {
        setListStation(response.data.listDatas)
        setLoadingData({grid:false})
        displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({grid:false})
        displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  useEffect(() => {
    
    api.get("/api/transport/details/stations", {
      headers:{
        "content-type" : "application/json",
        "Id":params.id
      },
      params:{
        Id: params.id,
      }
    })
    .then(function (response) {
      console.log(response)
      setListStation(response.data.listDatas)
    })
    .catch(function (error) {
      console.log(error);
    });
    
  }, [])

    return(
      <>
        <Button bg="primary" className="mb-2 mt-2">
          <i className="bx bx-plus font-size-16 align-middle me-2 "></i> 
          Добавить станцию
        </Button>

    </>
    );
}
import React, { useContext, useEffect, useState, useMemo, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Form, Spinner, Offcanvas, DropdownButton, Dropdown} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import './../../boxicons.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateUser } from '../../lang/users/user';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';

export const SingleCrm = () => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, getValues, formState, formState: { errors }} = useForm();
    const [listCompany, setListCompany] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [rfidBtnLoad, setRfidBtnLoad] = useState(false)
    const [currentTabActive, setCurrentTabActive] = useState('')
    const [listHistoryAuth, setListHistoryAuth] = useState([])
    const [isExistDataAuth, setIsExistDataAuth] = useState(false)
    const [userHasLogin, setUserHasLogin] = useState(false)
    const [isAdminWebService, setIsAdminWebService] = useState(false)
    const [isDriver, setIsDriver] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [listRoles, setListRoles] = useState([])    
    const translated = useLittera(TranslateUser);
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();
    const [permission, setPermission] = useState({
      read: false,
      update: false,
      delete: false,
      fullAccess: false
    })
    const [selectedCompany, setSelectedCompany] = useState({
      label:'',
      value:0
    })
    const [selectedRole, setSelectedRole] = useState({
      label:'',
      value:0
    })
    const [selectedAuthAuto, setSelectedAuthAuto] = useState({
      label:'',
      value:0
    })
    const [selectedTypeAuth, setSelectedTypeAuth] = useState({
      label:'',
      value:0
    })
    const [typeAuth, setTypeAuth] = useState({
      rfid:false,
      password:false,
      rfid_password:false,
    })
    const [listTypeAuth, setListTypeAuth] = useState([])
    const [selectedAdminController, setSelectedAdminController] = useState({
      label:translated.No,
      value:false
    })
    const [selectedAdminFillTank, setSelectedAdminFillTank] = useState({
      label:translated.No,
      value:false
    })
    const [selectedAdminLevel, setSelectedAdminLevel] = useState({
      label:translated.No,
      value:false
    })
    const [selectedIsExistDataAuth, setSelectedIsExistDataAuth] = useState({
      label:translated.No,
      value:false
    })
    const [selectedAdminSirena, setSelectedAdminSirena] = useState({
      label:translated.No,
      value:false
    })
    const [FIO, setFIO] = useState('')
    const [userData, setUserData] = useState({})
    const [loadingData, setLoadingData] = useState({
      grid:false,
      button:false
    })
    
    const listDisable = [
      {label:translated.No, value:1 },
      {label:translated.Yes, value:0}
    ]

    const listBooleanAdminController = [
      {label:translated.No, value:1},
      {label:translated.Yes, value:0}
    ]

    const listBoolean = [
      {label:translated.No, value:0},
      {label:translated.Yes, value:1}
    ]

    const handleTabActive = data => {
      setCurrentTabActive(data)
    }

    const columns = useMemo(
      () => [
        {
          accessorFn: (row) => row.ipAddress,
          accessorKey: 'ipAddress',
          header: translated.IPAddress
        },
        {
          accessorFn: (row) => row.userAgent,
          accessorKey: 'userAgent',
          header: 'User-Agent',
        },
        {
          accessorFn: (row) => moment(row.dateAuth).format("DD.MM.YYYY hh:mm:ss"),
          accessorKey: 'dateAuth',
          header: translated.DateAuth,
        }
      ],
      [],
    );

    useEffect(() => {
      methods.setLocale(CurrentSelectLanguage);
    }, [])


    return(
      isAuth ?
      <>
        <div className=" d-sm-flex align-items-center justify-content-between">
          <h2 className="mb-sm-0 fw-semibold font-size-18 benzamat-font-bold text-black">
              <NavLink to="/users">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.125 13.8125L3.25 8.9375L8.125 4.0625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.125 20.3125H17.0625C18.5709 20.3125 20.0176 19.7133 21.0842 18.6467C22.1508 17.5801 22.75 16.1334 22.75 14.625V14.625C22.75 13.8781 22.6029 13.1385 22.3171 12.4485C22.0312 11.7584 21.6123 11.1315 21.0842 10.6033C20.556 10.0752 19.9291 9.65626 19.239 9.37043C18.549 9.08461 17.8094 8.9375 17.0625 8.9375H3.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </NavLink> 
              Request No 5
          </h2>
    </div>
      
    <div className="row">
      
      <Tabs defaultActiveKey="maininfo" onClick={(e) => handleTabActive(e.target.dataset.rrUiEventKey)}  id="uncontrolled-tab-example" className="mt-1 nav-tabs-custom">

        <Tab eventKey="maininfo" title="General" tabClassName="font-size-15">
          <div className="row mt-4">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <div className="col-xs-12 col-lg-12 col-md-12">
                <DropdownButton variant="success" title="Actions with request" className="mb-3">
                    <Dropdown.Item>Download to PDF</Dropdown.Item>
                    <Dropdown.Item>Send to Nayara's manager email</Dropdown.Item>
                    <Dropdown.Item>Send pay document to client</Dropdown.Item>
                </DropdownButton>
            </div>

            <div className="col-xs-12 col-lg-6 col-md-6">
            <Form.Group className="mb-3">
            <Form.Label className="text-black">Customer <sup className="text-danger">*</sup></Form.Label>        
                <Controller
                    name="Controllers"                                
                    control={control}
                    rules={{ required: translatedForm.Required }}
                    render={({ field }) => (
                    <Select 
                    {...field}                               
                        isSearchable
                        isMulti
                        options={[]}                        
                        placeholder={translatedForm.SelectFromList}
                        classNamePrefix="select"
                    />
                    )}
                />
            {errors.Controllers && 
                <span className="text-danger font-size-13">{errors.Controllers.message}</span>
            }   
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label className="text-black">benzamatPro manager <sup className="text-danger">*</sup></Form.Label>        
                <Controller
                    name="Controllers"                                
                    control={control}
                    rules={{ required: translatedForm.Required }}
                    render={({ field }) => (
                    <Select 
                    {...field}                               
                        isSearchable
                        isMulti
                        options={[]}                        
                        placeholder={translatedForm.SelectFromList}
                        classNamePrefix="select"
                    />
                    )}
                />
            {errors.Controllers && 
                <span className="text-danger font-size-13">{errors.Controllers.message}</span>
            }   
        </Form.Group>

        <Form.Group className="mb-3">
        <Form.Label className="text-black">Sold to Party / CMS code <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("Name", {
            minLength : {
                value: 5,
                message: translatedForm.MinLengthString(5)
            },
            maxLength : {
                value: 100,
                message: translatedForm.MaxLenghtString(100)
            },
            required: translatedForm.Required
            })}
            type="text" maxLength="100" isInvalid={errors.Name}  
            placeholder={translatedForm.EnterValue(100)} className="form-control"  />
            {errors.Name && 
                <span className="text-danger font-size-13">{errors.Name.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3">
        <Form.Label className="text-black">Ship to address <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("Name", {
            minLength : {
                value: 5,
                message: translatedForm.MinLengthString(5)
            },
            maxLength : {
                value: 100,
                message: translatedForm.MaxLenghtString(100)
            },
            required: translatedForm.Required
            })}
            type="text" maxLength="100" isInvalid={errors.Name}  
            placeholder={translatedForm.EnterValue(100)} className="form-control"  />
            {errors.Name && 
                <span className="text-danger font-size-13">{errors.Name.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3">
        <Form.Label className="text-black">Quantity <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("Name", {
            minLength : {
                value: 5,
                message: translatedForm.MinLengthString(5)
            },
            maxLength : {
                value: 100,
                message: translatedForm.MaxLenghtString(100)
            },
            required: translatedForm.Required
            })}
            type="text" maxLength="100" isInvalid={errors.Name}  
            placeholder={translatedForm.EnterValue(100)} className="form-control"  />
            {errors.Name && 
                <span className="text-danger font-size-13">{errors.Name.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3">
        <Form.Label className="text-black">Transporter's name <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("Name", {
            minLength : {
                value: 5,
                message: translatedForm.MinLengthString(5)
            },
            maxLength : {
                value: 100,
                message: translatedForm.MaxLenghtString(100)
            },
            required: translatedForm.Required
            })}
            type="text" maxLength="100" isInvalid={errors.Name}  
            placeholder={translatedForm.EnterValue(100)} className="form-control"  />
            {errors.Name && 
                <span className="text-danger font-size-13">{errors.Name.message}</span>
            }
        </Form.Group>

        <Button type="button" className="mb-2" variant="primary">
            Save changes
        </Button>

            </div>

            <div className="col-xs-12 col-lg-6 col-md-6">
                
            <Form.Group className="mb-3">
            <Form.Label className="text-black">Vehicle Registration <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Name of Driver <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Nayara id no if any <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">License numberof Driver <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Adhar number of Driver <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Name of co-driver / Cleaner <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="text-black">Product <sup className="text-danger">*</sup></Form.Label>        
                    <Controller
                        name="Controllers"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field }) => (
                        <Select 
                        {...field}                               
                            isSearchable
                            isMulti
                            options={[]}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                        />
                        )}
                    />
                {errors.Controllers && 
                    <span className="text-danger font-size-13">{errors.Controllers.message}</span>
                }   
            </Form.Group>
            </div>

            </Tab.Container>
          </div>
        </Tab>
   
        <Tab eventKey="history_request" title="Changes history" tabClassName="font-size-15">
          
        </Tab>

      </Tabs>
    </div>
    </>
    :
    <Redirect to='/login'/>
    );
}
import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateReport = {

    Export: {
        en_US: "Export to",
        ru_RU: "Экспорт"
    },
    Company: {
        en_US: "Company",
        ru_RU: "Компания"
    },
    Users:{
        en_US: "User",
        ru_RU: "Пользователь"  
    },
    Day:{
        en_US: "Day",
        ru_RU: "День"
    },
    Month:{
        en_US: "Month",
        ru_RU: "Месяц"
    },
    StartDate:{
        en_US: "Start date",
        ru_RU: "Начальная дата"
    },
    ChangeDate:{
        en_US: "Change date",
        ru_RU: "Дата изменения"
    },
    
    EndDate:{
        en_US: "End date",
        ru_RU: "Конечная дата"
    },
    GenerateReport:{
        en_US: "Generate report",
        ru_RU: "Сформировать"
    },
    MainLiters:{
        en_US: "Total number of fillings by date",
        ru_RU: "Общее количество заправок по датам"
    },
    ByFuelType:{
        en_US: "By fuel type",
        ru_RU: "По типу топлива"
    },
    Fueled:{
        en_US: "Fueled",
        ru_RU: "Заправлено"
    },
    Analytics:{
        en_US: "Analytics",
        ru_RU: "Аналитика"
    },
    LastFillings:{
        en_US: "Last fillings",
        ru_RU: "Последние заправки"
    },
    NotFound:{
        en_US: "Not found",
        ru_RU: "Данные не найдены"
    },
    User:{
        en_US: "Users",
        ru_RU: "Пользователи"
    },
    Transport:{
        en_US: "Transport",
        ru_RU: "Машина"
    },
    Fuel:{
        en_US: "Fuel",
        ru_RU: "Топливо"
    },
    Date:{
        en_US: "Date filling",
        ru_RU: "Дата налива"
    },
    DateDefault:{
        en_US: "Date",
        ru_RU: "Дата"
    },
    Liters:{
        en_US: "Liters",
        ru_RU: "Литры"
    },
    Density:{
        en_US: "Density, kg/dm3",
        ru_RU: "Плотность, кг/дм3"
    },
    Mass:{
        en_US: "Mass, kg",
        ru_RU: "Масса, кг"
    },
    Price:{
        en_US: "Price",
        ru_RU: "Цена"
    },
    Sum:{
        en_US: "Sum",
        ru_RU: "Стоимость"
    },
    Controller:{
        en_US: "Controller (TRK)",
        ru_RU: "Контроллер (ТРК)"
    },
    ReadingLevelMeter:{
        en_US: "Readings level meter",
        ru_RU: "Показания уровнемеров"
    },
    ReportAllMessages:{
        en_US: "Log of all messages",
        ru_RU: "Журнал всех сообщений"
    },
    PermissionDenied:{
        en_US: "Permission denied",
        ru_RU: "У вас недостаточно прав доступа для просмотра информации по данному разделу"
    },
    ReportChangePrice:{
        en_US: "Change prices",
        ru_RU: "Изменение цен"
    },
    ReportCashDymanics:{
        en_US: "Cash dynamics",
        ru_RU: "Приход и расход денег"
    },
    Balance:{
        en_US: "Balance",
        ru_RU: "Остаток"
    },
    UsedUp:{
        en_US: "Used",
        ru_RU: "Израсходовано"
    },
    Final:{
        en_US: "Total",
        ru_RU: "Итого"
    },
    Received:{
        en_US: "Received",
        ru_RU: "Поступило"
    },
    ReportGsmConnect:{
        en_US: "GSM operation log",
        ru_RU: "Журнал качества связи"
    },
    Error:{
        en_US: "Error",
        ru_RU: "Ошибка"
    },
    Time:{
        en_US: "Check time",
        ru_RU: "Время проверки"
    },
    Value:{
        en_US: "Value",
        ru_RU: "Значение" 
    },
    ReportUserLogs:{
        en_US: "Log of all user's actions",
        ru_RU: "Журнал действий пользователей" 
    },
    IP:{
        en_US: "IP address",
        ru_RU: "IP адрес"  
    },
    Action:{
        en_US: "Action",
        ru_RU: "Действие"  
    },
    ReportFuleReceiptController:{
        en_US: "Fuel reception by controller",
        ru_RU: "Поступление топлива на контроллере"  
    },
    Mernik:{
        en_US: "Mernik",
        ru_RU: "Мерник"
    },
    FilterReport:{
        en_US: "Report's filter",
        ru_RU: "Фильтр отчета"
    },
    DataNotLoad:{
        en_US: "The data has not yet been loaded. Select data in the filter for uploading",
        ru_RU: "Данные еще не загружены. Выберите данные в фильтре для выгрузки"
    },
    SelectAllUsers:{
        en_US: "Select all users",
        ru_RU: "Выбрать всех пользователей"
    },
    SelectAllTransports:{
        en_US: "Select all transports",
        ru_RU: "Выбрать все ТС"
    },
    SelectAllControllers:{
        en_US: "Select all controllers",
        ru_RU: "Выбрать все контроллеры"
    },
    Fill:{
        en_US: "Fill",
        ru_RU: "Заправка"
    },
    FinalPeriod:{
        en_US: "Total for period",
        ru_RU: "Итого за период"
    },
    ReportHatchOpenClose:{
        en_US: "Tank lid sensor readings",
        ru_RU: "Показания датчика крышки резервуара"
    },
    Event:{
        en_US: "Event",
        ru_RU: "Событие"
    },
    Message:{
        en_US: "Message",
        ru_RU: "Сообщение"
    },
    Open:{
        en_US: "Open",
        ru_RU: "Открыта"
    },
    Close:{
        en_US: "Close",
        ru_RU: "Закрыта"
    },
    StartWork:{
        en_US: "Start work",
        ru_RU: "Начало работы"
    },
    ReportFuelReleaseByController:{
        en_US: "Fuel release by controllers",
        ru_RU: "Отпуск ГСМ по контроллерам"
    },
    ReportFuelReleaseByMass:{
        en_US: "Fuel release by mass",
        ru_RU: "Отпуск ГСМ по массе"
    },
    ReportFuelReleaseByControllerNotGroup:{
        en_US: "Fuel release by controllers without group",
        ru_RU: "Отпуск ГСМ по контроллерам (без группировки)"
    },
    Description:{
        en_US: "Description",
        ru_RU: "Описание"
    },
    ReportFuelReleaseByUsers:{
        en_US: "Fuel release by users",
        ru_RU: "Отпуск ГСМ по пользователям"
    },
    TotalMoneys:{
        en_US: "Total moneys",
        ru_RU: "Итого денег"
    },
    ReportTotalFuelController:{
        en_US: "Total fuel release by controllers",
        ru_RU: "Суммарный отпуск ГСМ по контроллерам"
    },
    Grouping:{
        en_US: "Grouping",
        ru_RU: "Группировка"
    },
    ReportTotalFuelUsers:{
        en_US: "Total fuel release by users",
        ru_RU: "Суммарный отпуск ГСМ по пользователям"
    },
    ReportTotalFuelTransports:{
        en_US: "Total fuel release by transports",
        ru_RU: "Суммарный отпуск ГСМ по транспорту"
    },
    ReportTotalFuelCompanies:{
        en_US: "Total fuel release by companies",
        ru_RU: "Суммарный отпуск ГСМ по компаниям"
    },
    MessageInController:{
        en_US: "In controller",
        ru_RU: "Сообщение на контроллере"
    }
};

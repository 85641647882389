import React from "react";
import { useLittera } from "@assembless/react-littera";

export const FormLocale = {

    MaxLenghtString: (number) => ({
        en_US: `Max length is ${number} symbols`,
        ru_RU: `Максимальная длина ${number} символов`
    }),
    MinLengthString: (number) => ({
        en_US: `Min length is ${number} symbols`,
        ru_RU: `Минимальная длина ${number} символов`
    }),
    MaxNumber: (number) => ({
        en_US: `Max value is ${number}`,
        ru_RU: `Максимальное значение ${number}`
    }),
    MinNumber: (number) => ({
        en_US: `Min value is ${number}`,
        ru_RU: `Минимальное значение ${number}`
    }),
    EnterValue: (number) => ({
        en_US: `Enter number until ${number} symbols`,
        ru_RU: `Введите значение до ${number} символом`
    }),
    Required:{
        en_US: "Required",
        ru_RU: "Обязательное поле"
    },
    SelectFromList:{
        en_US: "Select value from list",
        ru_RU: "Выберите значение"
    },
    SaveChanges:{
        en_US: "Save changes",
        ru_RU: "Сохранить"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    NotFound:{
        en_US: "Not found",
        ru_RU: "Данные не найдены"
    },
    EnterSingleValue:{
        en_US: "Enter value",
        ru_RU: "Введите значение"
    },
    GetDataLevelMeterSuccess:{
        en_US: "Level meter data received successfully",
        ru_RU: "Данные по уровнемеру успешно получены"
    },
    NotGetSections:{
        en_US: "No gauge has yet been added to the selected station section",
        ru_RU: "К выбранной секции станции еще не добавлен уровнемер"
    }
};

import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom'
import { Button, Offcanvas, Badge, Alert, Form, Spinner } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { CompanyFormAdd } from './CompanyFormAdd';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import './../../boxicons.css';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateCompanyModule } from '../../lang/company';
import { CurrentSelectLanguage } from '../../helpers/language';

export const CompanyApi = ({translated}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [loadingDataBtn, setLoadingDataButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [permission, setPermission] = useState({
        read:false,
        create:false,
        fullAccess:false
    })

    useEffect(() => {
        getApiData()
    }, []);

    const getApiData = () => {
        axios({
            method: 'get',
            url: `api/company/details/api-key`,
            headers:{
                "content-type" : "application/json",
                "companyId":params.id
            },
            params:{
                companyId: params.id,
            },
            withCrenditials:true
        })
        .then(function (response) {
            console.log("api")
            console.log(response)
            setPermission({
            read: response.data.read,
            update:response.data.update,
            delete:response.data.delete,
            create: response.data.create,
            fullAccess: response.data.fullAccess
            })
            setValue("Key", response.data.dataResult)
            setIsLoading(false)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });

        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    const onSubmit = data => {
        setLoadingDataButton(true)
        console.log(params.id)
        axios.post('/api/company/details/api-key/update', {
            Id: 15,
        })
        .then(function (response) {
            setLoadingDataButton(false)
            displayToast(response.data.message, response.data.isSuccess)
            setValue("Key", response.data.dataResult)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingDataButton(false)
        });
    }

    return (
        isAuth ?
        <>
        {
            isLoading
            ? <Spinner animation="border" variant="info" />
            :
            <>
                <div className="row">
                    <div className="col-xs-12 col-lg-12 col-md-12 ">         
                        <div>
                            {
                            permission.read || permission.fullAccess
                            ?               
                            <>
                                <Form onSubmit={handleSubmit(onSubmit)}>

                                <Form.Control {...register("Id")} type="hidden"  />

                                <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">Api Key</Form.Label>
                                <Form.Control 
                                    {...register("Key")} type="text" placeholder="API-KEY" className="form-control"  />
                                </Form.Group>

                                {
                                    permission.update || permission.fullAccess
                                    ?
                                    <div className="form-group mt-3">
                                        <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                                        {loadingDataBtn && (
                                            <Spinner animation="border" variant="light" 
                                                as="span"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {loadingDataBtn && <span>{translated.ChangeApiButton}</span>}
                                        {!loadingDataBtn && <span>{translated.ChangeApiButton}</span>}
                                        </button>
                                    </div>
                                    : null
                                }           
                                
                                </Form>
                            </>
                            : 
                            <Alert variant='danger'>{translated.AccessDenied}</Alert>
                            }
                        </div>
                    </div>
                </div>
            </>
        }
        </>
        :
        <Redirect to='/login'/>
    );

}
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Offcanvas, Form, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import moment from 'moment';
import axios from 'axios';
import Select from 'react-select';
import './../../../boxicons.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru'; 
import CurrencyInput from 'react-currency-input-field';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import api from '../../../utils/axiosInterceptions';

export const FillingPlan = () => {
    
    const params = useParams()
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false);
    const [listHistory, setListHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [listFuels, setFuels] = useState([])
    const [money, setMoney] = useState(0)
    const [header, setHeader] = useState('')
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedCompanyFuel, setSelectedCompanyFuel] = useState({
        label:'Выберите значение из списка',
        value:0
    })
    const [permission, setPermission] = useState({
        read: false,
        create:false,
        update:false,
        fullAccess:false
    })
    const handleShow = (header) => {
        setShowFormAdd(true)
        setHeader(header)
        setValue("CountLiters", 0)
        setValue("DateStart", new Date())
        setIsUpdate(false)
    };
    const handleClose = () => setShowFormAdd(false);

    useEffect(() => {
        getFillingPlan()
        getFuels()
    }, [])

    const getFuels = () => {
        api.get('/api/company/fuels', {
            headers:{
                "content-type" : "application/json",
                "companyId":params.id
            },
            params:{
                companyId: params.id,
            },
            withCredentials:true
        })
        .then(function(response){
            console.log("fuels company")
            console.log(response);

            const addFuel = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: `${item.fuelName} - ${item.price} руб/л`
            }))
            
            setFuels(addFuel)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getFillingPlan = () => {
        api.get("/api/company/details/filling-plan", {
            headers:{
              "content-type" : "application/json",
              "companyId":params.id
            },
            params:{
              companyId: params.id,
            }
        })
          .then(function (response) {
            console.log("company filling plan")
            console.log(response)
            setListHistory(response.data.listDatas)
            setPermission({
                read: response.data.read,
                create: response.data.create,
                update: response.data.update,
                fullAccess: response.data.fullAccess
            })
        })
          .catch(function (error) {
            console.log(error);
        });
    }
    
    const handleUpdate = (id, header) => {
        setShowFormAdd(true)
        setHeader(header)
        setIsUpdate(true)
        api.get("/api/company/details/filling-plan/details", {
            headers:{
              "content-type": "application/json",
              "Id":id
            },
            params:{
              Id: id,
            }
        })
        .then(function (response) {
            console.log("update plan")
            console.log(response)
            setValue("CountLiters", response.data.dataResult.countLiters)
            setValue("DateStart", response.data.dataResult.dateStart)
            setValue("Id", response.data.dataResult.id)
            let fuel = listFuels.filter((item) => item.value === response.data.dataResult.fuelId)
            setValue("Fuel", fuel[0])
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const onSubmit = data => {
        console.log("submit")
        console.log(selectedCompanyFuel)
        setLoading(true)
        let link = isUpdate ? "/api/company/details/filling-plan/update" : "/api/company/details/filling-plan/create"
        api.post(link, {
            Id: isUpdate ? data.Id : 0,
            CountLiters: data.CountLiters,
            CompanyFuelId: selectedCompanyFuel.value,
            CompanyId: params.id,
            DateStart: data.DateStart
        })
        .then(function (response) {
            console.log(response)
            displayToast(response.data.message, response.data.isSuccess)
            setLoading(false)
            setListHistory(response.data.listDatas)
            reset({
                CountLiters:''
            })
        })
        .catch(function (error) {
            console.log(error);
            setLoading(false)
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }

    const displayToast = (message, status) => {

        if(status) {
            toast.success(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        } else {
            toast.error(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        }
    }

    const columns = useMemo(
        () => [
          {
            accessorFn: (row) => row.countLiters,
            id: 'countLitres',
            header: 'Литры',
          },
          {
            accessorFn: (row) => row.fuelName,
            accessorKey: 'fuelName', //normal accessorKey
            header: 'Топливо',
          },
          {
            accessorFn: (row) => moment(row.dateStart).format("DD.MM.YYYY HH:mm"),
            accessorKey: 'dateStart', //normal accessorKey
            header: 'Дата начала',
          },
          {
            accessorFn: (row) => 
            <>
            <Button onClick={() => handleUpdate(row.id, "Изменение плана")} className="btn btn-light btn-sm">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.45938 16.8349L3.16406 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </Button>
            </>,
            accessorKey: 'button',
            header: 'Действия',
          },
        ],
    [],
    );
    
    return (
        <>
            {
                permission.create || permission.fullAccess
                ?
                <Button bg="primary" onClick={(() => handleShow("Добавление плана"))}>
                    <i className="bx bx-plus font-size-16 align-middle me-2 "></i> 
                    Добавить запись
                </Button>
                : null
            }

            {
                permission.read || permission.fullAccess
                ?
                <div className="mt-2">
                    {/*<DataTable showGridlines size="small" stripedRows lazy value={listHistory} responsiveLayout="scroll">
                        <Column field="countLiters" header="Литры"></Column>
                        <Column field="fuelName" header="Топливо"></Column>
                        <Column field="dateStart" header="Дата начала" body={dateCreated}></Column>
                        <Column field="button" header="Действие" body={buttons}></Column>
                    </DataTable>*/}
                <MaterialReactTable 
                    columns={columns} 
                    data={listHistory}
                    localization={MRT_Localization_RU}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                      elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                  />
                </div>
                : null
            }

            <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                        {header}
                    </Offcanvas.Title>
                </Offcanvas.Header>

                <div className="dropdown-divider"></div>
                <Offcanvas.Body>  

                <h4 className="text-black">Основная информация</h4>
                <Form onSubmit={handleSubmit(onSubmit)}> 
                <Form.Control {...register("Id")} type="hidden"  />

                <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">Количество литров <sup className="text-danger">*</sup></Form.Label>
                    <Controller
                        name="CountLiters"                                
                        control={control}
                        render={({ field:{value} }) => (
                        <CurrencyInput
                            placeholder="Введите литры"
                            value={value}
                            decimalsLimit={2}
                            suffix=" л."
                            className="form-control"
                            onValueChange={(value) => setValue("CountLiters", value)}
                        />
                        )}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label className="text-black">Вид топлива <sup className="text-danger">*</sup></Form.Label>
                
                    <Controller
                        name="Fuel"                                
                        control={control}
                        rules={{ required: "Обязательное поле" }}
                        render={({ field }) => (
                        <Select                          
                            isSearchable
                            value={selectedCompanyFuel}
                            options={listFuels}                        
                            placeholder="Выберите значение из списка"
                            classNamePrefix="select"
                            onChange={(item) => {
                                setValue("Fuel", item)
                                setSelectedCompanyFuel({label:item.label, value:item.value})
                            }}
                        />
                        )}
                    />
                    {errors.Fuel && 
                        <span className="text-danger font-size-13">{errors.Fuel.message}</span>
                    }
                </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">Дата начала <sup className="text-danger">*</sup></Form.Label>
                        <Controller
                            name="DateStart"                                
                            control={control}
                            rules={{required:"Обязательное поле"}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y H:i",
                                    locale: Russian
                                }}
                                value={value}
                                onChange={([date]) => {
                                    setValue("DateStart", date)
                                }}
                            />
                            )}
                        />
                        {errors.DateStart && 
                            <span className="text-danger font-size-13">{errors.DateStart.message}</span>
                        }
                    </Form.Group>


                    <div className="form-group mt-3">
                        <button type="submit" disabled={loading} className="btn btn-primary">
                            {loading && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loading && <span>{isUpdate ? "Обновление" : "Добавление"}</span>}
                            {!loading && <span>{isUpdate ? "Обновить" : "Добавить"}</span>}
                        </button>
                    </div>
                </Form>        
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const Auth = ({translated, translatedForm}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();

    const [loadingBtn, setLoadingBtn] = useState(false)

  useEffect(() => {
    api.get("/api/controllers/details/auth", {
      headers:{
        "content-type" : "application/json",
        "controllerId":params.id
      },
      params:{
        controllerId: params.id,
      },
      withCredentials:true
    })
    .then(function (response) {
      setValue("Login", response.data.dataResult.login)
      setValue("Password", response.data.dataResult.password)
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [])

  const onSubmit = data => {

    setLoadingBtn(true);
    api.post("/api/controllers/details/auth/update", {
        Id: params.id,
        Login: data.Login,
        Password: data.Password,
    })
    .then(function (response) {
      setLoadingBtn(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      setLoadingBtn(false)
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-5 col-md-5">

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Login}</Form.Label>
                  <Form.Control 
                      {...register("Login")}
                      type="text" maxLength="100" isInvalid={errors.Login} 
                      placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                      {errors.Login && 
                      <span className="text-danger font-size-13">{errors.Login.message}</span>
                      }
                </Form.Group>    

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Password}</Form.Label>
                  <Form.Control 
                      {...register("Password")}
                      type="text" maxLength="100" isInvalid={errors.Password} 
                      placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                      {errors.Password && 
                      <span className="text-danger font-size-13">{errors.Password.message}</span>
                      }
                </Form.Group>      

                <div className="form-group mt-3">
                    <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                        {loadingBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                    </button>
                  </div>
                </div>

            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}
import React, { useContext, useEffect, useMemo, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Tooltip, Badge, OverlayTrigger, Form, Card, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { Stations } from './Stations';
import { TransportLimits } from './limits';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import Chart from "react-apexcharts";
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import './../../boxicons.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import StatusConnect01 from "../../img/image_connect/StatusConnect-01.png";
import StatusConnect02 from "../../img/image_connect/StatusConnect-02.png";
import StatusConnect03 from "../../img/image_connect/StatusConnect-03.png";
import StatusConnect04 from "../../img/image_connect/StatusConnect-04.png";
import StatusConnect05 from "../../img/image_connect/StatusConnect-05.png";
import StatusConnect06 from "../../img/image_connect/StatusConnect-06.png";
import StatusConnect07 from "../../img/image_connect/StatusConnect-07.png";
import StatusConnect08 from "../../img/image_connect/StatusConnect-08.png";
import StatusConnect09 from "../../img/image_connect/StatusConnect_09.png";
import StatusConnect009 from "../../img/image_connect/StatusConnect-09.png";
import StatusConnect10 from "../../img/image_connect/StatusConnect-10.png"; 
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateTransport } from '../../lang/transports/translateTransport';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import { translate } from 'react-translate';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import api from '../../utils/axiosInterceptions';
import InputMask from 'react-input-mask';

const TransportSingle = () => {

  const router = useHistory()
  const params = useParams();
  const {isAuth} = useContext(AuthContext);
  const translated = useLittera(TranslateTransport);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [rfidBtnLoad, setRfidBtnLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [transportData, setTransportData] = useState({})
  const { control, register, handleSubmit, reset, setValue, getValues, formState, formState: { errors }} = useForm();
  const [transportName, setTransportName] = useState('');
  const [typeLimit, setTypeLimit] = useState(0)
  const [isDeleted, setIsDeleted] = useState(false)
  const [currentTabActive, setCurrentTabActive] = useState('')
  const [selectedTypeAuth, setSelectedTypeAuth] = useState({
    label:'',
    value:0
  })
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    update:false,
    fullAccess:false
  })
  const [currentTime, setCurrentTime] = useState(0)
  const [listCompany, setListCompany] = useState({});
  const [controllers, setControllers] = useState([])
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:''
  })
  const [typeAuth, setTypeAuth] = useState({
    rfid:false,
    password:false,
    rfid_password:false,
  })
  const [listTypeAuth, setListTypeAuth] = useState({
    label:'',
    value:0
  })
  const [loadingDataBtn, setLoadingDataButton] = useState(false)
  const listAuth = [
    {label:translated.NumberRfid, value:1},
    {label:translated.Password, value:2},
    {label:translated.RfidWithPassword, value:3},
  ]
  const formatChars = {
    '*': '[A-Fa-f0-9]'
  }
  const onSubmit = data => {
    console.log(data)
    setLoadingDataButton(true)
    api.post('/api/transport/update', {
      Id: data.Id,
      Name: data.Name,
      Number:data.Number,
      CompanyId: data.Company.value,
      Model: data.Model,
      Contract: data.Contract,
      Password: data.Password,
      NumberRfid: data.NumberRfid,
      ServiceDate: data.ServiceDate,
      EnumTypeAuthId: selectedTypeAuth.value,
      withCredentials:true
    })
    .then(function (response) {
      setLoadingDataButton(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      /*error.response.data.errors.map((item) => {
        displayToast(item.errorMessage, error.response.data.isSuccess)
      })*/
      displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
      setLoadingDataButton(false)
    });
  }

  const deleteTC = () => {
    let id = getValues("Id")
    api.post('/api/transport/details/delete', {
      Id: id,
      IsBlocked: isDeleted ? false : true,
      withCredentials:true
    })
    .then(function (response) {
      console.log(response)
      setIsDeleted(response.data.dataResult.isDeleted)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      error.response.data.errors.map((item) => {
        displayToast(item.errorMessage, error.response.data.isSuccess)
      })
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });

    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
  
  }

  const handleTabActive = data => {
    setCurrentTabActive(data)
  }

  const getControllers = () => {
    api.get("/api/transport/details/controllers", {
      headers:{
        "content-type" : "application/json",
        "transportId":params.id
      },
      params:{
        transportId: params.id,
      }
    })
    .then(function (response) {

      const addController = response.data.listDatas.map(item => ({
        ...item,
        value: item.id,
        label: item.connectId
      }))
      setControllers(addController)
    })
    .catch(function (error) {
    });
  }

  const getTypeAuth = () => {
    api.get('/api/type-auths/all', {
      withCredentials:true
    })
    .then(function (response) {
      const add = response.data.listDatas.filter((i) => i.id == 1).map(item => ({
          ...item,
          value: item.id,
          label: item.name
      }))
      setListTypeAuth(add)

    })
    .catch(function (error) {
    });
  }

  const getStations = () => {
    api.get("/api/stations/all", {
    })
    .then(function (response) {
  
      const addCompany = response.data.listDatas.map(item => ({
        ...item,
        value: item.id,
        label: item.name
      }))
      setListCompany(addCompany)

    })
    .catch(function (error) {

    });
  }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    setIsLoading(true)
    getControllers()
    getStations()
    getTypeAuth()

    api.get("/api/transport/details", {
      headers:{
        "content-type" : "application/json",
        "id":params.id
      },
      params:{
        id: params.id,
      }
    })
    .then(function (response) {
      console.log(response)
      setTransportData(response.data)
      setTransportName(response.data.dataResult.name)
      setValue("Name", response.data.dataResult.name)
      setValue("Model", response.data.dataResult.model)
      setValue("Number", response.data.dataResult.number)
      setValue("Contract", response.data.dataResult.contract)
      setValue("Password", response.data.dataResult.password)
      setValue("NumberRfid", response.data.dataResult.numberRfid)
      setValue("Id", response.data.dataResult.id)
      setValue("ServiceDate", response.data.dataResult.serviceDate)
      setValue("Company", {label:response.data.dataResult.company.name, value:response.data.dataResult.company.id})
      setTypeLimit(response.data.dataResult.enumTypeLimit.id)
      setIsDeleted(response.data.dataResult.isDeleted)

      setPermission({
        read: response.data.read,
        update: response.data.update,
        fullAccess: response.data.fullAccess
      })

      let enumTypeAuth = response.data.dataResult.enumTypeAuth.id
      let findAuth = listAuth.filter((item) => item.value == enumTypeAuth)
      setSelectedTypeAuth({label:findAuth[0].label, value:findAuth[0].value})
      setValue("TypeAuth", selectedTypeAuth)

      if(enumTypeAuth == 1) {
        setTypeAuth({rfid:true, password:false, rfid_password:false})
        setValue("NumberRfid", response.data.dataResult.rfidKey)
      } else if(enumTypeAuth == 2) {
        setTypeAuth({rfid:false, password:true, rfid_password:false })
        setValue("Password", response.data.dataResult.password)
      } else {
        setTypeAuth({rfid:false, password:false, rfid_password:true })
        setValue("Password", response.data.dataResult.password)
        setValue("NumberRfid", response.data.dataResult.rfidKey)
      }
    
      setCurrentTime(moment().format("DD.MM.YYYY"))
      setIsLoading(false)

    })
    .catch(function (error) {

    });
  }, [])

  const getLastCard = () => {
    setRfidBtnLoad(true)
    api.get("/api/users/details/cards/last", {
      withCredentials:true
    })
    .then(function (response) {
      console.log(response)
      setValue("NumberRfid", response.data.dataResult)
      setRfidBtnLoad(false)
    })
    .catch(function (error) {
      console.log(error);
      setRfidBtnLoad(false)
    }); 
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: translated.Name,
      },
      {
        accessorFn: (row) => row.connectId,
        id: 'connectId',
        header: 'ID',
      },
      {
        accessorFn: (row) => row.firstConfigRead != null 
          ? <Badge bg="success" className="font-size-13">{translated.Readed}</Badge> 
          : <Badge bg="danger" className="font-size-13">{translated.NotReaded}</Badge>,
        accessorKey: 'firstConfigRead', //normal accessorKey
        header: translated.Settings,
      },
      {
        accessorFn: (row) => row.doScanning == 1 
          ? <span className="badge bg-success font-size-12">{translated.Enable}</span> 
          : <span className="badge bg-danger font-size-12">{translated.Disable}</span>,
        accessorKey: 'doScanning',
        header: translated.AutoRead,
      },
      {
        accessorFn: (row) => row.versionSoftware != null && row.versionSoftware != "" ? row.versionSoftware : "Не указано",
        accessorKey: 'versionSoftware',
        header: translated.VersionSoftware,
      },
      {
        accessorFn: (row) => {

          if(row.statusConnect == -1 || row.statusConnect == -2 || row.statusConnect == -99 || row.statusConnect == -97 
          || row.statusConnect == -96 || row.statusConnect == -95 || row.statusConnect == -93)
          {
            return <img className="d-flex justify-content-center" src={StatusConnect06} />
          }

          if(row.statusConnect == -98) {
            return <img src={StatusConnect07} />
          }

          if(row.statusConnect == -94) {
            return <img src={StatusConnect10} />
          }

          if(row.statusConnect >= 0 && row.statusConnect <= 4) {
            return <img src={StatusConnect05} />
          }

          if(row.statusConnect > 4 && row.statusConnect <= 9) {
            return <img src={StatusConnect04} />
          }

          if(row.statusConnect > 9 && row.statusConnect <= 16) {
            return <img src={StatusConnect03} />
          }

          if(row.statusConnect > 16 && row.statusConnect <= 21) {
            return <img src={StatusConnect02} />
          }

          if(row.statusConnect > 21 && row.statusConnect <= 31) {
            return <img className="d-flex justify-content-center" src={StatusConnect01} />
          }

          if(row.statusConnect == 99) {
            return <img src={StatusConnect06} />
          }

          if(row.statusConnect == 128) {
            return <img src={StatusConnect09} />
          }
        },
        accessorKey: 'statusConnect',
        header: 'GSM',
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => router.push(`/controller/${row.id}`)} className="btn btn-light btn-sm">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </Button> 
        </>,
        accessorKey: 'button',
        header: translatedForm.Actions,
      },
    ],
    [],
  );

    return(
      isAuth ?
      <>
        <div className=" d-sm-flex align-items-center justify-content-between">
          <h2 className="mb-sm-0 fw-semibold font-size-18 benzamat-font-bold text-black">
              <NavLink to="/transports">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.125 13.8125L3.25 8.9375L8.125 4.0625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.125 20.3125H17.0625C18.5709 20.3125 20.0176 19.7133 21.0842 18.6467C22.1508 17.5801 22.75 16.1334 22.75 14.625V14.625C22.75 13.8781 22.6029 13.1385 22.3171 12.4485C22.0312 11.7584 21.6123 11.1315 21.0842 10.6033C20.556 10.0752 19.9291 9.65626 19.239 9.37043C18.549 9.08461 17.8094 8.9375 17.0625 8.9375H3.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </NavLink> 
              {translated.TitleSingle} - {isLoading ? <Skeleton width={200} height={25} /> : transportName}
          </h2>
          <div className="page-title-right">

            {
              isLoading
              ? <Skeleton width={200} height={30} />
              : 
                permission.delete || permission.fullAccess
                ?
                  isDeleted
                  ?
                  <Button onClick={() => deleteTC()} variant="success" className="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock-off" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M15 11h2a2 2 0 0 1 2 2v2m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h4"></path>
                        <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0"></path>
                        <path d="M8 11v-3m.719 -3.289a4 4 0 0 1 7.281 2.289v4"></path>
                        <path d="M3 3l18 18"></path>
                      </svg>
                      {translated.Unlock}
                  </Button>
                  :
                  <Button onClick={() => deleteTC()} variant="danger" className="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z"></path>
                        <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0"></path>
                        <path d="M8 11v-4a4 4 0 1 1 8 0v4"></path>
                      </svg>
                      {translated.Lock}
                  </Button>
                : null
            }
          </div>
        </div>
      
    <div className="row">

      {
        isDeleted
        ?
        <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
          <div className="alert alert-danger">
            <span>{translated.LockMessage}</span>
          </div>
        </div>
        : null
      }
      
      <Tabs defaultActiveKey="maininfo" onClick={(e) => handleTabActive(e.target.dataset.rrUiEventKey)}  id="uncontrolled-tab-example" className="mt-1 nav-tabs-custom">
       
        <Tab eventKey="maininfo" title={translated.General} tabClassName="font-size-15">
        <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Control {...register("Id")} type="hidden"  />
          <div className="row">
            <div className="col-xs-12 col-md-4 col-lg-4">

            {
              isLoading
              ? <Skeleton className="mt-4" count={3} height={25}/>
              :
              <>
              
                <Form.Group className="mb-3 mt-3">
                  <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
                  <Form.Control 
                    {...register("Name")}
                    type="text" maxLength="100" isInvalid={errors.Name}  
                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.Name && 
                      <span className="text-danger font-size-13">{errors.Name.message}</span>
                    }
                </Form.Group>
                
                <Form.Group className="mb-3 mt-3">
                  <Form.Label className="text-black">{translated.Number}</Form.Label>
                  <Form.Control 
                    {...register("Number")}
                    type="text" maxLength="10" isInvalid={errors.Number}  
                    placeholder={translatedForm.EnterValue(10)} className="form-control"  />
                    {errors.Number && 
                      <span className="text-danger font-size-13">{errors.Number.message}</span>
                    }
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Model}</Form.Label>
                  <Form.Control 
                    {...register("Model")}
                    type="text" maxLength="10" isInvalid={errors.Model}
                     placeholder={translatedForm.EnterValue(10)} className="form-control"  />
                    {errors.Model && 
                      <span className="text-danger font-size-13">{errors.Model.message}</span>
                    }
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Contract}</Form.Label>
                  <Form.Control 
                    {...register("Contract")}
                    type="text" maxLength="100" isInvalid={errors.Contract}
                     placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.Contract && 
                      <span className="text-danger font-size-13">{errors.Contract.message}</span>
                    }
                </Form.Group>

                <div className="form-group mt-3">
                  <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                    {loadingDataBtn && (
                      <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {loadingDataBtn && <span>{translatedForm.SaveChanges}</span>}
                    {!loadingDataBtn && <span>{translatedForm.SaveChanges}</span>}
                  </button>
                </div>
              </>
            }

            </div>

            <div className="col-xs-12 col-lg-4 col-md-4">
              {
                isLoading
                ? <Skeleton count={3} className="mt-4" height={25} />
                :
                <>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.TypeAuth} <sup className="text-danger">*</sup></Form.Label>
                      <Controller
                          name="TypeAuth"                                
                          control={control}
                          rules={{ required: translatedForm.Required }}
                          render={({ field }) => (
                          <Select
                            onChange={(item) => {
                              if(item.id == 1) {
                                setTypeAuth({rfid:true, password:false, rfid_password:false})
                              } else if (item.id == 2) {
                                setTypeAuth({rfid:false, password:true, rfid_password:false})
                              } else {
                                setTypeAuth({rfid:false, password:false, rfid_password:true})
                              }
                              setSelectedTypeAuth({label: item.label, value:item.value})
                              setValue("TypeAuth", selectedTypeAuth)
                            }}
                            value={selectedTypeAuth}
                            options={listTypeAuth}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                          />
                          )}
                      />
                      {errors.TypeAuth && 
                          <span className="text-danger font-size-13">{errors.TypeAuth.message}</span>
                      }
                  </Form.Group>

                    {
                      typeAuth.password || typeAuth.rfid_password
                      ?
                      <Form.Group className="mb-3 mt-3">
                        <Form.Label className="text-black">{translated.Password}</Form.Label>
                        <Form.Control 
                          {...register("Password", {
                            minLength : {
                              value: 5,
                              message: translatedForm.MinLengthString(5)
                            },
                            maxLength : {
                              value: 10,
                              message: translatedForm.MaxLenghtString(10)
                            },
                          })}
                          type="number" maxLength="10" isInvalid={errors.Password} 
                          placeholder={translatedForm.EnterValue(10)} className="form-control"  />
                          {errors.Password && 
                            <span className="text-danger font-size-13">{errors.Password.message}</span>
                          }
                      </Form.Group>
                      :
                      ''
                    }

                    {
                      typeAuth.rfid || typeAuth.rfid_password
                      ?

                      <>
                        <Form.Group className="mb-3 mt-3">
                          <Form.Label className="text-black">{translated.NumberRfid} <sup className="text-danger">*</sup></Form.Label>
                          <Controller
                              name="NumberRfid"
                              control={control}
                              rules={{ required: translatedForm.Required }}
                              render={({ field: {value} }) => (
                                  <InputMask 
                                      mask="**************"
                                      className="form-control"
                                      type="text"
                                      formatChars={formatChars}
                                      value={value}
                                      placeholder={translatedForm.EnterValue(14)}
                                      onChange={(item) => {
                                        setValue("NumberRfid", item.target.value)
                                      }}
                                  />
                              )}
                          />
                          {errors.NumberRfid && 
                              <span className="text-danger font-size-13">{errors.NumberRfid.message}</span>
                          }
                        </Form.Group>
                        <Button type="submit" onClick={() => getLastCard()} className="mb-2" variant="primary" disabled={rfidBtnLoad}>
                            {rfidBtnLoad && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {rfidBtnLoad && <span>{translated.ButtonRfid}</span>}
                            {!rfidBtnLoad && <span>{translated.ButtonRfid}</span>}
                        </Button>
                      </>
                      :
                      null
                  }

                  <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.ServiceDate} <sup className="text-danger">*</sup></Form.Label>
                    <Controller
                        name="ServiceDate"                                
                        control={control}
                        render={({ field:{value} }) => (
                        <Flatpickr
                            className='form-control'
                            options={{ 
                                time_24hr: true,  
                                enableTime: false,
                                locale: Russian
                            }}
                            value={value}
                            placeholder={translatedForm.SelectFromList}
                            onChange={([date]) => {
                                setValue("ServiceDate", date)
                            }}
                        />
                        )}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.Company} <sup className="text-danger">*</sup></Form.Label>
                      <Controller
                        name="Company"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field:{value} }) => (
                        <Select
                          onChange={(item) => {
                            setValue("Company", {label: item.label, value:item.value})
                          }}
                          value={value}                        
                          placeholder={translatedForm.SelectFromList}
                          classNamePrefix="select"
                        />
                      )}
                    />
                    {errors.Company && 
                        <span className="text-danger font-size-13">{errors.Company.message}</span>
                    }
                  </Form.Group>
                </>
              }            
            </div>
          </div>
          </Form>
        </Tab>

        <Tab eventKey="limit" title={translated.Limits} tabClassName="font-size-15">

          {
            currentTabActive == "limit"
            ? <TransportLimits typeLimit={typeLimit} permission={permission} setTypeLimit={setTypeLimit} transportData={transportData}/>
            : null
          }
        </Tab>
        
        <Tab eventKey="controllers" title={translated.Controllers} tabClassName="font-size-15">
          <div className="row mt-3">
            <div className="col-xs-12 col-lg-12 col-md-12">

            <MaterialReactTable 
              columns={columns} 
              data={controllers}
              localization={MRT_Localization_EN}
              initialState={{ showColumnFilters: true }}
              enableTopToolbar={false}
              enableRowVirtualization
              muiTablePaperProps={{
                elevation: 1,
              }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: 'bold',
                  color:"#000",
                  backgroundColor:'#F3F3F3',
                  fontFamily: 'BenzamatRegular',
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  color:"#000",
                  fontFamily: 'BenzamatRegular',
                },
              }}
            />

            </div>
          </div>
        </Tab>

      </Tabs>
    </div>

    </>
    :
    <Redirect to='/login'/>
    );
}

export default TransportSingle;
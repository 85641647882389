import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, DropdownButton, Alert, Dropdown, Badge, Modal, Form, Offcanvas, Spinner, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import ControllerUsers from './User';
import ControllerCompanies from './Company/all';
import InputMask from 'react-input-mask';
import Select from 'react-select'
import axios from 'axios';
import api from '../../utils/axiosInterceptions';

export const ModalHardware = ({translatedForm, translated, controllerData, controllerInfo, typeController, showModalChangeType, setShowModalChangeType}) => {

    const params = useParams()
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [loadingBtn, setLoadingBtn] = useState(false)
    
    const displayToast = (message, status) => {

        if(status)
        {
            toast.success(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        } 
        else 
        {
            toast.error(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        }
    
    }

    const onSubmit = data => {
        setLoadingBtn(true)
        api.post("/api/controllers/details/update-hardware", {
          Type: data.Type.value,
          HasModem: controllerInfo.type != 3 ? data.HasModem.value : 0,
          EnabledModem: controllerInfo.type != 3 ? data.EnabledModem.value : 0,
          ControllerId: params.id,
        })
        .then(function (response) {
          console.log("hardware")
          console.log(response)
          setLoadingBtn(false)
          displayToast(response.data.message, response.data.isSuccess)
          controllerData()
        })
        .catch(function (error) {
          setLoadingBtn(false)
          displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }

    const listTypeController = [
        {label:translated.NotSet, value:0},
        {label:translated.Electro, value:1},
        {label:translated.Livenka, value:2},
        {label:translated.Topaz, value:3},
        {label:translated.Gilbarco, value:4},
    ]

    const listTypeControllerNewB2 = [
        {label:translated.Electro, value:0},
        {label:translated.Livenka, value:1},
        {label:translated.Topaz, value:2},
        {label:translated.Gilbarco, value:3},
        {label:translated.Tokheim, value:4},
    ]

    const listModemCheck = [
        {label:translated.Now, value:false},
        {label:translated.No, value:true},
    ]

    const listEnableModem = [
        {label:translated.OpenCollector, value:0},
        {label:translated.RegularOutPut, value:1},
    ]

    useEffect(() => {
        let type = controllerInfo.type != 3 
        ? listTypeController.filter((item) => item.value === typeController)
        : listTypeControllerNewB2.filter((item) => item.value === typeController)
        setValue("Type", type[0])

        let hasModem = listModemCheck.filter((item) => item.value === controllerInfo.hasModem)
        setValue("HasModem", hasModem[0])

        if(controllerInfo.dayManufacture == 20) {
            let enableModem = listEnableModem.filter((item) => item.value === 0)
            setValue("EnabledModem", enableModem[0])
        } else {
            let enableModem = listEnableModem.filter((item) => item.value === 1)
            setValue("EnabledModem", enableModem[0])
        }

    }, [])

    return (        
        <Modal
            size="md"
            show={showModalChangeType}
            onHide={() => setShowModalChangeType(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="modal-90w"
        >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg" className="text-black benzamat-font-bold font-size-22">
            {translated.ModalHardwareTitle}
          </Modal.Title>
        </Modal.Header>
            <Modal.Body>
              <Alert variant="danger">
                {translated.MessageModalHardware}
              </Alert>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mt-3">
                      <Form.Group className="mb-3">
                      <Form.Label className="text-black">Конфигурация <sup className="text-danger">*</sup></Form.Label>
                          <Controller
                              name="Type"                                
                              control={control}
                              rules={{ required: translatedForm.Required }}
                              render={({ field:{value} }) => (
                              <Select
                                  value={value}
                                  options={controllerInfo.type != 3 ? listTypeController : listTypeControllerNewB2}          
                                  placeholder={translatedForm.SelectFromList}
                                  classNamePrefix="select"
                                  onChange={(item) => setValue("Type", item)}
                              />
                              )}
                          />
                          {errors.Type && 
                              <span className="text-danger font-size-13">{errors.ControlleType.message}</span>
                          }
                      </Form.Group>

                      {
                        controllerInfo.type != 3
                        ?
                        <>                                
                            <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.ModemInstall}</Form.Label>
                                <Controller
                                    name="HasModem"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field:{value} }) => (
                                    <Select
                                        value={value}
                                        options={listEnableModem}
                                        isDisabled={true}        
                                        placeholder={translatedForm.SelectFromList}
                                        classNamePrefix="select"
                                    />
                                    )}
                                />
                                {errors.HasModem && 
                                    <span className="text-danger font-size-13">{errors.HasModem.message}</span>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.TurnModemPower} <sup className="text-danger">*</sup></Form.Label>
                                <Controller
                                    name="EnabledModem"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field:{value} }) => (
                                    <Select
                                        value={value}
                                        options={listEnableModem}          
                                        placeholder={translatedForm.SelectFromList}
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("EnabledModem", item)}
                                    />
                                    )}
                                />
                                {errors.EnabledModem && 
                                    <span className="text-danger font-size-13">{errors.EnabledModem.message}</span>
                                }
                            </Form.Group>
                        </>
                        : null
                      }

                      <div className="form-group mt-2">
                          <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                              {loadingBtn && (
                                  <Spinner animation="border" variant="light" 
                                      as="span"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      style={{ marginRight: "5px" }}
                                  />
                              )}
                              {loadingBtn && <span>{translated.SaveConfiguration}</span>}
                              {!loadingBtn && <span>{translated.SaveConfiguration}</span>}
                          </button>
                      </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
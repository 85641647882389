import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const Printer = ({translated, translatedForm, typeController}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [selectedImpuls, setSelectedImpuls] = useState({
        label:'1',
        value:1
    })
    const [adjustingFactor, setAdjustingFactor] = useState({
      label:'',
      value:''
    })
    const [summator, setSummator] = useState(0)
    const [selectedCountPlomb, setSelectedCountPlomb] = useState(0)
    const [loadingBtn, setLoadingBtn] = useState(false)

    const printerTypes = [
      {label:translated.NotConnected, value:0},
      {label:"TG2480H", value:1},
    ]
 
  useEffect(() => {
    api.get("/api/controllers/details/printer", {
        headers:{
          "content-type" : "application/json",
          "controllerId":params.id
        },
        params:{
          controllerId: params.id,
        },
        withCredentials:true
      })
      .then(function (response) {
        console.log("printer")
        console.log(response)

        let printer = printerTypes.filter((item) => item.value === response.data.dataResult.type)
        setValue("Type", {label:printer[0].label, value:printer[0].value})
        setValue("Header1", response.data.dataResult.header1)
        setValue("Header2", response.data.dataResult.header2)
        setValue("Footer", response.data.dataResult.footer)
      
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const onSubmit = data => {
    setLoadingBtn(true);
    api.post("/api/controllers/details/printer/update", {
      Header1: data.Header1,
      Header2: data.Header2,
      Footer: data.Footer,
      Type: data.Type.value,
      ControllerId: params.id
    })
    .then(function (response) {
      setLoadingBtn(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      setLoadingBtn(false)
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-5 col-md-5">

                <Form.Group className="mb-3">
                <Form.Label className="text-black">{translated.PrinterType}</Form.Label>
                    <Controller
                        name="Type"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field:{value} }) => (
                        <Select
                            onChange={(item) => {
                                setValue("Type", {label:item.label, value:item.value})
                            }}
                            value={value}
                            options={printerTypes}          
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                        />
                        )}
                    />
                    {errors.PumpControlOutput && 
                        <span className="text-danger font-size-13">{errors.PumpControlOutput.message}</span>
                    }
                </Form.Group>          

                  <div className="form-group mt-3">
                      <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                          {loadingBtn && (
                              <Spinner animation="border" variant="light" 
                                  as="span"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: "5px" }}
                              />
                          )}
                          {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                          {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                      </button>
                  </div>
                </div>


              <div className="col-xs-12 col-lg-6 col-md-6">
                
              <h4 className="text-black">{translated.ChequeHeader}</h4>
                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.ChequeString}</Form.Label>
                    <Form.Control 
                        {...register("Header1")}
                        type="text"
                        maxLength="100" 
                        isInvalid={errors.Header1}  
                        placeholder={translatedForm.EnterValue(100)} 
                        className="form-control"  
                    />                    
                    {errors.Header1 && 
                        <span className="text-danger font-size-13">{errors.Header1.message}</span>
                    }
                </Form.Group>
                  
                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.ChequeString2}</Form.Label>
                    <Form.Control 
                        {...register("Header2")}
                        type="text"
                        maxLength="100" 
                        isInvalid={errors.Header2}  
                        placeholder={translatedForm.EnterValue(100)}
                        className="form-control"  
                    />                    
                    {errors.Header2 && 
                        <span className="text-danger font-size-13">{errors.Header2.message}</span>
                    }
                </Form.Group>
                
                <h4 className="text-black">{translated.TotalCheque}</h4>
                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.ChequeString}</Form.Label>
                    <Form.Control 
                        {...register("Footer")}
                        type="text"
                        maxLength="100" 
                        isInvalid={errors.Footer}  
                        placeholder={translatedForm.EnterValue(100)}
                        className="form-control"  
                    />                    
                    {errors.Footer && 
                        <span className="text-danger font-size-13">{errors.Footer.message}</span>
                    }
                </Form.Group>

              </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}
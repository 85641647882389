import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller, set } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const ControllerFlowSensor = ({translated, translatedForm, controllerInfo}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [isDisabled, setIsDisabled] = useState(false)
    const [selectedSensorType, setSelectedSensorType] = useState({
        label:'',
        value:''
    })
    const [selectedCounterImpuls, setSelectedCounterImpuls] = useState({
        label:'',
        value:''
    })
    const [selectedImpulsLength, setSelectedImpulsLength] = useState({
        label:'',
        value:''
    })
    const [selectedImpulsFirstTime, setSelectedImpulsFirstTime] = useState({
        label:'',
        value:''
    }) 
    const [selectedImpulsNextTime, setSelectedImpulsNextTime] = useState({
        label:'',
        value:''
    }) 
    const [loadingBtn, setLoadingBtn] = useState(false)

    const sensorType = [
        {label:translated.NotConnected, value:0},
        {label:translated.SingleChannel, value:1},
        {label:translated.DoubleChannel, value:2},
        {label:translated.MassFlowSensor, value:3},
        {label:translated.MassFlowEmis, value:4},
        {label:translated.DigitalBenza, value:5}
    ]

    const listCountImpuls = [
        {label:translated.ClosedToOpen, value:0},
        {label:translated.OpenToClose, value:1}
    ]

    const lengthImpuls = [
        {label:"1.0", value:1},
        {label:"2.0", value:2},
        {label:"3.0", value:3},
        {label:"4.0", value:4},
        {label:"5.0", value:5},
        {label:"6.0", value:6},
        {label:"7.0", value:7},
        {label:"8.0", value:8},
        {label:"9.0", value:9},
        {label:"10.0", value:10},
    ]

    const timeWaitImpuls = [
        {label:"10", value:10},
        {label:"20", value:20},
        {label:"30", value:30},
        {label:"40", value:40},
        {label:"50", value:50},
        {label:"60", value:60},
        {label:"90", value:90},
        {label:"120", value:120},
        {label:"180", value:180},
    ]

  useEffect(() => {

    api.get(`api/controllers/details/sensor`, {
        headers:{
            "content-type" : "application/json",
            "controllerId":params.id
        },
        params:{
        controllerId: params.id,
        },
        withCredentials:true
      })
      .then(function (response) { 
        console.log(response)

        let setFlowSensortType = sensorType.filter((item) => item.value == response.data.dataResult.flowSensorType)
        setSelectedSensorType({label:setFlowSensortType[0].label, value:setFlowSensortType[0].value})
        setValue("FlowSensorType", selectedSensorType)

        if(response.data.dataResult.flowSensorType === 4 || response.data.dataResult.flowSensorType === 3) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }

        let setCounterImpuls = listCountImpuls.filter((item) => item.value == response.data.dataResult.flowSensorCounterType)
        setSelectedCounterImpuls({label:setCounterImpuls[0].label, value:setCounterImpuls[0].value})
        setValue("FlowSensorCounterType", selectedCounterImpuls)

        let setImpulsLength = lengthImpuls.filter((item) => item.value == response.data.dataResult.flowSensorImpulseLength)
        setSelectedImpulsLength({label:setImpulsLength[0].label, value:setImpulsLength[0].value})
        setValue("FlowSensorImpulseLength", setImpulsLength[0].value)

        setValue("AddressFlowrate", response.data.dataResult.addressFlowrate);
        //let setImpulsFirstTime = timeWaitImpuls.filter((item) => item.value == response.data.dataResult.flowSensorImpulseFirstTime)
        //setSelectedImpulsFirstTime({label:setImpulsFirstTime[0].label, value:setImpulsFirstTime[0].value})
        setValue("FlowSensorImpulseFirstTime",  response.data.dataResult.flowSensorImpulseFirstTime)

        //let setImpulsNextTime = timeWaitImpuls.filter((item) => item.value == response.data.dataResult.flowSensorImpulseNextTime)
        //setSelectedImpulsNextTime({label:setImpulsNextTime[0].label, value:setImpulsNextTime[0].value})
        setValue("FlowSensorImpulseNextTime", response.data.dataResult.flowSensorImpulseNextTime)
    })
      .catch(function (error) {
        console.log(error);
    });

  }, [])

  const onSubmit = data => {

    console.log(data)
    setLoadingBtn(true);
    api.post("/api/controllers/details/sensor/update", {
        FlowSensorType: selectedSensorType.value,
        FlowSensorCounterType: selectedCounterImpuls.value,
        FlowSensorImpulseLength: data.FlowSensorImpulseLength,
        FlowSensorImpulseFirstTime: data.FlowSensorImpulseFirstTime,
        FlowSensorImpulseNextTime: data.FlowSensorImpulseNextTime,
        AddressFlowrate: data.AddressFlowrate,
        ControllerId: params.id
    })
    .then(function (response) {
      setLoadingBtn(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
        console.log(error)
      setLoadingBtn(false)
      displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-5 col-md-5">

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.FlowSensor}</Form.Label>
                        <Controller
                            name="FlowSensorType"                                
                            control={control}
                            rules={{ required: translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Select
                                onChange={(item) => {
                                    setSelectedSensorType({label:item.label, value:item.value})
                                    setValue("FlowSensorType", selectedSensorType)

                                    if(item.value === 4 || item.value === 3) {
                                        setIsDisabled(true)
                                    } else {
                                        setIsDisabled(false)
                                    }
                                }}
                                value={selectedSensorType}
                                options={sensorType}
                                isDisabled={selectedSensorType.value == 5 ? true : false}
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                        {errors.FlowSensorType && 
                            <span className="text-danger font-size-13">{errors.FlowSensorType.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.CountImpulseFW}</Form.Label>
                        <Controller
                            name="FlowSensorCounterType"                                
                            control={control}
                            rules={{ required: translatedForm.Required }}
                            render={({ field:{value} }) => (
                            <Select
                                onChange={(item) => {
                                    setSelectedCounterImpuls({label:item.label, value:item.value})
                                    setValue("FlowSensorCounterType", selectedCounterImpuls)
                                }}
                                value={selectedCounterImpuls}
                                options={listCountImpuls}
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                                isDisabled={isDisabled}
                            />
                            )}
                        />
                        {errors.FlowSensorCounterType && 
                            <span className="text-danger font-size-13">{errors.FlowSensorCounterType.message}</span>
                        }
                    </Form.Group>

                    {
                        controllerInfo.type != 3
                        ?
                        <>
                            <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.FirstChannelFW}</Form.Label>
                                <input type="text" disabled={true} className="form-control" value={translated.IN_FW}/>
                            </Form.Group>
                            
                            <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.SecondChannelFW}</Form.Label>
                                <input type="text" disabled={true} className="form-control" value={translated.IN2_FW} />
                            </Form.Group>
                        </>
                        : null
                    }

                    {
                        controllerInfo.firmwareVersionString == "BS-02 GOST" && selectedSensorType.value == 5
                        ?
                        <>
                        <Form.Group className="mb-3 mt-3">
                            <Form.Label className="text-black">{translated.Address}</Form.Label>
                            <Form.Control 
                                {...register("AddressFlowrate", {
                                max : {
                                    value: 255,
                                    message: translatedForm.MaxNumber(255)
                                },
                                min : {
                                    value: 10,
                                    message: translatedForm.MinNumber(10)
                                },
                                })}
                                type="number" maxLength="3" isInvalid={errors.AddressFlowrate}  placeholder={translated.EnterValue} className="form-control"  />
                                {errors.AddressFlowrate && 
                                    <span className="text-danger font-size-13">{errors.AddressFlowrate.message}</span>
                                }
                        </Form.Group>
                        </>
                        : null
                    }

                    <div className="form-group mt-3">
                        <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                            {loadingBtn && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                            {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        </button>
                    </div>
                </div>

                <div className="col-xs-12 col-lg-7 col-md-7">

                    {
                        controllerInfo.type != 3
                        ?
                        <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.ImpulseDurationFW}</Form.Label>
                            <Controller
                                name="FlowSensorImpulseLength"                                
                                control={control}
                                rules={{ 
                                    max : {
                                        value: 50.0,
                                        message: translatedForm.MaxNumber(50,0)
                                    },
                                    min : {
                                        value: 0.5,
                                        message: translatedForm.MinNumber(0,5)
                                    },
                                    required: translatedForm.Required
                                }}
                                render={({ field:{value} }) => (
                                <CurrencyInput
                                    placeholder={translatedForm.EnterValue(50)}
                                    value={value}
                                    decimalsLimit={2}
                                    className="form-control"
                                    onValueChange={(value) => setValue("FlowSensorImpulseLength", value)}
                                    isDisabled={isDisabled}
                                />
                                )}
                            />
                            {errors.FlowSensorImpulseLength && 
                                <span className="text-danger font-size-13">{errors.FlowSensorImpulseLength.message}</span>
                            }
                        </Form.Group>
                        : null
                    }

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.WaitingTimeFirst}</Form.Label>
                        
                        <Controller
                            name="FlowSensorImpulseFirstTime"                                
                            control={control}
                            rules={{ 
                                max : {
                                    value: 180,
                                    message: translatedForm.MaxNumber(180)
                                },
                                min : {
                                    value: 0,
                                    message: translatedForm.MinNumber(0)
                                },
                                required: translatedForm.Required
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(180)}
                                value={value}
                                decimalsLimit={2}
                                className="form-control"
                                onValueChange={(value) => {
                                    console.log(value)
                                    setValue("FlowSensorImpulseFirstTime", value)
                                }}
                                isDisabled={isDisabled}
                            />
                            )}
                        />
                        {errors.FlowSensorImpulseFirstTime && 
                            <span className="text-danger font-size-13">{errors.FlowSensorImpulseFirstTime.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.WaitingTimeNext}</Form.Label>
                        
                        <Controller
                            name="FlowSensorImpulseNextTime"                                
                            control={control}
                            rules={{ 
                                max : {
                                    value: 180,
                                    message: translatedForm.MaxNumber(180)
                                },
                                min : {
                                    value: 0,
                                    message: translatedForm.MinNumber(0)
                                },
                                required: translatedForm.Required
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(180)}
                                value={value}
                                decimalsLimit={2}
                                className="form-control"
                                onValueChange={(value) => setValue("FlowSensorImpulseNextTime", value)}
                                isDisabled={isDisabled}
                            />
                            )}
                        />
                        {errors.FlowSensorImpulseNextTime && 
                            <span className="text-danger font-size-13">{errors.FlowSensorImpulseNextTime.message}</span>
                        }
                    </Form.Group>
                </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}
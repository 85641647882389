import React, { useContext, useEffect, useState, useMemo, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, ProgressBar, Badge, Form, Alert, Offcanvas, Spinner, Nav} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import moment from 'moment';
import './../../boxicons.css';
import { toast } from 'react-toastify/dist/react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { FuelCompanyFormAdd } from './FuelForm/FuelCompanyFormAdd';
import { FuelCompanyFormEdit } from './FuelForm/FuelCompanyFormEdit';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import { TranslateFuels } from '../../lang/company/translateFuels';
import { FormLocale } from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const FuelsCompany = () => {

    const params = useParams()
    const [showFormAdd, setShowFormAdd] = useState(false);
    const [loadingDataBtn, setLoadingDataButton] = useState(false)
    const [listHistory, setListHistory] = useState([])
    const [fuels, setFuels] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const translated = useLittera(TranslateFuels)
    const translatedForm = useLittera(FormLocale)
    const methods = useLitteraMethods();
    const [fuel, setFuel] = useState(0)
    const [permission, setPermission] = useState({
        read:false,
        update:false,
        create:false,
        delete:false,
        fullAccess:false
    })
    const handleShow = () => setShowFormAdd(true);
    const handleClose = () => {

      if(isUpdate) {
        setIsUpdate(false)
      }
      setShowFormAdd(false)
    };
    
    const displayToast = (message, status) => {

        if(status){
          toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
    
        } else {
          toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
        }
      
    }

    const handleUpdate = id => {
      console.log(id)
      setFuel(id)
      setIsUpdate(true)
      setShowFormAdd(true)
    }

    const handleUpdateHistoryPrice = () => {

        setLoadingDataButton(true);
        api.get("/api/company/fuels/history-change-price", {
          headers:{
            "content-type" : "application/json",
            "id":params.id
          },
          params:{
            CompanyId: params.id,
          }
        })
        .then(function (response) {
          
          setLoadingDataButton(false)
          displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
          console.log(error);
          setLoadingDataButton(false)
          displayToast(error.data.message, error.data.isSuccess)
        });
    }
  
    const getPrice = () => {
      setIsLoading(true)
        api.get("/api/company/fuels", {
            headers:{
              "content-type" : "application/json",
              "CompanyId":params.id
            },
            params:{
              CompanyId: params.id,
            }
          })
          .then(function (response) {
            console.log("company fuel price")
            console.log(response)
            setFuels(response.data.listDatas)
            setPermission({
                read: response.data.read,
                update: response.data.update,
                create: response.data.create,
                fullAccess: response.data.fullAccess
            })
            setListHistory(response.data.listDatas)
            setLoadingDataButton(false)
            setIsLoading(false)
          })
          .catch(function (error) {
            console.log(error);
            setLoadingDataButton(false)
            displayToast(error.data.message, error.data.isSuccess)
          });  
    }

    useEffect(() => {
      methods.setLocale(CurrentSelectLanguage);
      getPrice()
    }, [])

    const columns = useMemo(
      () => [
        {
          accessorFn: (row) => row.fuelName,
          accessorKey: 'name',
          header: translated.Name,
        },
        {
          accessorFn: (row) => row.price,
          accessorKey: 'price',
          header: translated.Price,
        },
        {
          accessorFn: (row) => 
              <Button onClick={() => handleUpdate(row.id)}  className="btn btn-light btn-sm">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.45986 16.8349L3.16455 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </Button>,
          accessorKey: 'button',
          header: translated.Actions,
        },
      ],
      [],
    );

    return(
        <>
        {
          isLoading
          ? <Spinner animation="border" variant="info" />
          : <>

            <div className="row mt-4">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="col-xs-12 col-lg-3 col-md-3">
                        <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="first">
                              {translated.CurrentPrice}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </div>

                    <div className="col-xs-12 col-lg-9 col-md-9">
                    <Tab.Content>

                    <Tab.Pane eventKey="first">

                        {
                            permission.create || permission.fullAccess
                            ?
                            <Button bg="primary" onClick={() => handleShow()} className="mb-2">
                            <i className="bx bx-plus font-size-16 align-middle me-2 "></i> 
                                {translated.AddFuel}
                            </Button>
                            : null
                        }

                        {
                            permission.read || permission.fullAccess
                            ?
                            <MaterialReactTable 
                              columns={columns} 
                              data={fuels}
                              localization={MRT_Localization_EN}
                              initialState={{ showColumnFilters: true }}
                              enableTopToolbar={false}
                              enableRowVirtualization
                              muiTablePaperProps={{
                                elevation: 1,
                              }}
                              muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                  fontWeight: 'bold',
                                  color:"#000",
                                  backgroundColor:'#F3F3F3',
                                  fontFamily: 'BenzamatRegular',
                                },
                              }}
                              muiTableBodyCellProps={{
                                sx: {
                                  color:"#000",
                                  fontFamily: 'BenzamatRegular',
                                },
                              }}
                            />
                            : 
                            <Alert variant='danger'>
                              {translated.PermissionDenied}
                            </Alert>
                        }

                    </Tab.Pane>

                    </Tab.Content>
                    </div>
                </Tab.Container>
            </div>

            {
              permission.create || permission.fullAccess
              ?
              <Offcanvas placement="end" show={showFormAdd} onHide={() => handleClose()}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                    {
                      isUpdate
                      ? translated.EditPrice
                      : translated.AddFuelTitle
                    }
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <div className="dropdown-divider"></div>
                <Offcanvas.Body>  

                {
                  isUpdate
                  ? <FuelCompanyFormEdit translatedForm={translatedForm} translated={translated} fuel={fuel} companyId={params.id} setFuels={setFuels}/>
                  : <FuelCompanyFormAdd companyId={params.id} translatedForm={translatedForm}  translated={translated} setFuels={setFuels} /> 
                }

                </Offcanvas.Body>
              </Offcanvas>
              : null
            } 
          
          </>
        }
        </>
    )
}
import React, { useState, useEffect, useMemo, useContext, Redirect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Badge, Offcanvas, Form, Spinner} from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import moment from 'moment';
import api from '../../utils/axiosInterceptions';

export const HistoryLimits = ({translated}) => {

    const params = useParams();
    const router = useHistory();
    const [listCompanies, setListCompanies] = useState({})
    const [historyLimit, setHistoryLimit] = useState([])
    const [loadingData, setLoadingData] = useState({
        button:false,
        grid:false
    })

    const columns = useMemo(
      () => [
        {
          accessorFn: (row) =>  row.limitDay,
          accessorKey: 'limitDay',
          header: translated.LimitDay,
        },
        {
          accessorFn: (row) =>  row.limitMonth,
          accessorKey: 'limitMonth',
          header: translated.LimitMonth,
        },
        {
          accessorFn: (row) =>  row.typeLimit.name,
          accessorKey: 'enumTypeLimit',
          header: translated.TypeLimit,
        },
        {
          accessorFn: (row) => moment(row.createdAt).format('DD.MM.YYYY hh:mm'),
          accessorKey: 'dateCreated',
          header: translated.CreatedDate,
        },
      ],
      [],
    );

    useEffect(() => {
      api.get("/api/users/details/history-limits", {
          headers:{
              "content-type" : "application/json",
              "id":params.id
          },
          params:{
              id: params.id,
          }
      })
      .then(function (response) {
          console.log("update limit")
          console.log(response)
          setHistoryLimit(response.data.listDatas)
      })
      .catch(function (error) {
          console.log(error);
      });
    }, [])

    const createdAt = rowData => {
      return moment(rowData.createdAt).format('DD-MM-YYYY hh:mm')
    }

    const balance = rowData => {
      return rowData.enumTypeLimit.id == 1 ? rowData.limitDay : rowData.limitMonth
    }

    return ( 
      <div>
          <MaterialReactTable 
            columns={columns} 
            data={historyLimit}
            localization={MRT_Localization_EN}
            initialState={{ showColumnFilters: true }}
            enableTopToolbar={false}
            enableRowVirtualization
            muiTablePaperProps={{
              elevation: 1,
            }}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                fontWeight: 'bold',
                color:"#000",
                backgroundColor:'#F3F3F3',
                fontFamily: 'BenzamatRegular',
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                color:"#000",
                fontFamily: 'BenzamatRegular',
              },
            }}
          />
      </div>
    );
}

